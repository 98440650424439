<app-loader [loading]="loading"></app-loader>

<div class="content-actions" *ngIf="!loading">
  <h2>
    <i class="tio">file_add_outlined</i>
    {{ isEditMode ? "Kunde bearbeiten" : "Kunde erfassen" }}
  </h2>
</div>

<form #form="ngForm" class="content-offer-container">
  <div class="content-offer-forms limit-width" *ngIf="!loading">
    <ng-container>
      <div class="content limit-width">
        <header class="content-header">
          <span>Personen</span>
        </header>

        <div class="content-body">
          <div class="offer-type-container">
            <div
              class="offer-type"
              (click)="selectPersonType('A')"
              [class.selected]="data.patient_type === 'A'"
            >
              <p-radioButton
                [(ngModel)]="data.patient_type"
                label="A"
                name="patient-type"
                value="A"
              ></p-radioButton>
              <i
                class="pi pi-info-circle"
                pTooltip="Die Person lebt allein, eine weitere Person ist nicht im Haushalt."
              ></i>
              <small
                >Die Person lebt allein, eine weitere Person ist nicht im
                Haushalt.</small
              >
            </div>
            <div
              class="offer-type"
              (click)="selectPersonType('B')"
              [class.selected]="data.patient_type === 'B'"
            >
              <p-radioButton
                [(ngModel)]="data.patient_type"
                label="B"
                name="patient-type"
                value="B"
              ></p-radioButton>
              <i
                class="pi pi-info-circle"
                pTooltip="Die Person lebt mit einer weiteren Person im Haushalt. Die zweite Person ist hilfebedürftig."
              ></i>
              <small
                >Die Person lebt mit einer weiteren Person im Haushalt. Die
                zweite Person ist hilfebedürftig.</small
              >
            </div>
            <div
              class="offer-type"
              (click)="selectPersonType('C')"
              [class.selected]="data.patient_type === 'C'"
            >
              <p-radioButton
                [(ngModel)]="data.patient_type"
                label="C"
                name="patient-type"
                value="C"
              ></p-radioButton>
              <i
                class="pi pi-info-circle"
                pTooltip="Die Person lebt mit einer weiteren Person / Personen im Haushalt. Die zweite Person ist nicht hilfebedürftig. Lediglich Hilfestellung durch die Betreuungskraft in der Hauswirtschaft wird gewünscht (Kochen, Kleiderwaschen, Bügeln, Einkäufe, Raumpflege)."
              ></i>
              <small
                >Die Person lebt mit einer weiteren Person / Personen im
                Haushalt. Die zweite Person ist nicht hilfebedürftig. Lediglich
                Hilfestellung durch die Betreuungskraft in der Hauswirtschaft
                wird gewünscht (Kochen, Kleiderwaschen, Bügeln, Einkäufe,
                Raumpflege).</small
              >
            </div>
          </div>
        </div>
      </div>

      <div class="content limit-width content-highlight-1">
        <header class="content-header">
          <span>Grunddaten Person 1</span>
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-sm-3">
              <label for="first-person-form-of-address">Anrede *</label>
              <p-dropdown
                [(ngModel)]="data.first_patient.form_of_address"
                [options]="formOfAddress"
                inputId="first-person-form-of-address"
                #first_person_form_of_address="ngModel"
                name="first-person-form-of-address"
                placeholder="Bitte auswählen"
                [required]="true"
              ></p-dropdown>
              <div
                *ngIf="
                  first_person_form_of_address.invalid &&
                  (first_person_form_of_address.dirty ||
                    first_person_form_of_address.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="first_person_form_of_address.errors?.required">
                  <small>Bitte wählen Sie eine Anrede</small>
                </div>
              </div>
            </div>

            <div class="p-field p-col-12 p-sm-3">
              <label for="first-person-first-name">Vorname *</label>
              <input
                id="first-person-first-name"
                name="first-person-first-name"
                [(ngModel)]="data.first_patient.first_name"
                type="text"
                pInputText
                #first_person_first_name="ngModel"
                required
                [class.p-invalid]="
                  first_person_first_name.invalid &&
                  (first_person_first_name.dirty ||
                    first_person_first_name.touched)
                "
              />
              <div
                *ngIf="
                  first_person_first_name.invalid &&
                  (first_person_first_name.dirty ||
                    first_person_first_name.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="first_person_first_name.errors?.required">
                  <small>Bitte geben Sie einen Vornamen ein</small>
                </div>
              </div>
            </div>

            <div class="p-field p-col-12 p-sm-3">
              <label for="first-person-last-name">Nachname *</label>
              <input
                id="first-person-last-name"
                name="first-person-last-name"
                [(ngModel)]="data.first_patient.last_name"
                type="text"
                #first_person_last_name="ngModel"
                required
                [class.p-invalid]="
                  first_person_last_name.invalid &&
                  (first_person_last_name.dirty ||
                    first_person_last_name.touched)
                "
                pInputText
              />
              <div
                *ngIf="
                  first_person_last_name.invalid &&
                  (first_person_last_name.dirty ||
                    first_person_last_name.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="first_person_last_name.errors?.required">
                  <small>Bitte geben Sie einen Nachnamen ein</small>
                </div>
              </div>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-sm-4">
              <label for="first-person-street-house-number"
                >Straße / Hausnummer *</label
              >
              <input
                id="first-person-street-house-number"
                name="first-person-street-house-number"
                [(ngModel)]="data.first_patient.street_house_number"
                type="text"
                pInputText
                #first_person_street_house_number="ngModel"
                required
                [class.p-invalid]="
                  first_person_street_house_number.invalid &&
                  (first_person_street_house_number.dirty ||
                    first_person_street_house_number.touched)
                "
              />
              <div
                *ngIf="
                  first_person_street_house_number.invalid &&
                  (first_person_street_house_number.dirty ||
                    first_person_street_house_number.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="first_person_street_house_number.errors?.required">
                  <small>Bitte geben Sie einen Wert ein</small>
                </div>
              </div>
            </div>

            <div class="p-field p-col-12 p-sm-4">
              <label for="first-person-zipcode">PLZ *</label>
              <input
                id="first-person-zipcode"
                name="first-person-zipcode"
                (change)="loadCity('first_patient')"
                [(ngModel)]="data.first_patient.zipcode"
                type="text"
                pInputText
                #first_person_zipcode="ngModel"
                required
                [class.p-invalid]="
                  first_person_zipcode.invalid &&
                  (first_person_zipcode.dirty || first_person_zipcode.touched)
                "
              />
              <div
                *ngIf="
                  first_person_zipcode.invalid &&
                  (first_person_zipcode.dirty || first_person_zipcode.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="first_person_zipcode.errors?.required">
                  <small>Bitte geben Sie einen Wert ein</small>
                </div>
              </div>
            </div>

            <div class="p-field p-col-12 p-sm-4">
              <label
                for="first-person-city"
                style="justify-content: space-between"
                >Ort *
                <i
                  class="pi pi-info-circle color-gray"
                  style="font-size: 12px"
                  pTooltip="Wird durch PLZ übernommen"
                ></i
              ></label>
              <span class="p-input-icon-right">
                <i *ngIf="cityLoading" class="pi pi-spin pi-spinner"></i>
                <input
                  id="first-person-city"
                  name="first-person-city"
                  [(ngModel)]="data.first_patient.city"
                  type="text"
                  pInputText
                  #first_person_city="ngModel"
                  required
                  readonly
                  [class.p-invalid]="
                    first_person_city.invalid &&
                    (first_person_city.dirty || first_person_city.touched)
                  "
                />
              </span>
              <div
                *ngIf="
                  first_person_city.invalid &&
                  (first_person_city.dirty || first_person_city.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="first_person_city.errors?.required">
                  <small>Bitte geben Sie einen Wert ein</small>
                </div>
              </div>
            </div>
          </div>

          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-sm-4">
              <label for="first-person-city-part">Ortsteil</label>
              <span class="p-input-icon-right">
                <input
                  id="first-person-city-part"
                  name="first-person-city-part"
                  [(ngModel)]="data.first_patient.city_part"
                  type="text"
                  pInputText
                />
              </span>
            </div>

            <div class="p-field p-col-12 p-sm-4">
              <label
                for="first-person-county"
                style="justify-content: space-between"
                >Landkreis
                <i
                  class="pi pi-info-circle color-gray"
                  style="font-size: 12px"
                  pTooltip="Wird durch PLZ übernommen"
                ></i
              ></label>
              <span class="p-input-icon-right">
                <input
                  id="first-person-county"
                  name="first-person-county"
                  [(ngModel)]="data.first_patient.county"
                  type="text"
                  readonly
                  pInputText
                />
              </span>
            </div>

            <div class="p-field p-col-12 p-sm-4">
              <label
                for="first-person-state"
                style="justify-content: space-between"
                >Bundesland *
                <i
                  class="pi pi-info-circle color-gray"
                  style="font-size: 12px"
                  pTooltip="Wird durch PLZ übernommen"
                ></i
              ></label>
              <span class="p-input-icon-right">
                <input
                  id="first-person-state"
                  name="first-person-state"
                  [(ngModel)]="data.first_patient.state"
                  type="text"
                  pInputText
                  #first_person_state="ngModel"
                  required
                  readonly
                  [class.p-invalid]="
                    first_person_state.invalid &&
                    (first_person_state.dirty || first_person_state.touched)
                  "
                />
              </span>
              <div
                *ngIf="
                  first_person_state.invalid &&
                  (first_person_state.dirty || first_person_state.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="first_person_state.errors?.required">
                  <small>Bitte geben Sie einen Wert ein</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content limit-width content-highlight-1">
        <header class="content-header">
          <span>Kontaktdaten Person 1</span>
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-sm-4">
              <div class="p-field">
                <label for="first-person-phone-1">Telefon 1</label>
                <input
                  id="first-person-phone-1"
                  name="first-person-phone-1"
                  [(ngModel)]="data.first_patient.phone_1"
                  type="text"
                  pInputText
                />
              </div>
              <div class="p-field" style="margin-top: 5px">
                <input
                  id="first-person-phone-1-info"
                  name="first-person-phone-1-info"
                  placeholder="Informationen zu Telefon 1"
                  [(ngModel)]="data.first_patient.phone_1_info"
                  type="text"
                  pInputText
                />
              </div>
            </div>

            <div class="p-field p-col-12 p-sm-4">
              <div class="p-field">
                <label for="first-person-phone-2">Telefon 2</label>
                <input
                  id="first-person-phone-2"
                  name="first-person-phone-2"
                  [(ngModel)]="data.first_patient.phone_2"
                  type="text"
                  pInputText
                />
              </div>
              <div class="p-field" style="margin-top: 5px">
                <input
                  id="first-person-phone-2-info"
                  name="first-person-phone-2-info"
                  placeholder="Informationen zu Telefon 2"
                  [(ngModel)]="data.first_patient.phone_2_info"
                  type="text"
                  pInputText
                />
              </div>
            </div>

            <div class="p-field p-col-12 p-sm-4">
              <div class="p-field">
                <label for="first-person-email">E-Mail</label>
                <input
                  id="first-person-email"
                  name="first-person-email"
                  [(ngModel)]="data.first_patient.email"
                  type="email"
                  pInputText
                />
              </div>
              <div class="p-field" style="margin-top: 5px">
                <input
                  id="first-person-email-info"
                  name="first-person-email-info"
                  placeholder="Informationen zur E-Mail"
                  [(ngModel)]="data.first_patient.email_info"
                  type="email"
                  pInputText
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--      <div class="content limit-width content-highlight-1">-->
      <!--        <header class="content-header">-->
      <!--          <span> Versicherungs- und Leistungsdaten Person 1 </span>-->
      <!--        </header>-->

      <!--        <div class="content-body">-->
      <!--          <div class="p-fluid p-formgrid p-grid">-->
      <!--            <div class="p-field p-col-12">-->
      <!--              <div-->
      <!--                class="p-formgroup-inline has-form-height"-->
      <!--                style="align-items: center"-->
      <!--              >-->
      <!--                <div class="p-field-checkbox">-->
      <!--                  <p-checkbox-->
      <!--                    label="Selbstzahler"-->
      <!--                    name="first-person-insured-via"-->
      <!--                    value="Selbstzahler"-->
      <!--                    [(ngModel)]="data.first_patient.insured_via"-->
      <!--                    (onChange)="calculateBudget('first_patient')"-->
      <!--                    [required]="true"-->
      <!--                    #first_person_insured_via="ngModel"-->
      <!--                  ></p-checkbox>-->
      <!--                </div>-->
      <!--                <div class="p-field-checkbox">-->
      <!--                  <p-checkbox-->
      <!--                    label="Beihilfe"-->
      <!--                    value="Beihilfe"-->
      <!--                    name="first-person-insured-via"-->
      <!--                    [(ngModel)]="data.first_patient.insured_via"-->
      <!--                    (onChange)="calculateBudget('first_patient')"-->
      <!--                    [required]="true"-->
      <!--                    #first_person_insured_via="ngModel"-->
      <!--                  ></p-checkbox>-->
      <!--                </div>-->
      <!--                <div class="p-field-checkbox">-->
      <!--                  <p-checkbox-->
      <!--                    label="GKV"-->
      <!--                    name="first-person-insured-via"-->
      <!--                    value="GKV"-->
      <!--                    [(ngModel)]="data.first_patient.insured_via"-->
      <!--                    (onChange)="calculateBudget('first_patient')"-->
      <!--                    [required]="true"-->
      <!--                    #first_person_insured_via="ngModel"-->
      <!--                  ></p-checkbox>-->
      <!--                </div>-->
      <!--                <div class="p-field-checkbox">-->
      <!--                  <p-checkbox-->
      <!--                    label="PKV"-->
      <!--                    value="PKV"-->
      <!--                    name="first-person-insured-via"-->
      <!--                    [(ngModel)]="data.first_patient.insured_via"-->
      <!--                    (onChange)="calculateBudget('first_patient')"-->
      <!--                    [required]="true"-->
      <!--                    #first_person_insured_via="ngModel"-->
      <!--                  ></p-checkbox>-->
      <!--                </div>-->
      <!--                <div class="p-field-checkbox">-->
      <!--                  <p-checkbox-->
      <!--                    label="UV"-->
      <!--                    value="UV"-->
      <!--                    name="first-person-insured-via"-->
      <!--                    [(ngModel)]="data.first_patient.insured_via"-->
      <!--                    (onChange)="calculateBudget('first_patient')"-->
      <!--                    [required]="true"-->
      <!--                    #first_person_insured_via="ngModel"-->
      <!--                  ></p-checkbox>-->
      <!--                </div>-->
      <!--                <div class="p-field-checkbox">-->
      <!--                  <p-checkbox-->
      <!--                    label="SGB V"-->
      <!--                    value="SGB V"-->
      <!--                    name="first-person-insured-via"-->
      <!--                    [(ngModel)]="data.first_patient.insured_via"-->
      <!--                    (onChange)="calculateBudget('first_patient')"-->
      <!--                    [required]="true"-->
      <!--                    #first_person_insured_via="ngModel"-->
      <!--                  ></p-checkbox>-->
      <!--                </div>-->
      <!--                <div class="p-field-checkbox">-->
      <!--                  <p-checkbox-->
      <!--                    label="Kostenträger"-->
      <!--                    value="Kostenträger"-->
      <!--                    name="first-person-insured-via"-->
      <!--                    [(ngModel)]="data.first_patient.insured_via"-->
      <!--                    (onChange)="calculateBudget('first_patient')"-->
      <!--                    [required]="true"-->
      <!--                    #first_person_insured_via="ngModel"-->
      <!--                  ></p-checkbox>-->
      <!--                </div>-->
      <!--                &lt;!&ndash;              <div class="p-field-checkbox full-width">&ndash;&gt;-->
      <!--                <div class="p-field-checkbox">-->
      <!--                  <p-checkbox-->
      <!--                    label="Sonstiges"-->
      <!--                    value="Sonstiges"-->
      <!--                    name="first-person-insured-via"-->
      <!--                    [(ngModel)]="data.first_patient.insured_via"-->
      <!--                    (onChange)="calculateBudget('first_patient')"-->
      <!--                    [required]="true"-->
      <!--                    #first_person_insured_via="ngModel"-->
      <!--                  ></p-checkbox>-->
      <!--                </div>-->

      <!--                <div-->
      <!--                  class="p-field p-col-12 p-sm-3"-->
      <!--                  style="margin: 0"-->
      <!--                  *ngIf="data.first_patient.insured_via.includes('Sonstiges')"-->
      <!--                >-->
      <!--                  <input-->
      <!--                    id="first-person-insured_via_other_text"-->
      <!--                    name="first-person-insured_via_other_text"-->
      <!--                    [(ngModel)]="data.first_patient.insured_via_other_text"-->
      <!--                    type="text"-->
      <!--                    pInputText-->
      <!--                  />-->
      <!--                </div>-->
      <!--              </div>-->
      <!--              <div-->
      <!--                *ngIf="-->
      <!--                  first_person_insured_via.invalid &&-->
      <!--                  (first_person_insured_via.dirty ||-->
      <!--                    first_person_insured_via.touched)-->
      <!--                "-->
      <!--                class="p-invalid"-->
      <!--              >-->
      <!--                <div *ngIf="first_person_insured_via.errors?.required">-->
      <!--                  <small>Bitte wählen Sie einen Abrechnungsstatus</small>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->

      <!--          <div-->
      <!--            class="p-fluid p-formgrid p-grid"-->
      <!--            *ngIf="data.first_patient.insured_via.includes('Selbstzahler')"-->
      <!--          >-->
      <!--            <div class="p-field p-col-12">-->
      <!--              <label>Selbstzahler Budget</label>-->
      <!--              <div class="p-formgroup-inline" style="align-items: center">-->
      <!--                <div class="p-field-radiobutton">-->
      <!--                  <p-radioButton-->
      <!--                    [(ngModel)]="data.first_patient.selbstzahler_budget_type"-->
      <!--                    label="Flexibel"-->
      <!--                    name="first-person-selbstzahler-budget-type"-->
      <!--                    value="Flexibel"-->
      <!--                  ></p-radioButton>-->
      <!--                </div>-->
      <!--                <div class="p-field-radiobutton">-->
      <!--                  <p-radioButton-->
      <!--                    [(ngModel)]="data.first_patient.selbstzahler_budget_type"-->
      <!--                    label="Max"-->
      <!--                    name="first-person-selbstzahler-budget-type"-->
      <!--                    value="Max"-->
      <!--                  ></p-radioButton>-->
      <!--                </div>-->
      <!--                <div class="p-inputgroup" style="width: 130px">-->
      <!--                  <p-inputNumber-->
      <!--                    inputId="first-person-selbstzahler-budget-max"-->
      <!--                    name="first-person-selbstzahler-budget-max"-->
      <!--                    [(ngModel)]="data.first_patient.selbstzahler_budget_max"-->
      <!--                    [useGrouping]="false"-->
      <!--                    [disabled]="-->
      <!--                      data.first_patient.selbstzahler_budget_type !== 'Max'-->
      <!--                    "-->
      <!--                    mode="decimal"-->
      <!--                    locale="de-DE"-->
      <!--                    [minFractionDigits]="2"-->
      <!--                  ></p-inputNumber>-->
      <!--                  <span class="p-inputgroup-addon">Std</span>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->

      <!--          <div-->
      <!--            class="p-fluid p-formgrid p-grid"-->
      <!--            *ngIf="-->
      <!--              !onlySelbstzahlerSelected('first_patient') &&-->
      <!--              data.first_patient.degree_of_care &&-->
      <!--              data.first_patient.degree_of_care != 'I'-->
      <!--            "-->
      <!--            [class.alert-highlight]="-->
      <!--              !data.first_patient.request_37 &&-->
      <!--              isGkvOrPkvSelected('first_patient')-->
      <!--            "-->
      <!--          >-->
      <!--            <div-->
      <!--              class="p-field p-col-12 p-sm-4"-->
      <!--              [pTooltip]="-->
      <!--                !isGkvOrPkvSelected('first_patient')-->
      <!--                  ? 'Nur bei GKV oder PKV'-->
      <!--                  : ''-->
      <!--              "-->
      <!--            >-->
      <!--              <label for="first-person-nursing-person"-->
      <!--                >Pflegeperson vorhanden?</label-->
      <!--              >-->
      <!--              <div class="p-formgroup-inline has-form-height">-->
      <!--                <div class="p-field-radiobutton">-->
      <!--                  <p-radioButton-->
      <!--                    [(ngModel)]="data.first_patient.nursing_person"-->
      <!--                    (onClick)="calculateBudget('first_patient')"-->
      <!--                    label="Ja"-->
      <!--                    [disabled]="!isGkvOrPkvSelected('first_patient')"-->
      <!--                    name="first-person-nursing-person"-->
      <!--                    value="Ja"-->
      <!--                  ></p-radioButton>-->
      <!--                </div>-->
      <!--                <div class="p-field-radiobutton">-->
      <!--                  <p-radioButton-->
      <!--                    label="Nein"-->
      <!--                    [(ngModel)]="data.first_patient.nursing_person"-->
      <!--                    (onClick)="calculateBudget('first_patient')"-->
      <!--                    [disabled]="!isGkvOrPkvSelected('first_patient')"-->
      <!--                    name="first-person-nursing-person"-->
      <!--                    value="Nein"-->
      <!--                  ></p-radioButton>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->

      <!--            <div-->
      <!--              class="p-field p-col-12 p-sm-5"-->
      <!--              *ngIf="data.first_patient.nursing_person === 'Ja'"-->
      <!--            >-->
      <!--              <label for="first-person-nursing-person-name"-->
      <!--                >Name Pflegeperson</label-->
      <!--              >-->
      <!--              <input-->
      <!--                id="first-person-nursing-person-name"-->
      <!--                name="first-person-nursing-person-name"-->
      <!--                [(ngModel)]="data.first_patient.nursing_person_name"-->
      <!--                type="text"-->
      <!--                pInputText-->
      <!--              />-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div-->
      <!--            class="p-fluid p-formgrid p-grid"-->
      <!--            *ngIf="!onlySelbstzahlerSelected('first_patient')"-->
      <!--          >-->
      <!--            <div class="p-field p-col-12 p-sm-4">-->
      <!--              <label for="first-person-degree-of-care">Pflegegrad</label>-->
      <!--              <p-dropdown-->
      <!--                inputId="first-person-degree-of-care"-->
      <!--                name="first-person-degree-of-care"-->
      <!--                placeholder="Auswählen"-->
      <!--                optionValue="value"-->
      <!--                [showClear]="true"-->
      <!--                (onChange)="calculateBudget('first_patient')"-->
      <!--                [(ngModel)]="data.first_patient.degree_of_care"-->
      <!--                [options]="dependencies.degree_of_care_all"-->
      <!--              ></p-dropdown>-->
      <!--            </div>-->

      <!--            <div class="p-field p-col-12 p-sm-4">-->
      <!--              <label for="first-person-degree-of-care-since"-->
      <!--                >Pflegegrad seit</label-->
      <!--              >-->
      <!--              <p-calendar-->
      <!--                [firstDayOfWeek]="1"-->
      <!--                [showWeek]="true"-->
      <!--                [showButtonBar]="true"-->
      <!--                inputId="first-person-degree-of-care-since"-->
      <!--                name="first-person-degree-of-care-since"-->
      <!--                [(ngModel)]="data.first_patient.degree_of_care_since_string"-->
      <!--                dateFormat="dd.mm.yy"-->
      <!--                (onSelect)="calculateBudget('first_patient')"-->
      <!--                (onInput)="calculateBudget('first_patient')"-->
      <!--                dataType="string"-->
      <!--              ></p-calendar>-->
      <!--            </div>-->
      <!--          </div>-->

      <!--          <div-->
      <!--            class="p-fluid p-formgrid p-grid"-->
      <!--            *ngIf="!onlySelbstzahlerSelected('first_patient')"-->
      <!--          >-->
      <!--            <div class="p-field p-col-4">-->
      <!--              <label for="first-person-care-insurance-number">-->
      <!--                Pflegekassennummer (IK)-->
      <!--                <i-->
      <!--                  class="pi pi-info-circle"-->
      <!--                  pTooltip="Automatische Suche bei 5 Zeichen"-->
      <!--                ></i>-->
      <!--              </label>-->
      <!--              <p-autoComplete-->
      <!--                field="pflege_ik"-->
      <!--                inputId="first-person-care-insurance-number"-->
      <!--                name="first-person-care-insurance-number"-->
      <!--                [minLength]="5"-->
      <!--                [(ngModel)]="data.first_patient.care_insurance"-->
      <!--                [suggestions]="pflegekassen"-->
      <!--                (completeMethod)="searchPflegekassen($event)"-->
      <!--              >-->
      <!--                <ng-template let-multi pTemplate="item">-->
      <!--                  <div>{{ multi.name }}</div>-->
      <!--                </ng-template>-->
      <!--              </p-autoComplete>-->
      <!--            </div>-->

      <!--            <div class="p-field p-col-4">-->
      <!--              <label for="first-person-care-insurance">Pflegekasse</label>-->
      <!--              <input-->
      <!--                id="first-person-care-insurance"-->
      <!--                name="first-person-care-insurance"-->
      <!--                [(ngModel)]="data.first_patient.care_insurance.name"-->
      <!--                type="text"-->
      <!--                readonly-->
      <!--                pInputText-->
      <!--              />-->
      <!--            </div>-->

      <!--            <div class="p-field p-col-4">-->
      <!--              <label for="first-person-insurer-number">VS Nr</label>-->
      <!--              <input-->
      <!--                id="first-person-insurer-number"-->
      <!--                name="first-person-insurer-number"-->
      <!--                [(ngModel)]="data.first_patient.insurer_number"-->
      <!--                type="text"-->
      <!--                pInputText-->
      <!--              />-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <div
        class="content limit-width content-highlight-2"
        *ngIf="
          data.patient_type === 'B' ||
          (data.patient_type === 'C' && !isEditMode)
        "
      >
        <header class="content-header">
          <span *ngIf="data.patient_type === 'B'">Grunddaten Person 2</span>
          <span *ngIf="data.patient_type === 'C'"
            >Ansprechpartner im gleichen Haushalt</span
          >
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-sm-3" *ngIf="data.second_patient">
              <label for="second-person-form-of-address">Anrede *</label>
              <p-dropdown
                [(ngModel)]="data.second_patient.form_of_address"
                [options]="formOfAddress"
                inputId="second-person-form-of-address"
                #second_person_form_of_address="ngModel"
                name="second-person-form-of-address"
                placeholder="Bitte auswählen"
                [required]="true"
              ></p-dropdown>
              <div
                *ngIf="
                  second_person_form_of_address.invalid &&
                  (second_person_form_of_address.dirty ||
                    second_person_form_of_address.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="second_person_form_of_address.errors?.required">
                  <small>Bitte wählen Sie eine Anrede</small>
                </div>
              </div>
            </div>

            <div class="p-field p-col-12 p-sm-3">
              <label for="second-person-first-name">Vorname *</label>
              <input
                id="second-person-first-name"
                name="second-person-first-name"
                [(ngModel)]="data.second_patient.first_name"
                type="text"
                pInputText
                #second_person_first_name="ngModel"
                required
                [class.p-invalid]="
                  second_person_first_name.invalid &&
                  (second_person_first_name.dirty ||
                    second_person_first_name.touched)
                "
              />
              <div
                *ngIf="
                  second_person_first_name.invalid &&
                  (second_person_first_name.dirty ||
                    second_person_first_name.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="second_person_first_name.errors?.required">
                  <small>Bitte geben Sie einen Vornamen ein</small>
                </div>
              </div>
            </div>

            <div class="p-field p-col-12 p-sm-3">
              <label for="second-person-last-name">Nachname *</label>
              <input
                id="second-person-last-name"
                name="second-person-last-name"
                [(ngModel)]="data.second_patient.last_name"
                type="text"
                pInputText
                #second_person_last_name="ngModel"
                required
                [class.p-invalid]="
                  first_person_last_name.invalid &&
                  (first_person_last_name.dirty ||
                    first_person_last_name.touched)
                "
              />
              <div
                *ngIf="
                  second_person_last_name.invalid &&
                  (second_person_last_name.dirty ||
                    second_person_last_name.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="second_person_last_name.errors?.required">
                  <small>Bitte geben Sie einen Nachnamen ein</small>
                </div>
              </div>
            </div>

            <div class="p-field p-col-12 p-sm-3">
              <label for="second-person-birthday">Geburtsdatum</label>
              <p-calendar
                [showButtonBar]="true"
                [firstDayOfWeek]="1"
                [(ngModel)]="data.second_patient.birthday_date_string"
                inputId="second-person-birthday"
                name="second-person-birthday"
                dateFormat="dd.mm.yy"
                dataType="string"
              ></p-calendar>
            </div>
          </div>
        </div>
      </div>

      <div
        class="content limit-width content-highlight-2"
        *ngIf="
          data.patient_type === 'B' ||
          (data.patient_type === 'C' && !isEditMode)
        "
      >
        <header class="content-header">
          <span *ngIf="data.patient_type === 'B'">Kontaktdaten Person 2</span>
          <span *ngIf="data.patient_type === 'C'"
            >Kontaktdaten Ansprechpartner im gleichen Haushalt</span
          >
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-sm-4">
              <div class="p-field">
                <label for="second-person-phone-1">Telefon 1</label>
                <input
                  id="second-person-phone-1"
                  name="second-person-phone-1"
                  [(ngModel)]="data.second_patient.phone_1"
                  type="text"
                  pInputText
                />
              </div>
              <div class="p-field" style="margin-top: 5px">
                <input
                  id="second-person-phone-1-info"
                  name="second-person-phone-1-info"
                  placeholder="Informationen zu Telefon 1"
                  [(ngModel)]="data.second_patient.phone_1_info"
                  type="text"
                  pInputText
                />
              </div>
            </div>

            <div class="p-field p-col-12 p-sm-4">
              <div class="p-field">
                <label for="second-person-phone-2">Telefon 2</label>
                <input
                  id="second-person-phone-2"
                  name="second-person-phone-2"
                  [(ngModel)]="data.second_patient.phone_2"
                  type="text"
                  pInputText
                />
              </div>
              <div class="p-field" style="margin-top: 5px">
                <input
                  id="second-person-phone-2-info"
                  name="second-person-phone-2-info"
                  placeholder="Informationen zu Telefon 2"
                  [(ngModel)]="data.second_patient.phone_2_info"
                  type="text"
                  pInputText
                />
              </div>
            </div>

            <div class="p-field p-col-12 p-sm-4">
              <div class="p-field">
                <label for="second-person-email">E-Mail</label>
                <input
                  id="second-person-email"
                  name="second-person-email"
                  [(ngModel)]="data.second_patient.email"
                  type="email"
                  pInputText
                />
              </div>
              <div class="p-field" style="margin-top: 5px">
                <input
                  id="second-person-email-info"
                  name="second-person-email-info"
                  placeholder="Informationen zur E-Mail"
                  [(ngModel)]="data.second_patient.email_info"
                  type="email"
                  pInputText
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--      <div-->
      <!--        class="content limit-width content-highlight-2"-->
      <!--        *ngIf="data.patient_type === 'B'"-->
      <!--      >-->
      <!--        <header class="content-header">-->
      <!--          <span>Versicherungs- und Leistungsdaten Person 2</span>-->
      <!--        </header>-->

      <!--        <div class="content-body">-->
      <!--          <div class="p-fluid p-formgrid p-grid">-->
      <!--            <div class="p-field p-col-12">-->
      <!--              <div class="p-formgroup-inline" style="align-items: center">-->
      <!--                <div class="p-field-checkbox">-->
      <!--                  <p-checkbox-->
      <!--                    label="Selbstzahler"-->
      <!--                    name="second-person-insured-via"-->
      <!--                    value="Selbstzahler"-->
      <!--                    [(ngModel)]="data.second_patient.insured_via"-->
      <!--                    (onChange)="calculateBudget('second_patient')"-->
      <!--                    [required]="true"-->
      <!--                    #second_person_insured_via="ngModel"-->
      <!--                  ></p-checkbox>-->
      <!--                </div>-->
      <!--                <div class="p-field-checkbox">-->
      <!--                  <p-checkbox-->
      <!--                    label="Beihilfe"-->
      <!--                    value="Beihilfe"-->
      <!--                    name="second-person-insured-via"-->
      <!--                    [(ngModel)]="data.second_patient.insured_via"-->
      <!--                    (onChange)="calculateBudget('second_patient')"-->
      <!--                    [required]="true"-->
      <!--                    #second_person_insured_via="ngModel"-->
      <!--                  ></p-checkbox>-->
      <!--                </div>-->
      <!--                <div class="p-field-checkbox">-->
      <!--                  <p-checkbox-->
      <!--                    label="GKV"-->
      <!--                    name="second-person-insured-via"-->
      <!--                    value="GKV"-->
      <!--                    [(ngModel)]="data.second_patient.insured_via"-->
      <!--                    (onChange)="calculateBudget('second_patient')"-->
      <!--                    [required]="true"-->
      <!--                    #second_person_insured_via="ngModel"-->
      <!--                  ></p-checkbox>-->
      <!--                </div>-->
      <!--                <div class="p-field-checkbox">-->
      <!--                  <p-checkbox-->
      <!--                    label="PKV"-->
      <!--                    value="PKV"-->
      <!--                    name="second-person-insured-via"-->
      <!--                    [(ngModel)]="data.second_patient.insured_via"-->
      <!--                    (onChange)="calculateBudget('second_patient')"-->
      <!--                    [required]="true"-->
      <!--                    #second_person_insured_via="ngModel"-->
      <!--                  ></p-checkbox>-->
      <!--                </div>-->
      <!--                <div class="p-field-checkbox">-->
      <!--                  <p-checkbox-->
      <!--                    label="UV"-->
      <!--                    value="UV"-->
      <!--                    name="second-person-insured-via"-->
      <!--                    [(ngModel)]="data.second_patient.insured_via"-->
      <!--                    (onChange)="calculateBudget('second_patient')"-->
      <!--                    [required]="true"-->
      <!--                    #second_person_insured_via="ngModel"-->
      <!--                  ></p-checkbox>-->
      <!--                </div>-->
      <!--                <div class="p-field-checkbox">-->
      <!--                  <p-checkbox-->
      <!--                    label="SGB V"-->
      <!--                    value="SGB V"-->
      <!--                    name="second-person-insured-via"-->
      <!--                    [(ngModel)]="data.second_patient.insured_via"-->
      <!--                    (onChange)="calculateBudget('second_patient')"-->
      <!--                    [required]="true"-->
      <!--                    #second_person_insured_via="ngModel"-->
      <!--                  ></p-checkbox>-->
      <!--                </div>-->
      <!--                <div class="p-field-checkbox">-->
      <!--                  <p-checkbox-->
      <!--                    label="Kostenträger"-->
      <!--                    value="Kostenträger"-->
      <!--                    name="second-person-insured-via"-->
      <!--                    [(ngModel)]="data.second_patient.insured_via"-->
      <!--                    (onChange)="calculateBudget('second_patient')"-->
      <!--                    [required]="true"-->
      <!--                    #second_person_insured_via="ngModel"-->
      <!--                  ></p-checkbox>-->
      <!--                </div>-->
      <!--                <div class="p-field-checkbox">-->
      <!--                  <p-checkbox-->
      <!--                    label="Sonstiges"-->
      <!--                    value="Sonstiges"-->
      <!--                    name="second-person-insured-via"-->
      <!--                    [(ngModel)]="data.second_patient.insured_via"-->
      <!--                    (onChange)="calculateBudget('second_patient')"-->
      <!--                    [required]="true"-->
      <!--                    #second_person_insured_via="ngModel"-->
      <!--                  ></p-checkbox>-->
      <!--                </div>-->

      <!--                <div-->
      <!--                  class="p-field p-col-12 p-sm-3"-->
      <!--                  style="margin: 0"-->
      <!--                  *ngIf="data.second_patient.insured_via.includes('Sonstiges')"-->
      <!--                >-->
      <!--                  <input-->
      <!--                    id="second-person-insured_via_other_text"-->
      <!--                    name="second-person-insured_via_other_text"-->
      <!--                    [(ngModel)]="data.second_patient.insured_via_other_text"-->
      <!--                    type="text"-->
      <!--                    pInputText-->
      <!--                  />-->
      <!--                </div>-->
      <!--              </div>-->
      <!--              <div-->
      <!--                *ngIf="-->
      <!--                  second_person_insured_via.invalid &&-->
      <!--                  (second_person_insured_via.dirty ||-->
      <!--                    second_person_insured_via.touched)-->
      <!--                "-->
      <!--                class="p-invalid"-->
      <!--              >-->
      <!--                <div *ngIf="second_person_insured_via.errors?.required">-->
      <!--                  <small>Bitte wählen Sie einen Abrechnungsstatus</small>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->

      <!--          <div-->
      <!--            class="p-fluid p-formgrid p-grid"-->
      <!--            *ngIf="data.second_patient.insured_via.includes('Selbstzahler')"-->
      <!--          >-->
      <!--            <div class="p-field p-col-12">-->
      <!--              <label>Selbstzahler Budget</label>-->
      <!--              <div class="p-formgroup-inline" style="align-items: center">-->
      <!--                <div class="p-field-radiobutton">-->
      <!--                  <p-radioButton-->
      <!--                    [(ngModel)]="data.second_patient.selbstzahler_budget_type"-->
      <!--                    label="Flexibel"-->
      <!--                    name="second-person-selbstzahler-budget-type"-->
      <!--                    value="Flexibel"-->
      <!--                  ></p-radioButton>-->
      <!--                </div>-->
      <!--                <div class="p-field-radiobutton">-->
      <!--                  <p-radioButton-->
      <!--                    [(ngModel)]="data.second_patient.selbstzahler_budget_type"-->
      <!--                    label="Max"-->
      <!--                    name="second-person-selbstzahler-budget-type"-->
      <!--                    value="Max"-->
      <!--                  ></p-radioButton>-->
      <!--                </div>-->
      <!--                <div class="p-inputgroup" style="width: 130px">-->
      <!--                  <p-inputNumber-->
      <!--                    inputId="second-person-selbstzahler-budget-max"-->
      <!--                    name="second-person-selbstzahler-budget-max"-->
      <!--                    [(ngModel)]="data.second_patient.selbstzahler_budget_max"-->
      <!--                    [useGrouping]="false"-->
      <!--                    [disabled]="-->
      <!--                      data.second_patient.selbstzahler_budget_type !== 'Max'-->
      <!--                    "-->
      <!--                    mode="decimal"-->
      <!--                    locale="de-DE"-->
      <!--                    [minFractionDigits]="2"-->
      <!--                  ></p-inputNumber>-->
      <!--                  <span class="p-inputgroup-addon">Std</span>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->

      <!--          <div-->
      <!--            class="p-fluid p-formgrid p-grid"-->
      <!--            *ngIf="-->
      <!--              !onlySelbstzahlerSelected('second_patient') &&-->
      <!--              data.second_patient.degree_of_care &&-->
      <!--              data.second_patient.degree_of_care != 'I'-->
      <!--            "-->
      <!--            [class.alert-highlight]="-->
      <!--              !data.second_patient.request_37 &&-->
      <!--              isGkvOrPkvSelected('second_patient')-->
      <!--            "-->
      <!--          >-->
      <!--            <div-->
      <!--              class="p-field p-col-12 p-sm-4"-->
      <!--              [pTooltip]="-->
      <!--                !isGkvOrPkvSelected('second_patient')-->
      <!--                  ? 'Nur bei GKV oder PKV'-->
      <!--                  : ''-->
      <!--              "-->
      <!--            >-->
      <!--              <label for="second-person-nursing-person"-->
      <!--                >Pflegeperson vorhanden?</label-->
      <!--              >-->
      <!--              <div class="p-formgroup-inline has-form-height">-->
      <!--                <div class="p-field-radiobutton">-->
      <!--                  <p-radioButton-->
      <!--                    [(ngModel)]="data.second_patient.nursing_person"-->
      <!--                    (onClick)="calculateBudget('second_patient')"-->
      <!--                    label="Ja"-->
      <!--                    [disabled]="!isGkvOrPkvSelected('second_patient')"-->
      <!--                    name="second-person-nursing-person"-->
      <!--                    value="Ja"-->
      <!--                  ></p-radioButton>-->
      <!--                </div>-->
      <!--                <div class="p-field-radiobutton">-->
      <!--                  <p-radioButton-->
      <!--                    label="Nein"-->
      <!--                    [(ngModel)]="data.second_patient.nursing_person"-->
      <!--                    (onClick)="calculateBudget('second_patient')"-->
      <!--                    [disabled]="!isGkvOrPkvSelected('second_patient')"-->
      <!--                    name="second-person-nursing-person"-->
      <!--                    value="Nein"-->
      <!--                  ></p-radioButton>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->

      <!--            <div-->
      <!--              class="p-field p-col-12 p-sm-5"-->
      <!--              *ngIf="data.second_patient.nursing_person === 'Ja'"-->
      <!--            >-->
      <!--              <label for="second-person-nursing-person-name"-->
      <!--                >Name Pflegeperson</label-->
      <!--              >-->
      <!--              <input-->
      <!--                id="second-person-nursing-person-name"-->
      <!--                name="second-person-nursing-person-name"-->
      <!--                [(ngModel)]="data.second_patient.nursing_person_name"-->
      <!--                type="text"-->
      <!--                pInputText-->
      <!--              />-->
      <!--            </div>-->
      <!--          </div>-->

      <!--          <div-->
      <!--            class="p-fluid p-formgrid p-grid"-->
      <!--            *ngIf="!onlySelbstzahlerSelected('second_patient')"-->
      <!--          >-->
      <!--            <div class="p-field p-col-12 p-sm-4">-->
      <!--              <label for="second-person-degree-of-care">Pflegegrad</label>-->
      <!--              <p-dropdown-->
      <!--                inputId="second-person-degree-of-care"-->
      <!--                name="second-person-degree-of-care"-->
      <!--                placeholder="Auswählen"-->
      <!--                optionValue="value"-->
      <!--                [showClear]="true"-->
      <!--                (onChange)="calculateBudget('second_patient')"-->
      <!--                [(ngModel)]="data.second_patient.degree_of_care"-->
      <!--                [options]="dependencies.degree_of_care_all"-->
      <!--              ></p-dropdown>-->
      <!--            </div>-->

      <!--            <div class="p-field p-col-12 p-sm-4">-->
      <!--              <label for="second-person-degree-of-care-since"-->
      <!--                >Pflegegrad seit</label-->
      <!--              >-->
      <!--              <p-calendar-->
      <!--                [firstDayOfWeek]="1"-->
      <!--                [showWeek]="true"-->
      <!--                [showButtonBar]="true"-->
      <!--                inputId="second-person-degree-of-care-since"-->
      <!--                name="second-person-degree-of-care-since"-->
      <!--                [(ngModel)]="data.second_patient.degree_of_care_since_string"-->
      <!--                dateFormat="dd.mm.yy"-->
      <!--                (onSelect)="calculateBudget('second_patient')"-->
      <!--                (onInput)="calculateBudget('second_patient')"-->
      <!--                dataType="string"-->
      <!--              ></p-calendar>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="content limit-width">
        <header class="content-header">
          <span>Leistungsbeginn</span>
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-sm-5">
              <label>Einsatzbeginn</label>
              <div class="p-formgroup-inline has-form-height">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Zum nächst möglichen Termin"
                    name="first-person-start-of-operation"
                    [(ngModel)]="data.start_of_operation"
                    value="Zum nächst möglichen Termin"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Datum"
                    name="first-person-start-of-operation"
                    [(ngModel)]="data.start_of_operation"
                    value="Datum"
                  ></p-radioButton>
                </div>
              </div>
            </div>
            <!-- TODO: das datum im backend leeren wenn "Nächster Termin" ausgewählt wurde -->
            <div
              class="p-field p-col-12 p-sm-3"
              *ngIf="data.start_of_operation === 'Datum'"
            >
              <label for="start-of-operation-date">Wann</label>
              <p-calendar
                [firstDayOfWeek]="1"
                [showWeek]="true"
                [showButtonBar]="true"
                [(ngModel)]="data.start_of_operation_date_string"
                inputId="start-of-operation-date"
                name="start-of-operation-date"
                dateFormat="dd.mm.yy"
                dataType="string"
              ></p-calendar>
            </div>
          </div>
          <!--          <div class="p-fluid p-formgrid p-grid">-->
          <!--            <div class="p-field p-col-12">-->
          <!--              <label for="first-person-service-scope">Leistungsumfang</label>-->
          <!--              <textarea-->
          <!--                id="first-person-service-scope"-->
          <!--                name="first-person-service-scope"-->
          <!--                [(ngModel)]="data.service_scope"-->
          <!--                pInputTextarea-->
          <!--              ></textarea>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>

      <div class="content limit-width content-highlight-1">
        <header class="content-header">
          <span>Leistungsart Person 1</span>
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <label>Leistung als *</label>
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.service_for"
                    label="Reine Hauswirtschaft"
                    name="first-person-service-for"
                    value="Reine HW"
                    #first_person_service_for="ngModel"
                    required
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.service_for"
                    label="Reine Betreuung"
                    name="first-person-service-for"
                    value="Reine BE"
                    #first_person_service_for="ngModel"
                    required
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.service_for"
                    label="Hauswirtschaft und Betreuung"
                    name="first-person-service-for"
                    value="HW und BE"
                    #first_person_service_for="ngModel"
                    required
                  ></p-radioButton>
                </div>
              </div>
              <div
                *ngIf="
                  first_person_service_for.invalid &&
                  (first_person_service_for.dirty ||
                    first_person_service_for.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="first_person_service_for.errors?.required">
                  <small>Bitte wählen Sie etwas aus</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="content limit-width content-highlight-2"
        *ngIf="data.patient_type === 'B'"
      >
        <header class="content-header">
          <span>Leistungsart Person 2</span>
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-6">
              <label>Leistung als *</label>
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.service_for"
                    label="Reine HW"
                    name="second-person-service-for"
                    value="Reine HW"
                    #second_person_service_for="ngModel"
                    required
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.service_for"
                    label="Reine BE"
                    name="second-person-service-for"
                    value="Reine BE"
                    #second_person_service_for="ngModel"
                    required
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.service_for"
                    label="HW und BE"
                    name="second-person-service-for"
                    value="HW und BE"
                    #second_person_service_for="ngModel"
                    required
                  ></p-radioButton>
                </div>
              </div>
              <div
                *ngIf="
                  second_person_service_for.invalid &&
                  (second_person_service_for.dirty ||
                    second_person_service_for.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="second_person_service_for.errors?.required">
                  <small>Bitte wählen Sie etwas aus</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content limit-width">
        <header class="content-header">
          <span>Abschluss</span>
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <label for="summary_from_recommendation">Zusammenfassung</label>
              <textarea
                id="summary_from_recommendation"
                name="summary_from_recommendation"
                [rows]="5"
                [(ngModel)]="data.summary_from_recommendation"
                pInputTextarea
              ></textarea>
            </div>
          </div>

          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <div class="p-formgroup-inline" style="align-items: center">
                <div class="p-field-checkbox">
                  <p-checkbox
                    label="Zustimmung zur Weitergabe der Daten telefonisch erteilt"
                    name="phone_consent"
                    [binary]="true"
                    [(ngModel)]="data.phone_consent"
                  ></p-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="content-footer limit-width" [class.sticky]="isEditMode">
      <span></span>
      <button
        pButton
        (click)="save()"
        [label]="isEditMode ? 'Änderungen speichern' : 'Kunde erstellen'"
        type="button"
        [disabled]="submitted"
        [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
      ></button>
    </div>
  </div>
</form>
