<p-toast position="top-left" styleClass="toast-error" key="error"></p-toast>
<p-toast position="top-left" styleClass="toast-success" key="success"></p-toast>

<app-phone-call *ngIf="user?.is_user_system"></app-phone-call>

<header>
  <div class="logo-container">
    <i class="pi pi-bars" (click)="toggleMobileNav()"></i>
    <a routerLink="/" class="logo"><b>SBG Saar</b> Webportal</a>
  </div>
  <div class="header-center-container" *ngIf="user?.is_user_system">
    <div class="p-input-icon-left">
      <div class="suggestions-container" *ngIf="isSuggestionsOpen">
        <span class="suggestion-headline"
          ><i class="pi pi-clock"></i> Zuletzt gesucht</span
        >
        <div
          class="suggestions"
          (click)="searchSelected(result)"
          *ngFor="let result of suggestionResults"
        >
          <div class="search-result-data">
            <span class="search-result-name">
              <b>{{ result.full_name }}</b>
              <span class="search-result-id" *ngIf="result.id"
                >#{{ result.id }}</span
              >
              <i
                *ngIf="result.status"
                class="p-tag p-tag-rounded p-tag-{{ result.status }}"
                >{{ result.status_string }}</i
              >
              <i
                *ngIf="result.is_paused"
                class="p-tag p-tag-rounded p-tag-PAUSED"
                >Pausiert</i
              >
            </span>
            <span class="search-result-address">
              {{ result.zipcode }} {{ result.city }} {{ result.city_part }}
            </span>
            <span
              class="search-result-address"
              *ngIf="result.type === 'contact_person'"
            >
              {{ result.customer_name }}
            </span>
          </div>
          <div class="search-result-infos">
            <span
              class="search-result-type"
              *ngIf="result.type === 'caregiver'"
            >
              BK
            </span>
            <span class="search-result-type" *ngIf="result.type === 'car'">
              PKW
            </span>
            <span
              class="search-result-type"
              *ngIf="result.type === 'multiplier'"
            >
              MULTI
            </span>
            <span class="search-result-type" *ngIf="result.type === 'patient'">
              KUNDE
            </span>
            <span class="search-result-type" *ngIf="result.type === 'document'">
              DOKUMENT
            </span>
            <span
              class="search-result-type"
              *ngIf="result.type === 'contact_person'"
            >
              AP
            </span>
          </div>
        </div>
      </div>

      <i class="pi pi-search"></i>
      <p-autoComplete
        [(ngModel)]="query"
        [delay]="1000"
        (click)="showSuggestions()"
        (onBlur)="hideSuggestions()"
        [suggestions]="results"
        (completeMethod)="search($event)"
        (onSelect)="searchSelected($event)"
        [completeOnFocus]="true"
        emptyMessage="Nichts gefunden"
        placeholder="Suche..."
      >
        <ng-template let-result pTemplate="item">
          <div class="search-result-data">
            <span class="search-result-name">
              <b>{{ result.full_name }}</b>
              <span class="search-result-id" *ngIf="result.id"
                >#{{ result.id }}</span
              >
              <i
                *ngIf="result.status"
                class="p-tag p-tag-rounded p-tag-{{ result.status }}"
                >{{ result.status_string }}</i
              >
              <i
                *ngIf="result.is_paused"
                class="p-tag p-tag-rounded p-tag-PAUSED"
                >Pausiert</i
              >
            </span>
            <span class="search-result-address">
              {{ result.zipcode }} {{ result.city }} {{ result.city_part }}
            </span>
            <span
              class="search-result-address"
              *ngIf="result.type === 'contact_person'"
            >
              {{ result.customer_name }}
            </span>
          </div>
          <div class="search-result-infos">
            <span
              class="search-result-type"
              *ngIf="result.type === 'caregiver'"
            >
              BK
            </span>
            <span class="search-result-type" *ngIf="result.type === 'car'">
              PKW
            </span>
            <span
              class="search-result-type"
              *ngIf="result.type === 'multiplier'"
            >
              MULTI
            </span>
            <span class="search-result-type" *ngIf="result.type === 'patient'">
              KUNDE
            </span>
            <span class="search-result-type" *ngIf="result.type === 'document'">
              DOKUMENT
            </span>
            <span
              class="search-result-type"
              *ngIf="result.type === 'contact_person'"
            >
              AP
            </span>
          </div>
        </ng-template>
      </p-autoComplete>
    </div>

    <div class="header-buttons" #headerButtons>
<!--      <div-->
<!--        class="header-button no-select"-->
<!--        [pTooltip]="'Meine Notizen'"-->
<!--        tooltipPosition="bottom"-->
<!--      >-->
<!--        <i-->
<!--          class="pi pi-list"-->
<!--          pBadge-->
<!--          [value]="'2'"-->
<!--        ></i>-->
<!--      </div>-->

      <div
        [class.active]="todosAreOpen"
        class="header-button no-select"
        (click)="op.toggle($event)"
        [pTooltip]="todosAreOpen ? '' : 'Meine Todos'"
        tooltipPosition="bottom"
      >
        <i
          *ngIf="notificationsCount == 0"
          [class.color-gray]="todosAreOpen"
          class="pi pi-check-circle"
        ></i>

        <i
          *ngIf="notificationsCount > 0"
          [class.color-gray]="todosAreOpen"
          class="pi pi-check-circle alert-badge"
          pBadge
          [value]="notificationsCount.toString()"
        ></i>
      </div>

      <p-overlayPanel
        [appendTo]="headerButtons"
        #op
        (onShow)="todosOpened()"
        (onHide)="todosClosed()"
      >
        <ng-template pTemplate>
          <div
            style="padding: 20px 0"
            class="content-no-message"
            *ngIf="notifications.length === 0"
          >
            Keine Mitteilungen vorhanden
          </div>

          <span
            class="todo-header-title with-action"
            *ngIf="notifications.length > 0"
            >Mitteilungen
            <i
              (click)="readAllNotifications()"
              class="pi pi-eye"
              pTooltip="Alle als gelesen markieren"
            ></i
          ></span>
          <div
            class="todo-header-container"
            *ngFor="let notification of notifications"
          >
            <div
              class="todo-header"
              (click)="openShowTodoDialog(null, notification.notifiable_id)"
            >
              <strong>{{ notification.text }}</strong>
              <span *ngIf="notification.description">{{
                notification.description
              }}</span>
            </div>
            <div
              (click)="readNotification(notification.id)"
              class="todo-header-action"
              pTooltip="Als gelesen markieren"
            >
              <i class="pi pi-eye"></i>
            </div>
          </div>

<!--          <span class="todo-header-title" *ngIf="todos.length > 0">Todos</span>-->
<!--          <div-->
<!--            class="todo-header"-->
<!--            (click)="openShowTodoDialog(todo)"-->
<!--            *ngFor="let todo of todos"-->
<!--          >-->
<!--            <strong>{{ todo.title }}</strong>-->
<!--            <span-->
<!--              >zu erledigen-->
<!--              {{ todo.for_when_type === "FINISH_AT" ? "am" : "bis" }}-->
<!--              {{ todo.for_when_string }}</span-->
<!--            >-->
<!--          </div>-->
        </ng-template>
      </p-overlayPanel>

      <div
        class="header-button"
        (click)="openTodoFormDialog()"
        pTooltip="Neues Todo"
        tooltipPosition="bottom"
      >
        <i class="pi pi-pencil"></i>
      </div>

      <i class="header-divider"></i>

      <div
        class="header-button"
        (click)="openSendLetterDialog()"
        pTooltip="Neuer Brief"
        tooltipPosition="bottom"
      >
        <i class="pi pi-send"></i>
      </div>

      <div
        class="header-button"
        (click)="openSendSystemMessageDialog()"
        pTooltip="Mitteilungen"
        tooltipPosition="bottom"
      >
        <i class="pi pi-envelope"></i>
      </div>
    </div>
  </div>

  <div class="user-container-wrapper" #menuContainer>
    <div class="user-container" (click)="menu.toggle($event)">
      <div *ngIf="user">
        <div style="margin: 0 20px 0 0" *ngIf="impersonatedFrom">
          <i class="pi pi-key"></i>
          {{ impersonatedFrom.user_system.first_name[0] }}.
          {{ impersonatedFrom.user_system.last_name }}
        </div>

        <i
          [class.pi-user]="!inProgress"
          [class.pi-spinner]="inProgress"
          [class.pi-spin]="inProgress"
          class="pi pi-user"
        ></i>
        <div *ngIf="user.is_caregiver">
          {{ user.caregiver.first_name[0] }}.
          {{ user.caregiver.last_name }}
        </div>

        <div *ngIf="user.is_multiplier">
          {{ user.name }}
        </div>

        <div *ngIf="user.is_user_system">
          {{ user.user_system.first_name[0] }}.
          {{ user.user_system.last_name }}
        </div>

        <i class="pi pi-chevron-down"></i>
      </div>
    </div>
    <p-menu
      styleClass="header-menu"
      #menu
      [popup]="true"
      [model]="userMenuItems"
    ></p-menu>
  </div>
</header>
