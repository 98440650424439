<app-loader [loading]="loading"></app-loader>
<app-no-permission *ngIf="!hasPermission"></app-no-permission>

<div class="content" *ngIf="!loading && hasPermission">
  <header class="content-header">
    <span>Alle Systembenutzer</span>

    <div class="content-header-buttons">
      <a routerLink="/administration/users/new">
        <button
          pButton
          label="Neuer Benutzer"
          type="button"
          icon="pi pi-plus"
          iconPos="left"
          class="p-button-outlined"
        ></button>
      </a>

      <div
        class="table-search-container"
        [class.searchfield-active]="globalFilterValue != ''"
      >
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            (input)="filterTableGlobal($event)"
            placeholder="Suche..."
            [(ngModel)]="globalFilterValue"
          />
        </div>
      </div>
    </div>
  </header>
</div>

<div class="content" *ngIf="!loading && hasPermission">
  <div class="content-body content-body-full-height-with-tabs without-padding">
    <p-table
      #dt
      [value]="users"
      [globalFilterFields]="['id', 'first_name', 'last_name', 'user.name']"
      [rowHover]="true"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{totalRecords} Einträge"
      [rows]="50"
      [paginator]="true"
      scrollHeight="100%"
      [scrollable]="true"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th pSortableColumn="user.name">
            Benutzername <p-sortIcon field="user.name"></p-sortIcon>
          </th>
          <th pSortableColumn="first_name">
            Vorname <p-sortIcon field="first_name"></p-sortIcon>
          </th>
          <th pSortableColumn="last_name">
            Nachname <p-sortIcon field="last_name"></p-sortIcon>
          </th>
          <th style="max-width: 50px"></th>
          <th>Rollen</th>
          <th style="width: 150px" pSortableColumn="user.deleted_at">
            Account <p-sortIcon field="user.deleted_at"></p-sortIcon>
          </th>
          <th style="width: 100px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-user>
        <tr>
          <td>
            <a
              class="color-main1"
              routerLink="/administration/users/{{ user.id }}"
            >
              {{ user.user.name }}
            </a>
          </td>
          <td>{{ user.first_name }}</td>
          <td>{{ user.last_name }}</td>
          <td style="max-width: 50px">
            <i
              *ngIf="user.user.super_admin"
              class="pi pi-key color-main1"
              pTooltip="SUPER ADMIN"
            ></i>
          </td>
          <td>{{ user.role_names || "-" }}</td>
          <td>
            <span
              *ngIf="user.user.deleted_at"
              class="p-tag p-tag-danger p-tag-rounded"
              >Inaktiv</span
            >
            <span *ngIf="!user.user.deleted_at" class="p-tag p-tag-rounded"
              >Aktiv</span
            >
          </td>
          <td class="table-action" style="width: 100px">
            <a routerLink="/administration/users/{{ user.id }}/edit">
              <i
                class="pi pi-pencil"
                [class.with-margin-right]="isSuperAdmin"
                pTooltip="Bearbeiten"
                tooltipPosition="left"
              ></i>
            </a>

            <i
              class="pi pi-lock"
              *ngIf="isSuperAdmin"
              (click)="loginUsingId(user.user_id)"
              pTooltip="Als Benutzer einloggen"
              tooltipPosition="left"
            ></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
