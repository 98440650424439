<p-confirmPopup></p-confirmPopup>
<app-loader [loading]="loading"></app-loader>
<app-no-permission *ngIf="!hasPermission"></app-no-permission>

<div class="content-actions limit-width" *ngIf="!loading && hasPermission">
  <h2>
    <i class="tio">user_outlined</i>
    {{ isEditMode ? "Benutzer bearbeiten" : "Neuer Benutzer" }}
  </h2>

  <button
    *ngIf="
      isEditMode &&
      !data.user.deleted_at &&
      authService.can('Verwaltung.Systembenutzer deaktivieren')
    "
    pButton
    (click)="deactivate($event)"
    label="Deaktivieren"
    type="button"
    icon="pi pi-times"
    class="p-button-danger"
  ></button>
  <button
    *ngIf="
      isEditMode &&
      data.user.deleted_at &&
      authService.can('Verwaltung.Systembenutzer deaktivieren')
    "
    pButton
    (click)="activate($event)"
    label="Aktivieren"
    type="button"
    icon="pi pi-check"
    class="p-button"
  ></button>
</div>

<form #form="ngForm">
  <div class="content limit-width" *ngIf="!loading && hasPermission">
    <header class="content-header">
      <span>Daten</span>
    </header>

    <div class="content-body">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12">
          <div class="p-formgroup-inline">
            <div class="p-field-checkbox">
              <p-checkbox
                label="Für Beratungseinsätze"
                name="for_consulting"
                [binary]="true"
                [(ngModel)]="data.for_consulting"
              ></p-checkbox>
            </div>

            <div class="p-field-checkbox">
              <p-checkbox
                label="Für THC"
                name="for_thc"
                [binary]="true"
                [(ngModel)]="data.for_thc"
              ></p-checkbox>
            </div>

            <div class="p-field-checkbox">
              <p-checkbox
                label="Als Betreuer"
                name="as_supervisor"
                [binary]="true"
                [(ngModel)]="data.as_supervisor"
              ></p-checkbox>
            </div>
          </div>
        </div>
      </div>

      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-4">
          <label for="form_of_address">Anrede *</label>
          <p-dropdown
            [(ngModel)]="data.form_of_address"
            [options]="formOfAddress"
            inputId="form_of_address"
            #form_of_address="ngModel"
            name="form-of-address"
            placeholder="Bitte auswählen"
            [required]="true"
          ></p-dropdown>
          <div
            *ngIf="
              form_of_address.invalid &&
              (form_of_address.dirty || form_of_address.touched)
            "
            class="p-invalid"
          >
            <div *ngIf="form_of_address.errors?.required">
              <small>Bitte wählen Sie eine Anrede</small>
            </div>
          </div>
        </div>

        <div class="p-field p-col-4">
          <label for="first-name">Vorname *</label>
          <input
            id="first-name"
            name="first-name"
            [(ngModel)]="data.first_name"
            type="text"
            pInputText
            #firstname="ngModel"
            required
            [class.p-invalid]="
              firstname.invalid && (firstname.dirty || firstname.touched)
            "
          />
          <div
            *ngIf="firstname.invalid && (firstname.dirty || firstname.touched)"
            class="p-invalid"
          >
            <div *ngIf="firstname.errors?.required">
              <small>Bitte geben Sie einen Vornamen ein</small>
            </div>
          </div>
        </div>

        <div class="p-field p-col-4">
          <label for="last-name">Nachname *</label>
          <input
            id="last-name"
            name="last-name"
            [(ngModel)]="data.last_name"
            type="text"
            pInputText
            #lastname="ngModel"
            required
            [class.p-invalid]="
              lastname.invalid && (lastname.dirty || lastname.touched)
            "
          />
          <div
            *ngIf="lastname.invalid && (lastname.dirty || lastname.touched)"
            class="p-invalid"
          >
            <div *ngIf="lastname.errors?.required">
              <small>Bitte geben Sie einen Nachnamen ein</small>
            </div>
          </div>
        </div>
      </div>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-4">
          <label for="bday">Geburtsdatum</label>
          <p-calendar
            [firstDayOfWeek]="1"
            inputId="bday"
            [showButtonBar]="true"
            name="bday"
            [(ngModel)]="data.birthday_date_string"
            dateFormat="dd.mm.yy"
            [showIcon]="true"
            dataType="string"
          ></p-calendar>
        </div>

        <div class="p-field p-col-4">
          <label for="phone-1">Telefon 1</label>
          <input
            id="phone-1"
            name="phone-1"
            [(ngModel)]="data.phone_1"
            type="text"
            pInputText
          />
        </div>
        <div class="p-field p-col-4">
          <label for="phone-2">Telefon 2</label>
          <input
            id="phone-2"
            name="phone-2"
            [(ngModel)]="data.phone_2"
            type="text"
            pInputText
          />
        </div>
      </div>
    </div>
  </div>

  <div class="content limit-width" *ngIf="!loading && hasPermission">
    <header class="content-header">
      <span>Telefonsystem</span>
    </header>

    <div class="content-body">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-4">
          <label for="phone-identifier">
            Telefon Kennung
            <i
              class="pi pi-info-circle"
              pTooltip="Zum identifizieren des Benutzers für die Telefonanlage."
            ></i>
          </label>
          <input
            id="phone-identifier"
            name="phone-identifier"
            [(ngModel)]="data.phone_identifier"
            type="text"
            pInputText
          />
        </div>

        <!--        <div class="p-field p-col-4">-->
        <!--          <label for="line-number">-->
        <!--            Pflegeherzen Line Number-->
        <!--            <i-->
        <!--              class="pi pi-info-circle"-->
        <!--              pTooltip="<LineNumberSC> aus der Telefonanlage. Wird benötigt zum erkennen ob ein Anruf für Pflegeherzen gemacht wird."-->
        <!--            ></i>-->
        <!--          </label>-->
        <!--          <input-->
        <!--            id="line-number"-->
        <!--            name="line-number"-->
        <!--            [(ngModel)]="data.pflegeherzen_line_number"-->
        <!--            type="text"-->
        <!--            pInputText-->
        <!--          />-->
        <!--        </div>-->

        <!--        <div class="p-field p-col-4">-->
        <!--          <div class="p-formgroup-inline">-->
        <!--            <div class="p-field-checkbox">-->
        <!--              <p-checkbox-->
        <!--                label="Nur SBG Benutzer"-->
        <!--                [binary]="true"-->
        <!--                inputId="sbg_only"-->
        <!--                [(ngModel)]="data.sbg_only"-->
        <!--                name="sbg_only"-->
        <!--              ></p-checkbox>-->

        <!--              <i-->
        <!--                class="pi pi-info-circle"-->
        <!--                pTooltip="Nur anhaken, wenn dieser Benutzer nur für SBG und nicht Pflegeherzen arbeitet."-->
        <!--              ></i>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
  </div>

  <div class="content limit-width" *ngIf="!loading && hasPermission">
    <header class="content-header">
      <span>Account</span>
    </header>

    <div class="content-body">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-4">
          <label for="user-name"> Benutzername * </label>
          <input
            id="user-name"
            name="user-name"
            [(ngModel)]="data.user.name"
            type="text"
            pInputText
            #username="ngModel"
            required
            [class.p-invalid]="
              username.invalid && (username.dirty || username.touched)
            "
          />
          <div
            *ngIf="username.invalid && (username.dirty || username.touched)"
            class="p-invalid"
          >
            <div *ngIf="username.errors?.required">
              <small>Bitte geben Sie einen Benutzernamen ein</small>
            </div>
          </div>
        </div>
        <div class="p-field p-col-4">
          <label for="email"
            >E-Mail * <i class="pi pi-info-circle" pTooltip="Für den Login"></i
          ></label>
          <input
            id="email"
            name="email"
            [(ngModel)]="data.user.email"
            type="email"
            pInputText
            #email="ngModel"
            required
            [class.p-invalid]="email.invalid && (email.dirty || email.touched)"
          />
          <div
            *ngIf="email.invalid && (email.dirty || email.touched)"
            class="p-invalid"
          >
            <div *ngIf="email.errors?.required">
              <small>Bitte geben Sie eine E-Mail ein</small>
            </div>
          </div>
        </div>
        <div class="p-field p-col-4">
          <label for="password">
            Passwort
            <i
              *ngIf="isEditMode"
              class="pi pi-info-circle"
              pTooltip="Lassen Sie das Passwort leer, falls Sie es nicht ändern möchten"
            ></i>
          </label>
          <input
            id="password"
            name="password"
            [(ngModel)]="data.user.password"
            type="password"
            pInputText
          />
        </div>
      </div>

      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12">
          <label for="roles">Rollen</label>
          <p-multiSelect
            inputId="roles"
            [options]="roles"
            optionLabel="name"
            optionValue="id"
            [filter]="false"
            [showHeader]="false"
            name="roles"
            [(ngModel)]="data.role_ids"
          ></p-multiSelect>
        </div>
      </div>

      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12">
          <label for="signature">Signatur</label>
          <textarea
            id="signature"
            name="signature"
            [(ngModel)]="data.signature"
            pInputTextarea
            [rows]="15"
          ></textarea>
        </div>
      </div>

      <div class="p-fluid p-formgrid p-grid" *ngIf="data.signature">
        <div class="p-field p-col-12">
          <label for="signature">Signatur Vorschau</label>
          <div [innerHTML]="data.signature"></div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="content-footer limit-width"
    [class.sticky]="isEditMode"
    *ngIf="!loading && hasPermission"
  >
    <span></span>

    <button
      (click)="save()"
      pButton
      [label]="isEditMode ? 'Änderungen speichern' : 'Benutzer erstellen'"
      type="button"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>
