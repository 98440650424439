import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { TitleService } from 'src/app/services/title.service'
import { DialogService } from 'primeng/dynamicdialog'
import { DesiredDateService } from '../../../services/desired-date.service'
import { PhoneCallService } from '../../../services/phone-call.service'
import {
  EventBusService,
  GlobalEvent,
} from '../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { CancelledDateService } from '../../../services/cancelled-date.service'
import { AddCancelledDateDialogComponent } from '../../../components/dialogs/add-cancelled-date-dialog/add-cancelled-date-dialog.component'

@Component({
  selector: 'app-cancelled-dates-all',
  templateUrl: './cancelled-dates-all.component.html',
})
export class CancelledDatesAllComponent implements OnInit {
  @ViewChild('dt') table: ElementRef | any = null

  public globalFilterValue = ''
  public loading = false
  private eventBusSubscription: Subscription = new Subscription()
  public firstPage = 0

  // TODO: model erstellen
  public cancelledDates: any = []

  constructor(
    public phoneCallService: PhoneCallService,
    private dialogService: DialogService,
    private router: Router,
    private eventbus: EventBusService,
    private desiredDateService: DesiredDateService,
    private cancelledDateService: CancelledDateService,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    this.loadCancelledDates()
    this.listenForEventbus()
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  public navigateToDetail(id: number): void {
    this.router.navigate(['/patients', id])
  }

  // TODO: model erstellen
  public openAddCancelledDateDialog(cancelledDate: any): void {
    this.dialogService.open(AddCancelledDateDialogComponent, {
      header: 'Absage bearbeiten',
      width: '640px',
      styleClass: 'dialog-container',
      data: {
        isEdit: true,
        date: cancelledDate,
      },
    })
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.CancelledDateChanged:
            this.loadCancelledDates(false)
        }
      }
    )
  }

  private loadCancelledDates(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    // TODO: model erstellen
    this.cancelledDateService.load().subscribe((data: any) => {
      this.cancelledDates = data

      if (withLoading) {
        this.loading = false
      }

      setTimeout(() => {
        if (this.table) {
          const totalItems = this.table._totalRecords

          this.globalFilterValue = this.table.filters.global?.value ?? ''

          if (
            this.table._first > totalItems ||
            this.table._rows > totalItems
          ) {
            this.firstPage = 0
          }
        }
      })
    })
  }
}
