import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { StatusCodes } from 'http-status-codes'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { DocumentService } from '../../../services/document.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import { ConfirmationService } from 'primeng/api'

@Component({
  selector: 'app-add-global-document-dialog',
  templateUrl: './add-global-document-dialog.component.html',
})
export class AddGlobalDocumentDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false
  private selectedFile: any = null

  public isEdit = false
  public data: any = {}
  public values = {
    name: '',
    comment: '',
    selected_file: '',
  }
  public submitted = false
  public submittedDelete = false

  constructor(
    private ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private documentService: DocumentService,
    private eventbus: EventBusService,
    private toastService: ToastService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.isEdit = this.config.data.document !== null

    if (this.isEdit) {
      const { name, comment } = this.config.data.document

      this.values.name = name
      this.values.comment = comment
    }

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public setSelectedFile(event: any): any {
    this.selectedFile = event.target.files[0]
  }

  /**
   * Lädt eine Datei hoch.
   */
  public upload(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    const formData = new FormData()
    formData.append('file', this.selectedFile)
    formData.append('name', this.values.name)
    formData.append('comment', this.values.comment)

    const subscription = this.isEdit
      ? this.documentService.editGlobal(
          this.config.data.document.id,
          this.values
        )
      : this.documentService.uploadGlobal(formData)

    subscription.subscribe(
      () => {
        this.submitted = false
        this.eventbus.emit(GlobalEvent.DocumentListReload)
        this.ref.close()
        this.toastService.success(
          'Dokument gespeichert',
          'Das Dokument wurde erfolgreich gespeichert'
        )
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }

  /**
   * Bearbeitet ein Dokument.
   */
  public change(): void {}

  public remove(event: any): void {
    this.confirmationService.confirm({
      target: event.target ?? undefined,
      message: 'Dokument wirklich löschen?',
      accept: () => {
        this.submittedDelete = true

        this.documentService
          .removeGlobal(this.config.data.document.id)
          .subscribe(
            () => {
              this.submittedDelete = false
              this.eventbus.emit(GlobalEvent.DocumentListReload)
              this.ref.close()
              this.toastService.success(
                'Dokument gelöscht',
                'Das Dokument wurde erfolgreich gelöscht'
              )
            },
            () => {
              this.submittedDelete = false
              this.toastService.error(
                'Löschen fehlgeschlagen',
                'Das Dokument konnte nicht gelöscht werden'
              )
            }
          )
      },
      reject: () => {},
    })
  }

  public archive(event: any): void {
    this.confirmationService.confirm({
      target: event.target ?? undefined,
      message: 'Dokument wirklich archivieren?',
      accept: () => {
        this.submittedDelete = true

        this.documentService
          .archiveGlobal(this.config.data.document.id)
          .subscribe(
            () => {
              this.submittedDelete = false
              this.eventbus.emit(GlobalEvent.DocumentListReload)
              this.ref.close()
              this.toastService.success(
                'Dokument archiviert',
                'Das Dokument wurde erfolgreich archiviert'
              )
            },
            () => {
              this.submittedDelete = false
              this.toastService.error(
                'Archivierung fehlgeschlagen',
                'Das Dokument konnte nicht gelöscht werden'
              )
            }
          )
      },
      reject: () => {},
    })
  }

  public unarchive(event: any): void {
    this.confirmationService.confirm({
      target: event.target ?? undefined,
      message: 'Dokument wiederherstellen?',
      accept: () => {
        this.submittedDelete = true

        this.documentService
          .unarchiveGlobal(this.config.data.document.id)
          .subscribe(
            () => {
              this.submittedDelete = false
              this.eventbus.emit(GlobalEvent.DocumentListReload)
              this.ref.close()
              this.toastService.success(
                'Dokument wiederhergestellt',
                'Das Dokument wurde erfolgreich wiederhergestellt'
              )
            },
            () => {
              this.submittedDelete = false
              this.toastService.error(
                'Wiederherstellung fehlgeschlagen',
                'Das Dokument konnte nicht gelöscht werden'
              )
            }
          )
      },
      reject: () => {},
    })
  }
}
