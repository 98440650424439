import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { StatusCodes } from 'http-status-codes'
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import * as dayjs from 'dayjs'
import { HelperService } from '../../../services/helper.service'
import { DocumentService } from '../../../services/document.service'
import {UserCaregiverService} from "../../../services/user-caregiver.service";
import {ShowHistoriesDialogComponent} from "../show-histories-dialog/show-histories-dialog.component";
import * as customParseFormat from "dayjs/plugin/customParseFormat";
import * as utc from "dayjs/plugin/utc";
import {HospitationFeedbackDialogComponent} from "../hospitation-feedback-dialog/hospitation-feedback-dialog.component";

@Component({
  selector: 'app-hospitation-dialog',
  templateUrl: './hospitation-dialog.component.html',
})
export class HospitationDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null

  public isConfirmed = false
  public isEdit = false
  public data: any = {}
  public canceledTypes = []

  public values = {
    hospitation_from_string: '',
    hospitation_to_string: '',
    hospitation_coordination_at_string: '',
    hospitation_coordination_type: '',
  }

  public submitted = false

  constructor(
    private toastService: ToastService,
    private dialogService: DialogService,
    public userCaregiverService: UserCaregiverService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private helperService: HelperService,
    public documentService: DocumentService,
  ) {
    dayjs.locale('de')
    dayjs.extend(customParseFormat)
    dayjs.extend(utc)
  }

  ngOnInit(): void {
    this.data = this.config.data

    console.log(this.data);

    this.isEdit = this.data.current_status.status !== 'HOSPITATION_STARTED'

    this.isConfirmed = this.data.current_status.hospitation_confirmed

    this.helperService.dependencies$.subscribe((data: any) => {
      this.canceledTypes = data['canceled_types']
    })

    if (this.isEdit) {
      this.values.hospitation_from_string = this.data.current_status.hospitation_from_string
      this.values.hospitation_to_string = this.data.current_status.hospitation_to_string
      this.values.hospitation_coordination_at_string = this.data.current_status.hospitation_coordination_at_string
      this.values.hospitation_coordination_type = this.data.current_status.hospitation_coordination_type
    }
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public openFeedbackDialog(): void {
    const ref = this.dialogService.open(HospitationFeedbackDialogComponent, {
      data: {
        is_female: this.data.is_female,
        current_status: this.data.current_status,
      },
      header: 'Feedback',
      styleClass: 'dialog-container',
      dismissableMask: false,
      width: '520px',
    })

    ref.onClose.subscribe((response: any) => {
      if (response) {
        this.ref.close(true)
      }
    })
  }

  public openShowHistoriesDialog(): void {
    this.dialogService.open(ShowHistoriesDialogComponent, {
      data: {
        histories: this.data.histories
      },
      header: 'Historie',
      styleClass: 'dialog-container',
      dismissableMask: false,
      width: '80%',
    })
  }

  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    const dateFrom = dayjs(this.values.hospitation_from_string, 'DD.MM.YYYY')
    const dateTo = dayjs(this.values.hospitation_to_string, 'DD.MM.YYYY')

    if (dateFrom.isAfter(dateTo)) {
      alert('"Hospitation von" darf nicht größer als "Hospitation bis" sein')
      return
    }

    this.submitted = true

    const subscription = this.isEdit
      ? this.userCaregiverService.updateHospitation(this.data.current_status.id, this.values)
      : this.userCaregiverService.storeHospitation(this.data.id, this.values)

    subscription.subscribe(
      () => {
        this.submitted = false
        this.ref.close(true)
        this.toastService.success(
          'Hospitation gespeichert',
        )
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }
}
