import { Component, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { ToastService } from 'src/app/services/toast.service'
import { DirtyComponent } from 'src/app/guards/dirty-check.guard'
import { HttpErrorResponse } from '@angular/common/http'
import { StatusCodes } from 'http-status-codes'
import { NgForm } from '@angular/forms'
import { ConfirmationService } from 'primeng/api'
import { UserSystemFormModel } from '../../../../models/user/user-system-form.model'
import { UserSystemService } from '../../../../services/user-system.service'
import { TitleService } from 'src/app/services/title.service'
import { RolePermissionService } from '../../../../services/role-permission.service'
import { RoleModel } from '../../../../models/permission/role.model'
import { AuthService } from '../../../../services/auth.service'
import { HelperService } from '../../../../services/helper.service'

@Component({
  selector: 'app-users-form',
  templateUrl: './users-form.component.html',
  styleUrls: ['./users-form.component.scss'],
})
export class UsersFormComponent implements OnInit, DirtyComponent {
  @ViewChild('form', { static: true }) form!: NgForm

  public isDirty = false
  public isEditMode = false
  public userId: string | null = null
  public submitted = false
  public loading = false
  public formOfAddress: any = []

  public roles: RoleModel[] = []
  public hasPermission = true

  public data: UserSystemFormModel = new UserSystemFormModel()

  constructor(
    private service: UserSystemService,
    private router: Router,
    private rolePermissionService: RolePermissionService,
    private route: ActivatedRoute,
    private helperService: HelperService,
    public authService: AuthService,
    private toastService: ToastService,
    private confirmationService: ConfirmationService,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    this.hasPermission = this.authService.can(
      'Verwaltung.Systembenutzer verwalten'
    )

    if (this.hasPermission) {
      this.loadDataForEditMode()
    }

    this.titleService.setTitle(
      this.isEditMode ? 'Benutzer bearbeiten' : 'Neuer Benutzer'
    )

    this.form.valueChanges?.subscribe(() => {
      // sonst wird isDirty durch das initiale rendern der form gesetzt
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    this.helperService.dependencies$.subscribe((data: any) => {
      this.formOfAddress = data.form_of_address
    })
  }

  canDeactivate(): boolean {
    return this.isDirty
  }

  public deactivate(event: Event): void {
    this.confirmationService.confirm({
      target: event.target ?? undefined,
      message: 'Benutzer wirklich deaktivieren?',
      accept: () => {
        this.service.deactivate(this.userId).subscribe(
          () => {
            this.toastService.success(
              'Benutzer deaktiviert',
              'Der Benutzer wurde erfolgreich deaktiviert'
            )

            this.router.navigateByUrl(`/administration/users/${this.userId}`)
          },
          () => {
            this.toastService.error(
              'Deaktivieren fehlgeschlagen',
              'Der Benutzer konnte nicht deaktiviert werden'
            )
          }
        )
      },
      reject: () => {},
    })
  }

  public activate(event: Event): void {
    this.confirmationService.confirm({
      target: event.target ?? undefined,
      message: 'Benutzer wirklich aktivieren?',
      accept: () => {
        this.service.activate(this.userId).subscribe(
          () => {
            this.toastService.success(
              'Benutzer aktiviert',
              'Der Benutzer wurde erfolgreich aktiviert'
            )

            this.router.navigateByUrl(`/administration/users/${this.userId}`)
          },
          () => {
            this.toastService.error(
              'Aktivieren fehlgeschlagen',
              'Der Benutzer konnte nicht aktiviert werden'
            )
          }
        )
      },
      reject: () => {},
    })
  }

  /**
   * Erstellt bzw. updated die Daten.
   */
  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')

      return
    }

    this.submitted = true

    const subscription = this.isEditMode
      ? this.service.update(this.data)
      : this.service.store(this.data)

    subscription.subscribe(
      (data: UserSystemFormModel) => {
        this.isDirty = false

        // Ein reload der Seite, damit die Frontendabhängigkeiten neu geladen werden.
        window.location.href = '/administration/users/' + data.id
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error(
            'Bitte füllen Sie alle Pflichtfelder aus',
            'Eventuell ist Benutzername oder E-Mail bereits vergeben'
          )
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }

        this.submitted = false
      }
    )
  }

  /**
   * Lädt die Systembenutzer Daten.
   */
  private loadUserSystem(): void {
    this.service
      .loadForEditMode(this.userId)
      .subscribe((data: UserSystemFormModel) => {
        this.data = data

        // Passwort setzen, da es nicht aus dem Backend geladen wird.
        this.data.user.password = ''

        this.loading = false
      })
  }

  /**
   * Lädt die Daten falls wir im bearbeiten Modus sind.
   */
  private loadDataForEditMode(): void {
    this.loading = true

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.userId = params.get('id')
      this.isEditMode = params.has('id')

      this.rolePermissionService.roles().subscribe((roles: RoleModel[]) => {
        this.roles = roles

        if (this.isEditMode) {
          this.loadUserSystem()
        } else {
          this.loading = false
        }
      })
    })
  }
}
