<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header is-sticky">
    <div class="content-header-patients-tabs">
      <span
        (click)="changePatient('first_patient')"
        [class.active]="selectedPatient === 'first_patient'"
      >
        <i
          pTooltip="Terminversand gesperrt"
          class="pi pi-lock color-alert"
          *ngIf="patients.first_patient.appointment_lock"
        ></i>
        <i class="pi pi-user"></i>
        {{ patients.first_patient.full_name }}

        <i
          *ngIf="patients.first_patient.status"
          class="p-tag p-tag-rounded p-tag-{{ patients.first_patient.status }}"
          >{{ patients.first_patient.status_string }}</i
        >

        <i
          *ngIf="patients.first_patient.is_paused"
          class="p-tag p-tag-rounded p-tag-PAUSED"
          >Pausiert</i
        >
      </span>
      <span
        (click)="changePatient('second_patient')"
        [class.active]="selectedPatient === 'second_patient'"
        *ngIf="patients.second_patient"
      >
        <i
          pTooltip="Terminversand gesperrt"
          class="pi pi-lock color-alert"
          *ngIf="patients.second_patient.appointment_lock"
        ></i>
        <i class="pi pi-users"></i>
        {{ patients.second_patient.full_name }}

        <i
          *ngIf="patients.second_patient.status"
          class="p-tag p-tag-rounded p-tag-{{ patients.second_patient.status }}"
          >{{ patients.second_patient.status_string }}</i
        >

        <i
          *ngIf="patients.second_patient.is_paused"
          class="p-tag p-tag-rounded p-tag-PAUSED"
          >Pausiert</i
        >
      </span>
    </div>
    <div class="content-header-buttons">
      <p-checkbox
        label="nur archivierte"
        [binary]="true"
        (onChange)="loadDocuments(false)"
        [(ngModel)]="onlyArchived"
        name="only_archived"
      ></p-checkbox>

      <button
        pButton
        label="Dokument erstellen"
        (click)="openAddDocumentDialog()"
        type="button"
        class="p-button-outlined"
        icon="pi pi-plus"
        iconPos="left"
      ></button>
    </div>
  </header>
</div>

<div class="content" style="margin: 0" *ngIf="!loading">
  <div
    class="content-body"
    *ngIf="!patients[selectedPatient]?.initial_documents_generated"
  >
    <div class="initial-documents-container">
      <button
        pButton
        *ngIf="!showInitialDocumentDownload[selectedPatient]"
        (click)="createInitialDocuments()"
        [label]="
          initialDocumentsSubmitted[selectedPatient]
            ? 'Dokumente werden erstellt...'
            : 'Initiale Dokumente erstellen'
        "
        type="button"
        [disabled]="initialDocumentsSubmitted[selectedPatient] || !receiver"
        [icon]="
          initialDocumentsSubmitted[selectedPatient]
            ? 'pi pi-spin pi-spinner'
            : 'pi pi-file'
        "
        class="p-button p-button-info"
      ></button>

      <div
        class="content-no-message p-flex-column with-padding"
        *ngIf="!showInitialDocumentDownload[selectedPatient]"
      >
        <label for="receiver"
          >Wählen Sie einen Empfänger für Willkommensschreiben und
          DLV-Anschreiben:</label
        >
        <p-dropdown
          inputId="receiver"
          name="receiver"
          [options]="receiverOptions"
          [(ngModel)]="receiver"
          [required]="true"
        ></p-dropdown>
      </div>

      <!--      <a-->
      <!--        [href]="getInitialDocumentsDownloadLink()"-->
      <!--        target="_blank"-->
      <!--        (click)="downloadInitialDocuments()"-->
      <!--      >-->
      <!--        <button-->
      <!--          pButton-->
      <!--          *ngIf="showInitialDocumentDownload[selectedPatient]"-->
      <!--          label="Erstellte Dokumente herunterladen"-->
      <!--          type="button"-->
      <!--          icon="pi pi-download"-->
      <!--          class="p-button"-->
      <!--        ></button>-->
      <!--      </a>-->
    </div>
  </div>

  <ng-container *ngIf="patients[selectedPatient]?.initial_documents_generated">
    <ng-container *ngFor="let type of documentTypes[selectedPatient]">
      <div class="detail-information-header">
        {{ type.name }}
        <!--    <i class="pi pi-plus" pTooltip="Hinzufügen"></i>-->
      </div>
      <div class="content-body without-padding auto-height">
        <span
          class="content-no-message with-padding"
          *ngIf="type.documents.length === 0"
        >
          Keine Dokumente hinterlegt
        </span>

        <p-table
          [value]="type.documents"
          [rowHover]="true"
          *ngIf="type.documents.length > 0"
          [paginator]="type.documents.length > 10"
          [rows]="10"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>Name</th>
              <th style="width: 200px">Erstellt am</th>
              <th style="width: 200px">Erstellt von</th>
              <th style="width: 100px"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-document>
            <tr class="clickable">
              <td (click)="openDownloadTab(document)">{{ document.name }}</td>
              <td (click)="openDownloadTab(document)">
                {{ document.created_at | date: "dd.MM.y, H:mm" }} Uhr
              </td>
              <td (click)="openDownloadTab(document)">
                {{ document.custom_properties.created_from_name || "-" }}
              </td>
              <td class="table-action" style="width: 100px">
                <i
                  (click)="openAddDocumentDialog(document, type.name)"
                  class="pi pi-pencil with-margin-right"
                  tooltipPosition="left"
                  pTooltip="Bearbeiten"
                ></i>

                <a
                  [href]="patientService.getDocumentDownloadLink(document.uuid)"
                  target="_blank"
                >
                  <i
                    pTooltip="Herunterladen"
                    class="pi pi-download"
                    tooltipPosition="left"
                  ></i>
                </a>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </ng-container>
  </ng-container>
</div>
