<div class="content-actions">
  <h2>
    <i class="tio">time</i>
    Planung
  </h2>
</div>

<header class="content-header-tabs">
  <a
    routerLink="/plannings/change-required"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    Umplanungen
  </a>
  <a
    routerLink="/plannings/vacation"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    Urlaubsplanung
  </a>
</header>

<router-outlet></router-outlet>
