<!-- INCOMING -->
<p-toast key="phone-incoming" styleClass="phone-call phone-incoming">
  <ng-template let-message pTemplate="message">
    <div class="phone-call-header">
      <span>Ankommender Anruf</span>
    </div>
    <div class="phone-call-body">
      <span
        *ngIf="message.data?.found_callers.length > 0"
        class="phone-call-detail-small"
        >Nummer: {{ message.data.number }}</span
      >
      <span
        *ngIf="message.data?.found_callers.length == 0"
        class="phone-call-detail"
        >{{ message.data.number }}</span
      >
      <span
        *ngFor="let caller of message.data?.found_callers"
        class="phone-call-detail"
        >{{ caller.phone_caller_name }}
      </span>
    </div>
  </ng-template>
</p-toast>

<!-- CALLING -->
<p-toast key="phone-calling" styleClass="phone-call phone-calling">
  <ng-template let-message pTemplate="message">
    <div class="phone-call-header">
      <span>Ausgehender Anruf</span>
    </div>
    <div class="phone-call-body">
      <span class="phone-call-detail">{{ message.data.number }}</span>
    </div>
  </ng-template>
</p-toast>

<!-- CALLING FINISHED WITHOUT ACTIVE -->
<p-toast
  key="phone-calling-finished-without-active"
  styleClass="phone-call phone-calling-finished-without-active"
>
  <ng-template let-message pTemplate="message">
    <div class="phone-call-loader" *ngIf="message.data?.loading">
      <i class="loading-logo"></i>
    </div>
    <div class="phone-call-header">
      <span>Ausgehender Anruf beendet</span>
    </div>
    <div class="phone-call-body">
      <span class="phone-call-detail">{{ message.data.number }}</span>
      <p-dropdown
        *ngIf="message.data?.found_callers.length > 0"
        [(ngModel)]="message.data.selected_caller"
        [options]="message.data.found_callers"
        optionLabel="phone_caller_name"
      ></p-dropdown>
      <textarea
        *ngIf="message.data"
        placeholder="Kommentar..."
        [(ngModel)]="message.data.comment"
        pInputTextarea
      ></textarea>
    </div>
    <div class="phone-call-footer">
      <div class="phone-call-footer-checkitems">
        <div class="p-field-checkbox">
          <p-checkbox
            styleClass="phone-checkbox-container"
            label="Nicht erreicht"
            [(ngModel)]="message.data.not_reached"
            [binary]="true"
            name="not-reached"
          ></p-checkbox>
        </div>
      </div>
    </div>
    <div class="phone-call-footer">
      <i
        (click)="deleteCallingFinishedWithoutActive(message.data.id)"
        class="pi pi-trash"
        [class.pi-trash]="!submittedDelete"
        [class.pi-spin]="submittedDelete"
        [class.pi-spinner]="submittedDelete"
      ></i>
      <button
        (click)="save(message.data)"
        pButton
        label="Speichern"
        type="button"
        icon="pi pi-check"
        iconPos="left"
      ></button>
    </div>
  </ng-template>
</p-toast>

<!-- ACTIVE -->
<p-toast key="phone-active" styleClass="phone-call phone-active">
  <ng-template let-message pTemplate="message">
    <div class="phone-call-header">
      <span>Aktiver Anruf</span>
    </div>
    <div class="phone-call-body">
      <span
        *ngIf="message.data?.found_callers.length > 0"
        class="phone-call-detail-small"
        >Nummer: {{ message.data.number }}</span
      >
      <span
        *ngIf="message.data?.found_callers.length == 0"
        class="phone-call-detail"
        >{{ message.data.number }}</span
      >
      <p-dropdown
        *ngIf="message.data?.found_callers.length > 0"
        [(ngModel)]="message.data.selected_caller"
        [options]="message.data.found_callers"
        optionLabel="phone_caller_name"
      ></p-dropdown>
      <textarea
        *ngIf="message.data"
        placeholder="Thema..."
        [(ngModel)]="message.data.comment"
        pInputTextarea
      ></textarea>
    </div>
    <div class="phone-call-footer">
      <i></i>
      <button
        (click)="finishFromActive(message.data)"
        pButton
        label="Beenden"
        type="button"
        icon="pi pi-check"
        iconPos="left"
      ></button>
    </div>
  </ng-template>
</p-toast>

<!-- FINISH -->
<p-toast key="phone-finish" styleClass="phone-call phone-finish">
  <ng-template let-message pTemplate="message">
    <div class="phone-call-loader" *ngIf="message.data?.loading">
      <i class="loading-logo"></i>
    </div>
    <div class="phone-call-header">
      <span
        >Beendeter Anruf
        <i
          *ngIf="
            message.data?.number && hiddenMessages.includes(message.data?.id)
          "
          >({{ message.data.number }})</i
        ></span
      >
      <i
        (click)="toggleMessageHidden(message.data?.id)"
        class="pi"
        [class.pi-plus]="hiddenMessages.includes(message.data?.id)"
        [class.pi-minus]="!hiddenMessages.includes(message.data?.id)"
      ></i>
    </div>
    <div
      class="phone-call-body"
      *ngIf="!hiddenMessages.includes(message.data?.id)"
    >
      <span
        *ngIf="message.data?.found_callers.length > 0"
        class="phone-call-detail-small"
        >Nummer: {{ message.data.number }}</span
      >
      <span
        *ngIf="message.data?.found_callers.length == 0"
        class="phone-call-detail"
        >{{ message.data.number }}</span
      >

      <p-dropdown
        *ngIf="message.data?.found_callers.length > 0"
        [(ngModel)]="message.data.selected_caller"
        [options]="message.data.found_callers"
        optionLabel="phone_caller_name"
      ></p-dropdown>
      <textarea
        *ngIf="message.data"
        placeholder="Thema..."
        [(ngModel)]="message.data.comment"
        pInputTextarea
      ></textarea>

      <div
        class="p-field"
        style="margin: 10px 0 0 0"
        *ngIf="message.data?.selected_caller?.phone_caller_type === 'customer'"
      >
        <label for="appointment_transfer">Terminübergabe?</label>
        <div class="p-formgroup-inline">
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Nein"
              name="appointment_transfer"
              value="NO"
              [(ngModel)]="message.data.appointment_transfer"
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Für aktuellen Monat"
              name="appointment_transfer"
              [(ngModel)]="message.data.appointment_transfer"
              value="CURRENT_MONTH"
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Für nächsten Monat"
              name="appointment_transfer"
              [(ngModel)]="message.data.appointment_transfer"
              value="NEXT_MONTH"
            ></p-radioButton>
          </div>
        </div>
      </div>
    </div>
    <div
      class="phone-call-footer"
      *ngIf="!hiddenMessages.includes(message.data?.id)"
    >
      <div class="phone-call-footer-checkitems">
        <div class="p-field-checkbox">
          <p-checkbox
            *ngIf="message.data"
            styleClass="phone-checkbox-container"
            label="Wichtiges Ereignis"
            [binary]="true"
            [(ngModel)]="message.data.history.important"
            name="important"
          ></p-checkbox>
          <p-checkbox
            *ngIf="message.data"
            styleClass="phone-checkbox-container"
            label="Weiterleitung"
            [binary]="true"
            [style]="{ margin: '0 0 0 20px' }"
            [(ngModel)]="message.data.is_redirect"
            name="is_redirect"
          ></p-checkbox>
          <p-checkbox
            *ngIf="message.data"
            styleClass="phone-checkbox-container"
            label="Nicht erreicht"
            [binary]="true"
            [title]="
              message.data.appointment_transfer !== 'NO'
                ? 'Terminübergabe wurde ausgewählt'
                : ''
            "
            [disabled]="message.data.appointment_transfer !== 'NO'"
            [(ngModel)]="message.data.not_reached"
            [style]="{ margin: '0 0 0 20px' }"
            name="not-reached"
          ></p-checkbox>
        </div>

        <div class="p-field-checkbox" style="margin: 5px 0 0 0">
          <p-checkbox
            *ngIf="message.data"
            styleClass="phone-checkbox-container"
            label="Neukunden Call"
            [binary]="true"
            [(ngModel)]="message.data.is_consulting"
            name="is_consulting"
          ></p-checkbox>

          <p-checkbox
            styleClass="phone-checkbox-container"
            *ngIf="message.data && message.data.is_consulting"
            label="Starkes Interesse"
            [style]="{ margin: '0 0 0 20px' }"
            [binary]="true"
            [(ngModel)]="message.data.strong_interest"
            name="strong_interest"
          ></p-checkbox>
        </div>

        <div
          class="p-field"
          style="margin: 20px 0 0 0"
          *ngIf="message.data && message.data.is_consulting"
        >
          <label for="attention_from">Aufmerksam durch</label>
          <p-dropdown
            inputId="attention_from"
            name="attention_from"
            placeholder="Bitte auswählen"
            [options]="attentionFrom"
            [(ngModel)]="message.data.attention_from_id"
            optionLabel="name"
            optionValue="id"
            [style]="{ width: '200px' }"
          ></p-dropdown>
        </div>
      </div>
    </div>
    <div
      class="phone-call-footer"
      *ngIf="!hiddenMessages.includes(message.data?.id)"
    >
      <i
        *ngIf="
          !message.data.found_callers.length ||
          message.data.selected_caller.phone_caller_type != 'customer'
        "
      ></i>

      <button
        *ngIf="
          message.data.found_callers.length &&
          message.data.selected_caller.phone_caller_type == 'customer'
        "
        pButton
        label="Termine absagen"
        (click)="openAddCancelledDateDialog(message.data)"
        type="button"
        icon="pi pi-times"
        iconPos="left"
        class="p-button-outlined"
      ></button>

      <!--      <button-->
      <!--        *ngIf="message.data.found_callers.length"-->
      <!--        pButton-->
      <!--        label="Todo"-->
      <!--        (click)="openTodoDialog(message.data)"-->
      <!--        type="button"-->
      <!--        icon="pi pi-check"-->
      <!--        iconPos="left"-->
      <!--        class="p-button-outlined"-->
      <!--      ></button>-->

      <button
        (click)="save(message.data)"
        pButton
        label="Speichern"
        type="button"
        icon="pi pi-check"
        iconPos="left"
      ></button>
    </div>
  </ng-template>
</p-toast>
