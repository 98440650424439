<div class="content-actions">
  <h2>
    <i class="tio">group_equal</i>
    Stammkunden
  </h2>
</div>

<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <span>Alle Stammkunden</span>

    <div
      class="table-search-container"
      [class.searchfield-active]="globalFilterValue != ''"
    >
      <i
        class="pi pi-filter"
        [class.color-main1]="filterValues.selectedFilterType !== 'NO_FILTER'"
        (click)="op.toggle($event)"
      ></i>

      <p-overlayPanel [dismissable]="true" #op>
        <ng-template pTemplate>
          <div class="p-field">
            <p-dropdown
              (onChange)="customFilter()"
              inputId="customer_service"
              name="customer_service"
              [options]="filterValues.filterTypes"
              [(ngModel)]="filterValues.selectedFilterType"
            ></p-dropdown>
          </div>
          <div
            class="p-field"
            *ngIf="filterValues.selectedFilterType !== 'NO_FILTER'"
          >
            <p-multiSelect
              (onChange)="customFilter()"
              selectedItemsLabel="{0} Typen ausgewählt"
              inputId="current-month-types"
              name="current-month-types"
              [options]="filterValues.types"
              optionLabel="name"
              optionValue="value"
              [filter]="false"
              [showHeader]="false"
              [(ngModel)]="filterValues.selectedTypes"
            >
              <ng-template let-value pTemplate="selectedItems">
                Filtermöglichkeiten
              </ng-template>

              <ng-template let-type pTemplate="item">
                <i
                  class="light-symbol {{ type.class }}"
                  style="margin: 0 10px 0 0"
                ></i>
                {{ type.name }}
              </ng-template>
            </p-multiSelect>
          </div>
        </ng-template>
      </p-overlayPanel>

      <div class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input
          pInputText
          (input)="filterTableGlobal($event)"
          placeholder="Suche..."
          [(ngModel)]="globalFilterValue"
        />
      </div>
    </div>
  </header>
</div>

<div class="content" style="margin-bottom: 0" *ngIf="!loading">
  <div class="content-body" *ngIf="patients.length == 0">
    <span class="content-no-message">Keine Stammkunden vorhanden</span>
  </div>

  <div
    class="content-body content-body-full-height without-padding"
    *ngIf="patients.length > 0"
  >
    <p-overlayPanel
      styleClass="with-border"
      #opBudgets
      [showCloseIcon]="true"
      [style]="{
        maxWidth: '640px',
        width: 'max-content',
        maxHeight: '420px',
        overflowY: 'auto'
      }"
    >
      <ng-template pTemplate>
        <div [innerHTML]="currentBudgetTable"></div>
      </ng-template>
    </p-overlayPanel>

    <p-table
      #dt
      [value]="patients"
      [globalFilterFields]="[
        'id',
        'first_name',
        'last_name',
        'full_name',
        'insured_via',
        'city'
      ]"
      scrollHeight="100%"
      [scrollable]="true"
      [(first)]="firstPage"
      [rowHover]="true"
      [paginator]="true"
      [rows]="50"
      stateStorage="local"
      stateKey="state-patients-regular-2"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{totalRecords} Patienten"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th style="max-width: 100px" pSortableColumn="id">
            ID <p-sortIcon field="id"></p-sortIcon>
          </th>
          <th style="min-width: 200px" pSortableColumn="last_name">
            Name <p-sortIcon field="last_name"></p-sortIcon>
          </th>
          <th style="min-width: 200px" pSortableColumn="city">
            Wohnort <p-sortIcon field="city"></p-sortIcon>
          </th>
          <th
            pTooltip="Terminsperre"
            style="max-width: 60px"
            pSortableColumn="appointment_lock"
          >
            <i class="pi pi-lock"></i>
            <p-sortIcon field="appointment_lock"></p-sortIcon>
          </th>
          <th
            style="max-width: 100px"
            pSortableColumn="budget_for_current_month"
          >
            Aktueller
            <p-sortIcon field="budget_for_current_month"></p-sortIcon>
          </th>
          <th style="max-width: 100px" pSortableColumn="budget_for_next_month">
            Nächster
            <p-sortIcon field="budget_for_next_month"></p-sortIcon>
          </th>
          <th style="min-width: 200px" pSortableColumn="insured_via">
            Art <p-sortIcon field="insured_via"></p-sortIcon>
          </th>
          <th pSortableColumn="customer.start_of_operation_date">
            Leistungsbeginn
            <p-sortIcon field="customer.start_of_operation_date"></p-sortIcon>
          </th>
          <th style="max-width: 100px" pSortableColumn="first_sepa.id">
            SEPA <p-sortIcon field="first_sepa.id"></p-sortIcon>
          </th>
          <th
            style="max-width: 60px"
            pTooltip="Kunde bei Pflegeherzen"
            tooltipPosition="left"
            pSortableColumn="is_pflegeherzen_customer"
          >
            PH <p-sortIcon field="is_pflegeherzen_customer"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-patient>
        <tr
          [class.background-hightlight-main2]="selectedPatientId === patient.id"
        >
          <td style="max-width: 100px">
            {{ patient.id }}
          </td>
          <td style="min-width: 200px">
            <a class="color-main1" routerLink="/patients/{{ patient.id }}">
              {{ patient.last_name }}, {{ patient.first_name }}
            </a>
          </td>
          <td style="min-width: 200px">
            <div class="text-center-container">
              <i
                style="font-size: 14px"
                class="pi pi-info-circle color-gray"
                [pTooltip]="patient.full_address"
              ></i>
              {{ patient.city }}
            </div>
          </td>

          <td style="max-width: 60px">
            <i
              *ngIf="patient.appointment_lock"
              pTooltip="Terminsperre"
              class="pi pi-lock color-alert"
            ></i>
          </td>

          <td style="max-width: 100px">
            <span style="display: flex">
              <i
                pTooltip="Pause von {{
                  patient.pause_in_current_month.pause_from_string
                }} bis {{ patient.pause_in_current_month.pause_to_string }}"
                class="light-symbol light-symbol-dark"
                *ngIf="patient.pause_in_current_month"
              ></i>
              <i
                pTooltip="Pause ist bald zu Ende"
                class="light-symbol light-symbol-blue"
                *ngIf="patient.pause_that_will_end"
              ></i>
              <i
                *ngIf="patient.budget_for_current_month === 3"
                class="light-symbol light-symbol-red"
                pTooltip="Keine Persplan Planung"
              ></i>
              <i
                (click)="
                  openBudgetOverlay(
                    $event,
                    'SOLL vorhanden <br><br>' +
                      persplanData['current' + patient.id],
                    patient.id
                  )
                "
                *ngIf="patient.budget_for_current_month === 2"
                class="light-symbol light-symbol-orange p-tag-clickable"
                pTooltip="Planung ansehen"
                (mouseenter)="getPersplanBudget(patient.id, 'current')"
              ></i>
              <i
                (click)="
                  openBudgetOverlay(
                    $event,
                    'Termine übergeben <br><br>' +
                      persplanData['current' + patient.id],
                    patient.id
                  )
                "
                *ngIf="patient.budget_for_current_month === 1"
                class="light-symbol light-symbol-green p-tag-clickable"
                pTooltip="Termine ansehen"
                (mouseenter)="getPersplanBudget(patient.id, 'current')"
              ></i>
            </span>
          </td>

          <td style="max-width: 100px">
            <span style="display: flex">
              <i
                pTooltip="Pause von {{
                  patient.pause_in_next_month.pause_from_string
                }} bis {{ patient.pause_in_next_month.pause_to_string }}"
                class="light-symbol light-symbol-dark"
                *ngIf="patient.pause_in_next_month"
              ></i>
              <i
                *ngIf="patient.budget_for_next_month === 3"
                class="light-symbol light-symbol-red"
                pTooltip="Keine Persplan Planung"
              ></i>
              <i
                (click)="
                  openBudgetOverlay(
                    $event,
                    'SOLL vorhanden <br><br>' +
                      persplanData['next' + patient.id],
                    patient.id
                  )
                "
                *ngIf="patient.budget_for_next_month === 2"
                class="light-symbol light-symbol-orange p-tag-clickable"
                pTooltip="Planung ansehen"
                (mouseenter)="getPersplanBudget(patient.id, 'next')"
              ></i>
              <i
                (click)="
                  openBudgetOverlay(
                    $event,
                    'Termine übergeben <br><br>' +
                      persplanData['next' + patient.id],
                    patient.id
                  )
                "
                *ngIf="patient.budget_for_next_month === 1"
                class="light-symbol light-symbol-green p-tag-clickable"
                pTooltip="Termine ansehen"
                (mouseenter)="getPersplanBudget(patient.id, 'next')"
              ></i>
            </span>
          </td>

          <td style="min-width: 200px">
            {{ patient.insured_via_string }}
          </td>

          <td>
            {{ patient.customer.start_of_operation_date_string }}
          </td>

          <td style="max-width: 100px">
            <a
              *ngIf="patient.first_sepa"
              target="_blank"
              [href]="
                patientService.getDocumentDownloadLink(patient.first_sepa.uuid)
              "
              >SEPA
              <i
                class="pi pi-arrow-up-right color-gray"
                style="font-size: 12px"
              ></i
            ></a>
          </td>

          <td style="max-width: 60px">
            <i
              class="pflegeherzen-logo-icon"
              *ngIf="patient.is_pflegeherzen_customer"
              pTooltip="Kunde bei Pflegeherzen"
              tooltipPosition="left"
            ></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
