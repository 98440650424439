import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { CarService } from '../../../../services/car.service'
import { CarListModel } from '../../../../models/car/car-list.model'

@Component({
  selector: 'app-cars-list',
  templateUrl: './cars-list-active.component.html',
})
export class CarsListActiveComponent implements OnInit {
  @ViewChild('dt') table: ElementRef | any = null

  public globalFilterValue = ''
  public loading = false
  public firstPage = 0

  public cars: CarListModel[] = []

  constructor(private carService: CarService) {}

  ngOnInit(): void {
    this.loadCars()
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  private loadCars(): void {
    this.loading = true

    this.carService.list().subscribe((data: CarListModel[]) => {
      this.cars = data

      this.loading = false

      setTimeout(() => {
        if (this.table) {
          const totalItems = this.table._totalRecords

          this.globalFilterValue = this.table.filters.global?.value ?? ''

          if (this.table._first > totalItems || this.table._rows > totalItems) {
            this.firstPage = 0
          }
        }
      })
    })
  }
}
