import { Component, OnInit } from '@angular/core'
import interact from 'interactjs'
import { SearchService } from '../../services/search.service'
import { Router } from '@angular/router'
import { SearchResultModel } from '../../models/search/search-result.model'
import { TodoFormDialogComponent } from '../dialogs/todo-form-dialog/todo-form-dialog.component'
import { DialogService } from 'primeng/dynamicdialog'
import { DocumentService } from '../../services/document.service'
import { SendLetterDialogComponent } from '../dialogs/send-letter-dialog/send-letter-dialog.component'
import { HelperService } from '../../services/helper.service'
import { TodoModel } from '../../models/todo/todo.model'
import { TodoService } from '../../services/todo.service'
import { ShowTodoDialogComponent } from '../dialogs/show-todo-dialog/show-todo-dialog.component'
import { TodoHeaderModel } from '../../models/todo/todo-header.model'
import { NotificationModel } from '../../models/notification/notification.model'
import { NotificationService } from '../../services/notification.service'
import { ToastService } from '../../services/toast.service'
import { AuthService } from '../../services/auth.service'
import { AuthModel } from '../../models/user/auth.model'
import { MenuItem } from 'primeng/api'
import { SendSystemMessageDialogComponent } from '../dialogs/send-system-message-dialog/send-system-message-dialog.component'
import { UserModel } from '../../models/user/user.model'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public query = ''
  public isSuggestionsOpen = false
  public suggestionResults: any = []
  public todos: TodoModel[] = []
  public notifications: NotificationModel[] = []
  public todosAreOpen = false
  public todosCount = 0
  public notificationsCount = 0
  public results: SearchResultModel[] = []
  public navHidden = false

  public inProgress = false

  public userMenuItems: MenuItem[] = [
    { label: 'Einstellungen', routerLink: 'settings' },
    { label: 'Ausloggen', command: () => this.logout() },
  ]

  public user: AuthModel | null = new AuthModel()
  public impersonatedFrom: AuthModel | null = null

  constructor(
    private searchService: SearchService,
    private documentService: DocumentService,
    private router: Router,
    private todoService: TodoService,
    private authService: AuthService,
    private notificationService: NotificationService,
    private helperService: HelperService,
    private dialogService: DialogService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.helperService.dependencies$.subscribe((data: any) => {
      if (data.message) {
        this.showFrontendMessage(data.message)
      }
    })

    this.addInteractEvent(
      '.phone-calling-finished-without-active',
      '.phone-call-header'
    )
    this.addInteractEvent('.phone-incoming', '.phone-call-header')
    this.addInteractEvent('.phone-active', '.phone-call-header')
    this.addInteractEvent('.phone-finish', '.phone-call-header')
    this.addInteractEvent('.notice-wrap', '.notice-wrap-drag-handle')

    this.user = this.authService.getUser()
    this.impersonatedFrom = this.authService.getImpersonatedFrom()

    // Dadurch aktualisieren wir die Anzeige im Header.
    // Für sowas benötigen wir keine Websocket Events.
    if (this.user?.is_user_system) {
      this.loadTodos()

      setInterval(() => {
        this.loadTodos()
      }, 30000)
    }

    this.listenForNotifications()
  }

  private logout(): void {
    this.inProgress = true

    this.authService.logout().subscribe(() => {
      this.authService.removeImpersonation()

      window.location.href = '/login'
    })
  }

  private addInteractEvent(className: string, allowFrom: string): void {
    const position = { x: 0, y: 0 }

    interact(className).draggable({
      allowFrom,
      modifiers: [
        interact.modifiers.restrictRect({
          restriction: 'body',
          endOnly: true,
        }),
      ],
      listeners: {
        // tslint:disable-next-line:typedef
        move(event) {
          position.x += event.dx
          position.y += event.dy

          event.target.style.transform = `translate(${position.x}px, ${position.y}px)`
        },
      },
    })
  }

  public search(event: any): void {
    if (event.query) {
      setTimeout(() => {
        this.isSuggestionsOpen = false
      })
    }

    this.searchService
      .search(event.query)
      .subscribe((results: SearchResultModel[]) => {
        this.results = results
      })
  }

  public readNotification(id: number): void {
    this.notificationService.read(id).subscribe(() => {
      this.loadTodos()
    })
  }

  public readAllNotifications(): void {
    const ids = this.notifications.map((n: NotificationModel) => n.id)

    this.notificationService.readAll(ids).subscribe(() => {
      this.loadTodos()
    })
  }

  public toggleMobileNav(): void {
    if (document.body.classList.contains('mobile-nav-visible')) {
      document.body.classList.remove('mobile-nav-visible')
    } else {
      document.body.classList.add('mobile-nav-visible')
    }
  }

  public todosOpened(): void {
    this.todosAreOpen = true

    this.loadTodos()
  }

  public loadTodos(): void {
    this.todoService
      .loadOpenForHeader()
      .subscribe((result: TodoHeaderModel) => {
        this.notifications = result.notifications
        this.notificationsCount = result.notifications.length
        this.todos = result.todos
        this.todosCount = result.todos.length
      })
  }

  public todosClosed(): void {
    this.todosAreOpen = false
  }

  public searchSelected(result: SearchResultModel): any {
    this.isSuggestionsOpen = false

    if (result.type === 'document') {
      window.open(
        this.documentService.getDocumentDownloadLink(result.uuid || '')
      )
    } else {
      const url = this.getUrlByType(result.type)

      this.router.navigateByUrl(`${url}/${result.id}`)
    }

    const lastClickedSearches = JSON.parse(
      localStorage.getItem('last-clicked-searches') || '[]'
    )

    const alreadyInHistory = lastClickedSearches.findIndex((item: any) => {
      return item.type === result.type && result.id === item.id
    })

    if (alreadyInHistory > -1) {
      lastClickedSearches.splice(alreadyInHistory, 1)
    }

    lastClickedSearches.unshift(result)

    if (lastClickedSearches.length > 5) {
      lastClickedSearches.pop()
    }

    localStorage.setItem(
      'last-clicked-searches',
      JSON.stringify(lastClickedSearches)
    )

    setTimeout(() => {
      this.query = ''

      this.isSuggestionsOpen = false
    }, 200)
  }

  public showSuggestions(): void {
    if (!this.query) {
      setTimeout(() => {
        const results = JSON.parse(
          localStorage.getItem('last-clicked-searches') || '[]'
        )

        if (results.length) {
          this.suggestionResults = results
          this.isSuggestionsOpen = true
        }
      }, 0)
    }
  }

  public hideSuggestions(): void {
    setTimeout(() => {
      this.isSuggestionsOpen = false
    }, 100)
  }

  public openTodoFormDialog(todo: TodoModel | null = null): void {
    this.dialogService.open(TodoFormDialogComponent, {
      header: todo ? 'Todo bearbeiten' : 'Neues Todo',
      width: '520px',
      styleClass: 'dialog-container',
      data: {
        isEdit: !!todo,
        todo,
        user_type_name: todo?.caregiver?.full_name || todo?.patient?.full_name,
      },
    })
  }

  public openShowTodoDialog(
    todo: TodoModel | null = null,
    todoId: number | null = null
  ): void {
    const ref = this.dialogService.open(ShowTodoDialogComponent, {
      header: 'Todo ansehen',
      width: '820px',
      styleClass: 'dialog-container',
      data: {
        isEdit: true,
        todo: todo || new TodoModel(),
        todo_id: todoId || todo?.id,
        user_type_name: todo?.caregiver?.full_name || todo?.patient?.full_name,
      },
    })

    // Dadurch aktualisieren wir die Anzeige im Header, sobald der Dialog geschlossen wird.
    ref.onClose.subscribe(() => {
      this.loadTodos()
    })
  }

  public openSendLetterDialog(): void {
    this.dialogService.open(SendLetterDialogComponent, {
      header: 'Neuer Brief',
      width: '680px',
      styleClass: 'dialog-container',
      data: {},
    })
  }

  public openSendSystemMessageDialog(): void {
    this.dialogService.open(SendSystemMessageDialogComponent, {
      header: 'Mitteilungen',
      width: '620px',
      styleClass: 'dialog-container',
      data: {},
    })
  }

  private getUrlByType(type: string): string {
    switch (type) {
      case 'caregiver':
        return '/caregivers'
      case 'car':
        return '/cars'
      case 'multiplier':
        return '/multipliers'
      case 'patient':
      case 'contact_person':
        return '/patients'
    }

    return ''
  }

  private showFrontendMessage(message: any): void {
    setTimeout(() => {
      this.toastService.stickySuccess('Systemnachricht', message.message)
    }, 1000)
  }

  /**
   * Lauscht über Websocket Events auf Browser-
   * notifications und zeigt diese dem Benutzer an.
   *
   * @private
   */
  private listenForNotifications(): void {
    // @ts-ignore
    const channel = window.Echo.private(`notification.${this.user?.id}`)

    channel.listen(
      'NotificationCreated',
      ({ notification }: { notification: NotificationModel }) => {
        // @ts-ignore
        window.webNotification.showNotification('Neue Mitteilung', {
          body: notification.description,
          // Dadurch wird verhindert, dass die Browser-notification mehrfach
          // angezeigt wird, wenn mehrere Browsertabs offen sind.
          tag: notification.notifiable_type,
          icon: '/assets/img/loading-logo.svg',
          onClick: () => {
            if (notification.notifiable_type === 'App\\Models\\Todo') {
              this.openShowTodoDialog(null, notification.notifiable_id)
            }
          },
          autoClose: 6000,
        })
      }
    )
  }
}
