import { Component, OnInit, ViewChild } from '@angular/core'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NoticeService } from '../../../services/notice.service'
import { HelperService } from '../../../services/helper.service'
import { CustomerService } from '../../../services/customer.service'
import { QmModel } from '../../../models/customer-patient/qm.model'
import * as dayjs from 'dayjs'
import { SearchResultModel } from '../../../models/search/search-result.model'
import { SearchService } from '../../../services/search.service'
import { NgForm } from '@angular/forms'
import { PatientService } from '../../../services/patient.service'
import { CaregiverService } from '../../../services/caregivers.service'
import { ConfirmationService } from 'primeng/api'
import { UserSystemService } from '../../../services/user-system.service'
import { ConsultingService } from '../../../services/consulting.service'
import { PatientModel } from '../../../models/customer-patient/patient.model'

@Component({
  selector: 'app-consulting-dialog',
  templateUrl: './consulting-dialog.component.html',
})
export class ConsultingDialogComponent implements OnInit {
  @ViewChild('form', { static: true }) form!: NgForm

  public patients: SearchResultModel[] = []

  public withoutPatient = false
  public homeVisitFrom: any[] = []
  public patient: PatientModel = new PatientModel()
  public isPreview = false
  public receiverOptions: any[] = []
  private receiversData: any
  private consulting: any
  public ccOptions: any[] = []
  public clickedOnPreview = false
  public appointmentsForUser: any[] = []

  values = {
    comment: '',
    home_visit_from: '',
    home_visit_time: '',
    home_visit_date_string: '',
    receiver: null,
    cc: [],
    send_email: false,
    sent_type: '',
  }

  public recommendationIssue: any = {
    has_issue: false,
    name: '',
  }

  public hasMedia = false

  submitted = false
  submittedDelete = false

  constructor(
    private ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private caregiverService: CaregiverService,
    private userSystemService: UserSystemService,
    private helperService: HelperService,
    public consultingService: ConsultingService,
    private searchService: SearchService,
    private noticeService: NoticeService,
    private patientService: PatientService,
    private eventbus: EventBusService,
    private confirmationService: ConfirmationService,
    private toastService: ToastService
  ) {}

  public ngOnInit(): void {
    this.patient = this.config.data.patient
    this.isPreview = this.config.data.isPreview
    this.withoutPatient = this.config.data.withoutPatient

    // Prüfen, ob Daten für "Empfohlen durch" übergeben wurden.
    if (this.config.data.recommendation) {
      this.setRecommendationIssue(
        this.config.data.recommendation_type,
        this.config.data.recommendation_id,
        this.config.data.recommendation.name
      )
    }

    // Bei der Vorschau brauchen wir keine Empfänger.
    if (!this.isPreview && !this.withoutPatient) {
      this.loadReceivers()
    }

    if (!this.config.data.isNew) {
      this.consulting = this.config.data.consulting

      this.hasMedia = this.config.data.consulting.media[0]

      this.values.comment = this.consulting.comment
      this.values.sent_type = this.consulting.sent_type
      this.values.home_visit_from = this.consulting.for_user
      this.values.home_visit_date_string = dayjs(this.consulting.date).format(
        'DD.MM.YYYY'
      )
      this.values.home_visit_time = dayjs(this.consulting.date).format('HH:mm')
    }

    if (this.withoutPatient) {
    }

    if (this.isPreview && this.config.data.previewData) {
      this.values.comment = this.config.data.previewData.comment
      this.values.home_visit_from = this.config.data.previewData.home_visit_from
      this.values.home_visit_date_string = this.config.data.previewData.home_visit_date_string
      this.values.home_visit_time = this.config.data.previewData.home_visit_time
    }

    this.helperService.dependencies$.subscribe((data: any) => {
      for (const user of data.consulting_users) {
        this.homeVisitFrom.push({
          id: user.id,
          full_name: `${user.user_system.first_name} ${user.user_system.last_name}`,
        })
      }
    })
  }

  private setRecommendationIssue(type: string, id: number, name: string): void {
    // Vorerst dürfen keine Beratungseinsätze gemacht werden, wenn diese
    // von bestimmten Multiplikatoren empfohlen wurden.
    if (type === 'App\\Models\\Multiplier') {
      const heib = 4959
      const harmonie = 4958
      const zeitNah = 4979

      if ([heib, harmonie, zeitNah].includes(id)) {
        this.recommendationIssue.has_issue = true
        this.recommendationIssue.name = name
      } else {
        this.recommendationIssue.has_issue = false
        this.recommendationIssue.name = ''
      }
    } else {
      this.recommendationIssue.has_issue = false
      this.recommendationIssue.name = ''
    }
  }

  public searchPatients(event: any): void {
    this.searchService
      .findPatients(event.query.trim())
      .subscribe((results: SearchResultModel[]) => {
        this.patients = results
      })
  }

  public patientSelected(event: any): void {
    this.patient = event

    this.loadReceivers()
    this.loadRecommendationForPatient()
  }

  private loadRecommendationForPatient(): void {
    this.patientService
      .loadRecommendation(this.patient.id)
      .subscribe((customer: any) => {
        if (customer.recommendation) {
          this.setRecommendationIssue(
            customer.recommendation_type,
            customer.recommendation_id,
            customer.recommendation.name
          )
        } else {
          this.recommendationIssue.has_issue = false
          this.recommendationIssue.name = ''
        }
      })
  }

  private loadReceivers(): void {
    this.patientService
      .getReceivers(this.patient.id)
      .subscribe((response: any) => {
        this.receiversData = response

        this.buildReceiverOptions()
        this.buildCCOptions()

        // Nachdem die Daten für den Empfänger gebaut wurden, können die Edit-Daten gesetzt werden.
        if (!this.config.data.isNew) {
          setTimeout(() => {
            this.values.receiver = this.consulting.receiver
          }, 0)
        }
      })
  }

  public loadUserAppointments(): void {
    const found = this.homeVisitFrom.find((item: any) => {
      return item.full_name === this.values.home_visit_from
    })

    if (!found) {
      return
    }

    this.userSystemService
      .loadDatesForDay(found.id, this.values.home_visit_date_string)
      .subscribe((response: string[]) => {
        this.appointmentsForUser = response
      })
  }

  public checkForTimes(event: any): void {
    const value = event.target.value

    if (value.length === 1) {
      this.values.home_visit_time = `0${value}:00`
    } else if (value.length === 2) {
      this.values.home_visit_time = `${value}:00`
    } else if (value.length === 4) {
      this.values.home_visit_time = `${value[0]}${value[1]}:${value[2]}${value[3]}`
    }
  }

  public savePreview(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    setTimeout(() => {
      this.ref.close(this.values)
    }, 200)
  }

  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    if (this.recommendationIssue.has_issue) {
      if (
        !window.confirm(
          `Der Kunde wurde von ${this.recommendationIssue.name} empfohlen. Soll trotzdem ein Beratungseinsatz erstellt werden?`
        )
      ) {
        return
      }
    }

    this.submitted = true

    const subscription = this.config.data.isNew
      ? this.consultingService.store(this.patient.id || 0, this.values)
      : this.consultingService.update(
          this.config.data.consulting.id,
          this.values
        )

    subscription.subscribe(
      () => {
        this.submitted = false
        this.ref.close()
        this.eventbus.emit(GlobalEvent.ConsultingChanged)
        this.toastService.success(
          'Beratungseinsatz gespeichert',
          'Der Termin wurde erfolgreich gespeichert'
        )
      },
      () => {
        this.toastService.error(
          'Etwas ist schiefgelaufen...',
          'Bitte wenden Sie sich an den Support'
        )

        this.submitted = false
      }
    )
  }

  public buildReceiverOptions(): void {
    this.receiverOptions = []
    this.values.receiver = null

    this.receiverOptions.push({
      value: '',
      label: 'Bitte auswählen',
      email: '',
      disabled: true,
    })

    this.receiverOptions.push({
      value: `patient_${this.receiversData.first_patient.id}_`,
      email: this.receiversData.first_patient.email || 'Keine E-Mail',
      label: `${this.receiversData.first_patient.full_name} - Patient`,
      disabled:
        this.values.sent_type === 'MAIL' &&
        !this.receiversData.first_patient.email,
    })

    if (this.receiversData.second_patient) {
      this.receiverOptions.push({
        value: `patient_${this.receiversData.second_patient.id}_`,
        email: this.receiversData.second_patient.email || 'Keine E-Mail',
        label: `${this.receiversData.second_patient.full_name} - Patient`,
        disabled:
          this.values.sent_type === 'MAIL' &&
          !this.receiversData.second_patient.email,
      })
    }

    // Der AP kann 2 verschiedene Mails haben.
    for (const contactPerson of this.receiversData.contact_persons) {
      if (contactPerson.email_private) {
        this.receiverOptions.push({
          value: `ap_${contactPerson.id}_private`,
          email: `Private E-Mail: ${contactPerson.email_private}`,
          label: `${contactPerson.full_name} - AP`,
          disabled: false,
        })

        // Bei Postversand soll der AP soll nur einmal in der Liste angezeigt
        // werden, selbst wenn er noch eine Geschäftliche E-Mail Adresse hat.
        if (this.values.sent_type === 'POST') {
          continue
        }
      }

      if (contactPerson.email_work) {
        this.receiverOptions.push({
          value: `ap_${contactPerson.id}_work`,
          email: `Geschäftliche E-Mail: ${contactPerson.email_work}`,
          label: `${contactPerson.full_name} - AP`,
          disabled: false,
        })
      }

      // Wenn gar keine E-Mail hinterlegt ist.
      if (!contactPerson.email_private && !contactPerson.email_work) {
        this.receiverOptions.push({
          value: `ap_${contactPerson.id}_`,
          email: 'Keine E-Mail',
          label: `${contactPerson.full_name} - AP`,
          disabled: this.values.sent_type === 'MAIL',
        })
      }
    }
  }

  public buildReceiverOptions_OLD(): void {
    this.receiverOptions.push({
      value: '',
      label: 'Bitte auswählen',
      disabled: true,
    })

    this.receiverOptions.push({
      value: `patient_${this.receiversData.first_patient.id}_`,
      email: this.receiversData.first_patient.email || 'Keine E-Mail',
      label: `${this.receiversData.first_patient.full_name} - Patient`,
      disabled: !this.receiversData.first_patient.email,
    })

    if (this.receiversData.second_patient) {
      this.receiverOptions.push({
        value: `patient_${this.receiversData.second_patient.id}_`,
        email: this.receiversData.second_patient.email || 'Keine E-Mail',
        label: `${this.receiversData.second_patient.full_name} - Patient`,
        disabled: !this.receiversData.second_patient.email,
      })
    }

    for (const contactPerson of this.receiversData.contact_persons) {
      if (contactPerson.email_private) {
        this.receiverOptions.push({
          value: `ap_${contactPerson.id}_private`,
          email: `Private E-Mail: ${contactPerson.email_private}`,
          label: `${contactPerson.full_name} - AP`,
          disabled: false,
        })
      }

      if (contactPerson.email_work) {
        this.receiverOptions.push({
          value: `ap_${contactPerson.id}_work`,
          email: `Geschäftliche E-Mail: ${contactPerson.email_work}`,
          label: `${contactPerson.full_name} - AP`,
          disabled: false,
        })
      }

      // Wenn gar keine E-Mail hinterlegt ist.
      if (!contactPerson.email_private && !contactPerson.email_work) {
        this.receiverOptions.push({
          value: `ap_${contactPerson.id}_`,
          email: 'Keine E-Mail',
          label: `${contactPerson.full_name} - AP`,
          disabled: true,
        })
      }
    }
  }

  public buildCCOptions(): void {
    this.ccOptions = []

    this.ccOptions.push({
      value: this.receiversData.first_patient.email,
      email: this.receiversData.first_patient.email || 'Keine E-Mail',
      label: `${this.receiversData.first_patient.full_name} - Patient`,
      disabled: !this.receiversData.first_patient.email,
    })

    if (this.receiversData.second_patient) {
      this.ccOptions.push({
        value: this.receiversData.second_patient.email,
        email: this.receiversData.second_patient.email || 'Keine E-Mail',
        label: `${this.receiversData.second_patient.full_name} - Patient`,
        disabled: !this.receiversData.second_patient.email,
      })
    }

    for (const contactPerson of this.receiversData.contact_persons) {
      if (contactPerson.email_private) {
        this.ccOptions.push({
          value: contactPerson.email_private,
          email: `Private E-Mail: ${contactPerson.email_private}`,
          label: `${contactPerson.full_name} - AP - Private E-Mail`,
          disabled: false,
        })
      }

      if (contactPerson.email_work) {
        this.ccOptions.push({
          value: contactPerson.email_work,
          email: `Geschäftliche E-Mail: ${contactPerson.email_work}`,
          label: `${contactPerson.full_name} - AP - Geschäftliche E-Mail`,
          disabled: false,
        })
      }

      // Wenn gar keine E-Mail hinterlegt ist.
      if (!contactPerson.email_private && !contactPerson.email_work) {
        this.ccOptions.push({
          value: null,
          email: 'Keine E-Mail',
          label: `${contactPerson.full_name} - AP - Keine E-Mail`,
          disabled: true,
        })
      }
    }
  }

  public remove(event: any): void {
    this.confirmationService.confirm({
      target: event.target ?? undefined,
      message: 'BE wirklich stornieren?',
      accept: () => {
        this.submittedDelete = true

        this.consultingService.remove(this.config.data.consulting.id).subscribe(
          () => {
            this.submittedDelete = false
            this.eventbus.emit(GlobalEvent.ConsultingChanged)
            this.ref.close()
            this.toastService.success(
              'Beratungseinsatz storniert',
              'Der Termin wurde erfolgreich storniert'
            )
          },
          () => {
            this.submittedDelete = false
            this.toastService.error(
              'Storno fehlgeschlagen',
              'Der Eintrag konnte nicht gelöscht werden'
            )
          }
        )
      },
      reject: () => {},
    })
  }
}
