<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-4">
        <label>Benutzername *</label>
        <input
          [(ngModel)]="values.name"
          id="name"
          name="name"
          pInputText
          [required]="true"
          #name="ngModel"
          [class.p-invalid]="name.invalid && (name.dirty || name.touched)"
        />
      </div>

      <div class="p-field p-col-4">
        <label for="email_multiplier"
          >E-Mail *<i
            class="pi pi-info-circle"
            pTooltip="Für den Login. Es wird eine automatische Passwort E-Mail versendet."
          ></i
        ></label>
        <input
          [(ngModel)]="values.email"
          id="email_multiplier"
          name="email_multiplier"
          type="email"
          pInputText
          [required]="true"
          #email="ngModel"
          [class.p-invalid]="email.invalid && (email.dirty || email.touched)"
        />
      </div>

      <!--      <div class="p-field p-col-4">-->
      <!--        <label for="initial_password"-->
      <!--          >Initiales Passwort<i-->
      <!--            class="pi pi-info-circle"-->
      <!--            pTooltip="Mit diesem Passwort kann sich der Benutzer initial einloggen. Dieser wird nach dem ersten Login aufgefordert, ein neues Passwort zu setzen. Bitte leer lassen, wenn es nicht geändert werden soll."-->
      <!--          ></i-->
      <!--        ></label>-->
      <!--        <input-->
      <!--          [(ngModel)]="values.initial_password"-->
      <!--          id="initial_password"-->
      <!--          name="initial_password"-->
      <!--          type="password"-->
      <!--          pInputText-->
      <!--        />-->
      <!--      </div>-->
    </div>
  </div>
  <div class="dialog-form-footer">
    <div class="p-field-checkbox">
      <p-checkbox
        name="todo"
        [binary]="true"
        label="Account aktiv"
        [(ngModel)]="values.is_active"
      ></p-checkbox>
    </div>

    <button
      (click)="save()"
      pButton
      label="Speichern"
      type="button"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>
