<div class="content-actions">
  <h2>
    <i class="tio">document_text_outlined</i>
    Dokumente
  </h2>
</div>

<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <span>Alle globalen Dokumente</span>

    <div class="content-header-buttons">
      <p-checkbox
        label="nur archivierte"
        [binary]="true"
        (onChange)="loadDocuments(false)"
        [(ngModel)]="onlyArchived"
        name="only_archived"
      ></p-checkbox>

      <button
        (click)="openAddGlobalDocumentDialog()"
        pButton
        label="Neues Dokument"
        type="button"
        icon="pi pi-plus"
        iconPos="left"
        class="p-button-outlined"
      ></button>

      <div
        class="table-search-container"
        [class.searchfield-active]="globalFilterValue != ''"
      >
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            (input)="filterTableGlobal($event)"
            placeholder="Suche..."
            [(ngModel)]="globalFilterValue"
          />
        </div>
      </div>
    </div>
  </header>
</div>

<div class="content" style="margin: 0" *ngIf="!loading">
  <div class="content-body" *ngIf="documents.length == 0">
    <span class="content-no-message">Keine globalen Dokumente vorhanden</span>
  </div>

  <div class="content-body without-padding" *ngIf="documents.length > 0">
    <p-table
      #dt
      [autoLayout]="true"
      [value]="documents"
      [globalFilterFields]="['id', 'name', 'comment']"
      [rowHover]="true"
      scrollHeight="100%"
      [scrollable]="true"
      [(first)]="firstPage"
      stateStorage="local"
      stateKey="state-global-documents"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th style="min-width: 320px" pSortableColumn="name">
            Name <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th pSortableColumn="comment">
            Beschreibung <p-sortIcon field="comment"></p-sortIcon>
          </th>
          <th style="width: 180px" pSortableColumn="created_at">
            Erstellt am <p-sortIcon field="created_at"></p-sortIcon>
          </th>
          <th style="width: 160px">von</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-document>
        <tr class="clickable">
          <td (click)="openDownloadTab(document.media[0])">
            {{ document.name }}
          </td>
          <td (click)="openDownloadTab(document.media[0])">
            {{ document.comment || "-" }}
          </td>
          <td (click)="openDownloadTab(document.media[0])" style="width: 180px">
            {{ document.created_at | date: "dd.MM.y, H:mm" }} Uhr
          </td>
          <td (click)="openDownloadTab(document.media[0])" style="width: 160px">
            {{ document.media[0].custom_properties.created_from_name || "-" }}
          </td>
          <td class="table-action" style="width: 100px">
            <i
              (click)="openAddGlobalDocumentDialog(document)"
              class="pi pi-pencil with-margin-right"
              tooltipPosition="left"
              pTooltip="Bearbeiten"
            ></i>
            <a
              [href]="
                documentService.getDocumentDownloadLink(document.media[0].uuid)
              "
              target="_blank"
            >
              <i
                pTooltip="Herunterladen"
                tooltipPosition="left"
                class="pi pi-download"
              ></i>
            </a>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
