import { Component, OnDestroy, OnInit } from '@angular/core'
import { DashboardService } from '../../services/dashboard.service'
import { DashboardModel } from '../../models/dashboard/dashboard.model'
import {
  PhoneCallsListItemModel,
  PhoneCallsListModel,
} from '../../models/phone-call/phone-calls-list.model'
import { PhoneCallEditDialogComponent } from '../../components/dialogs/phone-call-edit-dialog/phone-call-edit-dialog.component'
import { DialogService } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from '../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { TitleService } from '../../services/title.service'
import { PhoneCallService } from '../../services/phone-call.service'
import { DragulaService } from 'ng2-dragula'
import { TodoModel } from '../../models/todo/todo.model'
import { HistoryListModel } from '../../models/history/history-list.model'
import { HistoryManualDialogComponent } from '../../components/dialogs/history-manual-dialog/history-manual-dialog.component'
import { ShowTodoDialogComponent } from '../../components/dialogs/show-todo-dialog/show-todo-dialog.component'
import { PatientModel } from '../../models/customer-patient/patient.model'
import { CaregiverDetailModel } from '../../models/caregiver/caregiver-detail.model'
import { UserSystemModel } from '../../models/user/user-system.model'
import { VacationIllModel } from '../../models/vacation-ill/vacation-ill.model'
import { AuthService } from '../../services/auth.service'
import { Router } from '@angular/router'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit, OnDestroy {
  public data: DashboardModel = new DashboardModel()

  private dragulaSubscription: Subscription = new Subscription()
  private eventBusSubscription: Subscription = new Subscription()

  public types = [
    'pause_ending',
    'todos',
    'birthdays',
    'vacation_ill',
    'important_histories',
    'new_created',
    'phone_calls',
  ]

  public loadings = {
    pause_ending: true,
    todos: true,
    birthdays: true,
    vacation_ill: true,
    important_histories: true,
    new_created: true,
    phone_calls: true,
  }

  public settings = {
    only_assigned: false,
  }

  constructor(
    private dashboardService: DashboardService,
    private eventbus: EventBusService,
    private router: Router,
    private authService: AuthService,
    public phoneCallService: PhoneCallService,
    private dragulaService: DragulaService,
    private titleService: TitleService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    const user = this.authService.getUser()

    // Die Startseite einer Betreuungskraft ist nicht auf /, sondern auf /daten.
    if (user && user.is_caregiver) {
      this.router.navigate(['dienstplan'])

      return
    }

    // Die Startseite eines Multiplikators ist nicht auf /, sondern auf /patienten.
    if (user && user.is_multiplier) {
      this.router.navigate(['patienten'])

      return
    }

    this.titleService.setTitle('Übersicht')

    this.initSettings()
    this.initDragDrop()

    this.loadPauseEnding()
    this.loadTodos()
    this.loadImportantHistories()
    this.loadBirthdays()
    this.loadVacationIll()
    this.loadNewCreated()
    this.loadPhoneCalls()

    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.dragulaService.destroy('DASHBOARD')
    this.eventBusSubscription.unsubscribe()
    this.dragulaSubscription.unsubscribe()
  }

  private initSettings(): void {
    // Falls noch nichts im Speicher vorhanden ist, setze den Default wert.
    if (localStorage.getItem('dashboard-settings') === null) {
      localStorage.setItem('dashboard-settings', JSON.stringify(this.settings))
    } else {
      // Falls aber was vorhanden ist, werden die Settings aus dem Speicher genommen.
      const savedSettings = JSON.parse(
        localStorage.getItem('dashboard-settings') || '{}'
      )

      // Es muss geprüft werden, ob die Menge der gespeicherten Daten übereinstimmt.
      // Falls nicht, wurde bei der Entwicklung ein neues Setting hinzugefügt
      // und die Daten werden nun zurückgesetzt.
      if (
        Object.keys(savedSettings).length != Object.keys(this.settings).length
      ) {
        localStorage.setItem(
          'dashboard-settings',
          JSON.stringify(this.settings)
        )
      } else {
        this.settings = JSON.parse(
          localStorage.getItem('dashboard-settings') || '{}'
        )
      }
    }
  }

  private initDragDrop(): void {
    // Falls noch nichts im Speicher vorhanden ist, setze den Default wert.
    if (localStorage.getItem('dashboard-orders') === null) {
      localStorage.setItem('dashboard-orders', JSON.stringify(this.types))
    } else {
      // Falls aber was vorhanden ist, wird die Reihenfolge aus dem Speicher genommen.
      const savedOrders = JSON.parse(
        localStorage.getItem('dashboard-orders') || '[]'
      )

      // Es muss geprüft werden, ob die Menge der gespeicherten Daten übereinstimmt.
      // Falls nicht, wurde bei der Entwicklung ein neues Dashboard hinzugefügt
      // und die Daten werden nun zurückgesetzt.
      if (savedOrders.length != this.types.length) {
        localStorage.setItem('dashboard-orders', JSON.stringify(this.types))
      } else {
        this.types = JSON.parse(
          localStorage.getItem('dashboard-orders') || '[]'
        )
      }
    }

    // Erlaubt das Drag nur über das Symbol.
    this.dragulaService.createGroup('DASHBOARD', {
      moves: (el, container, handle) => {
        // @ts-ignore
        return handle.classList.contains('handle')
      },
    })

    // Setzt die Reihenfolge nach dem Drop in den Speicher.
    this.dragulaSubscription = this.dragulaService
      .drop('DASHBOARD')
      .subscribe(() => {
        const orders: string[] = []

        Array.from(document.querySelectorAll('[data-type]')).forEach(
          (element: any) => {
            const type = element.getAttribute('data-type')

            if (!orders.includes(type)) {
              orders.push(type)
            }
          }
        )

        localStorage.setItem('dashboard-orders', JSON.stringify(orders))
      })
  }

  public openManualHistoryDialog(data: any): void {
    if (data.already_wished) {
      return
    }

    this.dialogService.open(HistoryManualDialogComponent, {
      data: {
        edit: false,
        history: new HistoryListModel(),
        type: data.type,
        type_id: data.id,
        is_birthday_wish: true,
      },
      header: 'Neuer Eintrag',
      styleClass: 'dialog-container',
      dismissableMask: false,
      width: '520px',
    })
  }

  public changedSettings(): void {
    localStorage.setItem('dashboard-settings', JSON.stringify(this.settings))

    this.loadTodos(false)
  }

  private loadPauseEnding(withLoading: boolean = true): void {
    if (withLoading) {
      this.loadings.pause_ending = true
    }

    this.dashboardService
      .loadPauseEnding()
      .subscribe((data: PatientModel[]) => {
        this.data.pause_ending = data

        if (withLoading) {
          this.loadings.pause_ending = false
        }
      })
  }

  private loadTodos(withLoading: boolean = true): void {
    if (withLoading) {
      this.loadings.todos = true
    }

    this.dashboardService
      .loadTodos(this.settings)
      .subscribe((data: TodoModel[]) => {
        this.data.todos = data

        if (withLoading) {
          this.loadings.todos = false
        }
      })
  }

  private loadBirthdays(withLoading: boolean = true): void {
    if (withLoading) {
      this.loadings.birthdays = true
    }

    this.dashboardService
      .loadBirthdays()
      .subscribe(
        (data: PatientModel[] | CaregiverDetailModel[] | UserSystemModel[]) => {
          this.data.birthdays = data

          if (withLoading) {
            this.loadings.birthdays = false
          }
        }
      )
  }

  private loadVacationIll(withLoading: boolean = true): void {
    if (withLoading) {
      this.loadings.vacation_ill = true
    }

    this.dashboardService
      .loadVacationIll()
      .subscribe((data: VacationIllModel[]) => {
        this.data.vacation_ill = data

        if (withLoading) {
          this.loadings.vacation_ill = false
        }
      })
  }

  private loadNewCreated(withLoading: boolean = true): void {
    if (withLoading) {
      this.loadings.new_created = true
    }

    this.dashboardService.loadNewCreated().subscribe((data: PatientModel[]) => {
      this.data.new_created = data

      if (withLoading) {
        this.loadings.new_created = false
      }
    })
  }

  private loadPhoneCalls(withLoading: boolean = true): void {
    if (withLoading) {
      this.loadings.phone_calls = true
    }

    this.dashboardService
      .loadPhoneCalls()
      .subscribe((data: PhoneCallsListItemModel[]) => {
        this.data.phone_calls = data

        if (withLoading) {
          this.loadings.phone_calls = false
        }
      })
  }

  private loadImportantHistories(withLoading: boolean = true): void {
    if (withLoading) {
      this.loadings.important_histories = true
    }

    this.dashboardService
      .loadImportantHistories()
      .subscribe((data: HistoryListModel[]) => {
        this.data.important_histories = data

        if (withLoading) {
          this.loadings.important_histories = false
        }
      })
  }

  /**
   * Öffnet das Modal zum Bearbeiten eines Phonecalls.
   */
  public openPhonecallEditModal(phonecall: PhoneCallsListModel): void {
    this.dialogService.open(PhoneCallEditDialogComponent, {
      header: 'Telefonat bearbeiten',
      width: '540px',
      styleClass: 'dialog-container',
      dismissableMask: false,
      data: phonecall,
    })
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.PhoneCallListReload:
            this.loadPhoneCalls(false)
            break
          case GlobalEvent.TodoChanged:
            this.loadTodos(false)
            break
          case GlobalEvent.BirthdayWished:
            this.loadBirthdays(false)
            break
        }
      }
    )
  }

  public openShowTodoDialog(todo: TodoModel): void {
    this.dialogService.open(ShowTodoDialogComponent, {
      header: 'Todo ansehen',
      width: '820px',
      styleClass: 'dialog-container',
      data: {
        isEdit: true,
        todo,
        todo_id: todo.id,
        user_type_name: todo?.caregiver?.full_name || todo?.patient?.full_name,
      },
    })
  }
}
