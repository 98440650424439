<app-loader [loading]="loading"></app-loader>

<div class="content new-content-container" style="margin: 0" *ngIf="!loading">
  <header class="content-header">
    <span>Offene Feedbacks</span>

    <div
      class="table-search-container"
      [class.searchfield-active]="globalFilterValue != ''"
      *ngIf="data.length > 0"
    >
      <div class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input
          pInputText
          (input)="filterTableGlobal($event)"
          placeholder="Suche..."
          [(ngModel)]="globalFilterValue"
        />
      </div>
    </div>
  </header>

  <div
    class="content-body"
    [class.content-loading]="contentLoading"
    *ngIf="data.length == 0"
  >
    <span class="content-no-message">Kein Feedback vorhanden</span>
  </div>

  <div
    class="content-body content-body-full-height-with-subtabs without-padding"
    [class.content-loading]="contentLoading"
    *ngIf="data.length > 0"
  >
    <p-table
      #dt
      [value]="data"
      [rowHover]="true"
      [paginator]="true"
      [globalFilterFields]="[
        'data.caregiver.first_name',
        'data.caregiver.last_name',
        'data.caregiver.full_name',
        'data.patient.first_name',
        'data.patient.last_name',
        'data.patient.full_name'
      ]"
      [rows]="50"
      stateStorage="local"
      stateKey="plans-feedback-created-4"
      scrollHeight="100%"
      [(first)]="firstPage"
      [scrollable]="true"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{totalRecords} Feedbacks"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th style="max-width: 150px" pSortableColumn="type">
            Typ <p-sortIcon field="type"></p-sortIcon>
          </th>
          <th style="max-width: 300px" pSortableColumn="data.caregiver_id">
            Betreuungskraft
            <p-sortIcon field="data.caregiver_id"></p-sortIcon>
          </th>
          <th style="max-width: 150px" pSortableColumn="data.real_from">
            Datum
            <p-sortIcon field="data.real_from"></p-sortIcon>
          </th>
          <th style="max-width: 150px" pSortableColumn="data.real_from_h">
            Uhrzeit
            <p-sortIcon field="data.real_from_h"></p-sortIcon>
          </th>
          <th style="max-width: 260px" pSortableColumn="data.patient_id">
            Patient
            <p-sortIcon field="data.patient_id"></p-sortIcon>
          </th>
          <th style="max-width: 50px"></th>
          <th>Details</th>
          <th style="max-width: 200px" pSortableColumn="created_at">
            Erstellt am
            <p-sortIcon field="created_at"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr class="clickable">
          <td style="max-width: 150px" (click)="openPlansFeedbackDialog(item)">
            <div class="text-center-container" *ngIf="item.type === 'FEEDBACK'">
              <i class="pi pi-envelope color-gray"></i>
              Feedback
            </div>

            <div class="text-center-container" *ngIf="item.type === 'TRIP'">
              <i class="pi pi-car color-gray"></i>
              KM-Fahrt
            </div>

            <div
              class="text-center-container"
              *ngIf="item.type === 'TIME_CHANGE'"
            >
              <i class="pi pi-clock color-gray" style="font-size: 1rem"></i>
              Dauer
            </div>

            <div
              class="text-center-container"
              *ngIf="item.type === 'DRIVE_TIME_FEEDBACK'"
            >
              <i class="pi pi-clock color-gray" style="font-size: 1rem"></i>
              Fahrzeit
            </div>
          </td>
          <td style="max-width: 300px" (click)="openPlansFeedbackDialog(item)">
            <a routerLink="/caregivers/{{ item.data.caregiver_id }}/overview"
              >{{ item.data.caregiver.first_name }}
              {{ item.data.caregiver.last_name }}</a
            >
          </td>
          <td style="max-width: 150px" (click)="openPlansFeedbackDialog(item)">
            {{ item.data.real_from | date: "dd.MM.y" }} ({{
              item.data.weekday[0]
            }}{{ item.data.weekday[1] }})
          </td>
          <td style="max-width: 150px" (click)="openPlansFeedbackDialog(item)">
            {{ item.data.real_from_h }} - {{ item.data.real_to_h }}
          </td>
          <td style="max-width: 260px" (click)="openPlansFeedbackDialog(item)">
            <a routerLink="/patients/{{ item.data.patient_id }}"
              >{{ item.data.patient.first_name }}
              {{ item.data.patient.last_name }}</a
            >
          </td>
          <td style="max-width: 50px" (click)="openPlansFeedbackDialog(item)">
            <i
              class="pi pi-file"
              pTooltip="Mit Dokument"
              style="font-size: 15px; margin: 0 0 0 10px"
              *ngIf="item.documents.length > 0"
            ></i>
          </td>
          <td (click)="openPlansFeedbackDialog(item)">
            <div class="text-center-container" *ngIf="item.type === 'FEEDBACK'">
              Feedback erstellt
              <i
                class="pi pi-info-circle color-gray"
                style="font-size: 14px"
                [pTooltip]="item.tooltip"
                tooltipStyleClass="tooltip-wider tooltip-wider-light"
                tooltipPosition="left"
              ></i>
            </div>
            <div class="text-center-container" *ngIf="item.type === 'TRIP'">
              {{
                item.data.caregiver_appointment_trip.mileage
                  .toString()
                  .replace(".", ",")
              }}
              KM
              <i
                class="pi pi-info-circle color-gray"
                style="font-size: 14px"
                [pTooltip]="item.tooltip"
                tooltipStyleClass="tooltip-wider tooltip-wider-light"
                tooltipPosition="left"
              ></i>
            </div>
            <!-- Dauer -->
            <div
              class="text-center-container"
              *ngIf="item.type === 'TIME_CHANGE'"
            >
              <span *ngIf="item.data.caregiver_appointment_change.time > 0">
                +</span
              >{{
                item.data.caregiver_appointment_change.time
                  .toString()
                  .replace(".", ",")
              }}
              Std
              <i
                class="pi pi-info-circle color-gray"
                style="font-size: 14px"
                *ngIf="item.data.caregiver_appointment_change.time_feedback"
                [pTooltip]="
                  item.data.caregiver_appointment_change.time_feedback
                "
                tooltipPosition="left"
              ></i>
            </div>
            <!-- Fahrzeit -->
            <div
              class="text-center-container"
              *ngIf="item.type === 'DRIVE_TIME_FEEDBACK'"
            >
              <span
                *ngIf="item.data.caregiver_appointment_change.drive_time > 0"
              >
                +</span
              >{{
                item.data.caregiver_appointment_change.drive_time
                  .toString()
                  .replace(".", ",")
              }}
              min
              <i
                class="pi pi-info-circle color-gray"
                style="font-size: 14px"
                *ngIf="
                  item.data.caregiver_appointment_change.drive_time_feedback
                "
                [pTooltip]="
                  item.data.caregiver_appointment_change.drive_time_feedback
                "
                tooltipPosition="left"
              ></i>
            </div>
          </td>
          <td style="max-width: 200px" (click)="openPlansFeedbackDialog(item)">
            {{ item.created_at | date: "dd.MM.y, H:mm" }} Uhr
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
