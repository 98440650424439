import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs'
import { PhoneCallsListItemModel } from '../models/phone-call/phone-calls-list.model'
import { CaregiverProofGroupedModel } from '../models/caregiver/caregiver-proof.model'
import { CaregiverDetailGeneralDataModel } from '../models/caregiver/caregiver-detail-general.model'
import {
  CaregiverDetailDocumentsModel,
  CaregiverDocumentTypesModel,
} from '../models/caregiver/caregiver-detail-documents.model'
import {
  DocumentModel,
  DocumentModelSimple,
} from '../models/document/document.model'
import {CaregiverHospitationFeedbackModel} from "../models/caregiver/caregiver-hospitation-feedback.model";

@Injectable({
  providedIn: 'root',
})
export class UserCaregiverService {
  constructor(private http: HttpClient) {}

  public loadPhoneCalls(): Observable<PhoneCallsListItemModel[]> {
    return this.http.get<PhoneCallsListItemModel[]>(
      `${environment.api}/user-caregiver/phone-calls`
    )
  }

  public loadProofs(): Observable<CaregiverProofGroupedModel> {
    return this.http.get<CaregiverProofGroupedModel>(
      `${environment.api}/user-caregiver/proofs`
    )
  }

  public loadAnalyses(year: string): Observable<CaregiverProofGroupedModel> {
    return this.http.get<any>(
      `${environment.api}/user-caregiver/analyses?year=${year}`
    )
  }

  public loadRecommendations(): Observable<any[]> {
    return this.http.get<any[]>(
      `${environment.api}/user-caregiver/recommendations`
    )
  }

  public loadHospitations(): Observable<any[]> {
    return this.http.get<any[]>(
      `${environment.api}/user-caregiver/hospitations`
    )
  }

  public loadAnonymousAppointments(persplanId: number): Observable<any> {
    return this.http.get<any>(
      `${environment.api}/user-caregiver/anonymous-appointments/${persplanId}`
    )
  }

  public loadDocuments(): Observable<DocumentModelSimple[]> {
    return this.http.get<DocumentModelSimple[]>(
      `${environment.api}/user-caregiver/documents`
    )
  }

  public loadData(): Observable<CaregiverDetailGeneralDataModel> {
    return this.http.get<CaregiverDetailGeneralDataModel>(
      `${environment.api}/user-caregiver/data`
    )
  }

  public loadCalender(year: string): Observable<any> {
    return this.http.get<any>(`${environment.api}/user-caregiver/calendar`, {
      params: {
        year,
      },
    })
  }

  public getPersplan(date: string): Observable<string> {
    return this.http.get(`${environment.api}/user-caregiver/persplan`, {
      responseType: 'text',
      params: {
        date,
      },
    })
  }

  public approveOverview(
    comment: string,
    month: string,
    year: string
  ): Observable<any> {
    return this.http.post<any>(
      `${environment.api}/user-caregiver/approve-overview`,
      {
        comment,
        month,
        year,
      }
    )
  }

  public storeHospitation(caregiverId: number, values: any): Observable<any> {
    return this.http.post<any>(
      `${environment.api}/user-caregiver/hospitation/${caregiverId}`, {
        ...values
      })
  }

  public updateHospitation(statusId: number, values: any): Observable<any> {
    return this.http.put<any>(
      `${environment.api}/user-caregiver/hospitation/${statusId}`, {
        ...values
      })
  }

  public disapproveOverview(month: string, year: string): Observable<any> {
    return this.http.post<any>(
      `${environment.api}/user-caregiver/disapprove-overview`,
      {
        month,
        year,
      }
    )
  }

  public releaseAppointment(persplanId: number): Observable<any> {
    return this.http.post<any>(
      `${environment.api}/user-caregiver/release-appointment/${persplanId}`,
      {}
    )
  }

  public unreleaseAppointment(persplanId: number): Observable<any> {
    return this.http.post<any>(
      `${environment.api}/user-caregiver/unrelease-appointment/${persplanId}`,
      {}
    )
  }

  public changeAppointment(
    persplanId: string | number,
    values: any
  ): Observable<any> {
    return this.http.post<any>(
      `${environment.api}/user-caregiver/change-appointment/${persplanId}`,
      {
        ...values,
      }
    )
  }

  public storeFeedback(
    persplanId: string | number,
    values: any
  ): Observable<any> {
    return this.http.post<any>(
      `${environment.api}/user-caregiver/feedback/${persplanId}`,
      {
        ...values,
      }
    )
  }

  public storeHospitationFeedback(
    caregiverStatusId: number,
    values: CaregiverHospitationFeedbackModel
  ): Observable<CaregiverHospitationFeedbackModel> {
    return this.http.post<CaregiverHospitationFeedbackModel>(
      `${environment.api}/user-caregiver/hospitation-feedback/${caregiverStatusId}`,
      {
        ...values,
      }
    )
  }

  public updateHospitationFeedback(
    values: CaregiverHospitationFeedbackModel
  ): Observable<CaregiverHospitationFeedbackModel> {
    return this.http.put<CaregiverHospitationFeedbackModel>(
      `${environment.api}/user-caregiver/hospitation-feedback/${values.id}`,
      {
        ...values,
      }
    )
  }

  public storeChangeFeedback(
    persplanId: string | number,
    values: any
  ): Observable<any> {
    return this.http.post<any>(
      `${environment.api}/user-caregiver/appointment-change-feedback/${persplanId}`,
      {
        ...values,
      }
    )
  }

  public storeTrip(persplanId: string | number, values: any): Observable<any> {
    return this.http.post<any>(
      `${environment.api}/user-caregiver/trip/${persplanId}`,
      {
        ...values,
      }
    )
  }

  public loadOverviewList(month: string, year: string): Observable<any> {
    return this.http.get<any>(
      `${environment.api}/user-caregiver/overview-list`,
      {
        params: {
          month,
          year,
        },
      }
    )
  }

  public getDownloadProofDocumentLink(uuid: string): string {
    return `${environment.api}/user-caregiver/download-proof/${uuid}`
  }

  public getDownloadDocumentLink(uuid: string): string {
    return `${environment.api}/user-caregiver/download/${uuid}`
  }

  public getDownloadApplicationDocumentLink(uuid: string): string {
    return `${environment.api}/user-caregiver/download-application-document/${uuid}`
  }

  public getZipOfProofsLink(month: string, year: string): string {
    return `${environment.api}/user-caregiver/download-zip-of-proofs?month=${month}&year=${year}`
  }
}
