<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <span>Letzte Terminabsagen</span>

    <div
      class="table-search-container"
      [class.searchfield-active]="globalFilterValue != ''"
      *ngIf="cancelledDates.length > 0"
    >
      <div class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input
          pInputText
          (input)="filterTableGlobal($event)"
          placeholder="Suche..."
          [(ngModel)]="globalFilterValue"
        />
      </div>
    </div>
  </header>
</div>

<div class="content" style="margin-bottom: 0" *ngIf="!loading">
  <div class="content-body" *ngIf="cancelledDates.length == 0">
    <span class="content-no-message">Keine Absagen vorhanden</span>
  </div>

  <div
    class="content-body content-body-full-height-with-tabs without-padding"
    *ngIf="cancelledDates.length > 0"
  >
    <p-table
      #dt
      [value]="cancelledDates"
      [globalFilterFields]="[
        'patient.full_name',
        'reason.name',
        'appointments',
        'comment'
      ]"
      [autoLayout]="true"
      [(first)]="firstPage"
      [rowHover]="true"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{totalRecords} Terminabsagen"
      [rows]="50"
      [paginator]="true"
      scrollHeight="100%"
      [scrollable]="true"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th style="min-width: 300px" pSortableColumn="patient.last_name">
            Patient <p-sortIcon field="patient.last_name"></p-sortIcon>
          </th>
          <th style="min-width: 200px" pSortableColumn="patient.city">
            Wohnort <p-sortIcon field="patient.city"></p-sortIcon>
          </th>
          <th style="max-width: 80px">Termine</th>
          <th style="min-width: 140px" pSortableColumn="cancelled_reason_id">
            Grund
            <p-sortIcon field="cancelled_reason_id"></p-sortIcon>
          </th>
          <th style="min-width: 300px">Bemerkung</th>
          <th style="max-width: 160px" pSortableColumn="created_at">
            Erstellt am <p-sortIcon field="created_at"></p-sortIcon>
          </th>
          <th style="max-width: 120px">Erstellt von</th>
          <th style="max-width: 60px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-date>
        <tr>
          <td style="min-width: 300px">
            <a routerLink="/patients/{{ date.patient_id }}"
              >{{ date.patient.first_name }} {{ date.patient.last_name }}</a
            >
          </td>

          <td style="min-width: 200px">
            <div class="text-center-container">
              <i
                style="font-size: 14px"
                class="pi pi-info-circle color-gray"
                [pTooltip]="date.patient.full_address"
              ></i>
              {{ date.patient.city }}
            </div>
          </td>

          <td style="max-width: 80px">
            <i
              class="pi pi-calendar color-blue"
              style="font-size: 14px"
              tooltipStyleClass="tooltip-wider"
              [escape]="false"
              [pTooltip]="date.appointments.join('<br>')"
            ></i>
            <i
              style="font-size: 19px; margin: 0 0 0 10px"
              class="pi pi-check-circle color-main1"
              pTooltip="Termin wurde gerettet"
              *ngIf="date.appointment_saved"
            ></i>
          </td>

          <td style="min-width: 140px">{{ date.reason?.name || "-" }}</td>
          <td style="min-width: 300px">{{ date.comment }}</td>
          <td style="max-width: 160px">
            {{ date.created_at | date: "dd.MM.y, H:mm" }}
          </td>
          <td style="max-width: 120px">{{ date.user.name }}</td>

          <td class="table-action" style="max-width: 60px">
            <i
              (click)="openAddCancelledDateDialog(date)"
              class="pi pi-pencil"
            ></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
