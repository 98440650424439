import { Component, OnInit, ViewChild } from '@angular/core'
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { ProofsService } from '../../../services/proofs.service'
import { DocumentService } from '../../../services/document.service'
import * as dayjs from 'dayjs'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import * as utc from 'dayjs/plugin/utc'
import { PatientService } from '../../../services/patient.service'
import { HelperService } from '../../../services/helper.service'
import { InvoiceService } from '../../../services/invoice.service'
import { DomSanitizer } from '@angular/platform-browser'
import { ConfirmWithTextDialogComponent } from '../confirm-with-text-dialog/confirm-with-text-dialog.component'
import * as currency from 'currency.js'
import { HttpErrorResponse } from '@angular/common/http'
import { StatusCodes } from 'http-status-codes'
import { MenuItem } from 'primeng/api'
import { PatientModel } from '../../../models/customer-patient/patient.model'
import { InvoiceViewDialogComponent } from '../invoice-view-dialog/invoice-view-dialog.component'

@Component({
  selector: 'app-invoice-new-dialog',
  templateUrl: './invoice-new-dialog.component.html',
})
export class InvoiceNewDialogComponent implements OnInit {
  @ViewChild('form', { static: true }) form!: NgForm

  public submittedDelete = false
  public submitted = false

  private currencyOptions = {
    // Cent
    decimal: ',',
    // Tausender
    separator: '.',
    symbol: '€',
    pattern: `# !`,
  }

  public activeTab = 'CHECK'
  public loading = true

  public showSidebar = false
  public dataForCheckStatusIsValid = false
  public dataForBudgetStatusIsValid = false
  public hasOpenedReworks = false
  public hasBudgetIssueRework = false
  public data: any = {}

  public pdfSrc: any = ''
  public pdfSrcSafe: any = ''
  public mailPreviewSrc = ''

  public clickedOnPreviewLnw = false
  public clickedOnPreviewMail = false
  public clickedOnPreviewPdf = false

  public lnwBase64 = null
  public mediaGenerated = false
  public generatedMediaLink = ''

  public viewerLoading = true
  public rotating = false

  public currentPatient: any = null
  public selectedPatientAddress = ''
  public selectedCostUnitAddress = ''
  public selectedReceiverAddress = ''
  public selectedAddress: any = {}
  public selectedUnitCostReceiver: any = null
  public selectedReceiver: any = {}
  private viewer: any = null

  public currentDate = ''

  public selectedGlobalTab: any = {
    type: 'PROOF',
    index: 0,
  }

  public type: any = []
  public comment = ''

  public isPkv = false
  public isSelbstzahler = false
  public hasMedia = false

  public typeOptions = [
    { label: 'Sonstiges', value: 'OTHER' },
    { label: 'Fehlerhafter Leistungsnachweis', value: 'PROOF_ISSUE' },
    { label: 'Fehlerhaftes Budget', value: 'BUDGET_ISSUE' },
    { label: 'Fehlerhafte Daten Kunde', value: 'CUSTOMER_DATA_ISSUE' },
    { label: 'Änderung Termin', value: 'APPOINTMENT_CHANGE' },
  ]

  public items: MenuItem[] = [
    {
      label: 'Rechnung versenden',
      icon: 'pi pi-file-pdf',
      command: () => {
        this.createInvoice()
      },
    },
  ]

  public stampSaveSubmitted = false
  public withDateStamp = false
  private positions = {
    stamp: {
      x: 0,
      y: 0,
    },
    date: {
      x: 0,
      y: 0,
    },
  }
  public costUnitHasNoContactPersons = false
  public contactPersonsForInvoice = ''
  public receiversData: any = {}
  public patientReceiverOptions: any[] = []
  public costUnitReceiverOptions: any[] = []
  public costUnitEmailReceiverOptions: any[] = []
  public addressReceiverOptions: any[] = []
  public ccOptions: any[] = []
  public formOfAddress: any = []

  public partialPaymentEditId: any = null
  public partialPayment = ''

  public invoiceValues = {
    invoice_receiver: '',
    individual_email: '',
    individual_address: '',
    individual_form_of_address: '',
    individual_first_name: '',
    individual_last_name: '',
    individual_street_house_number: '',
    individual_zipcode: '',
    individual_city: '',
    accounting_type: '',
    patient_receiver: '',
    cost_unit_receiver: '',
    cost_unit_email_receiver: '',
    accounting_receiver: '',
    payment_type: '',
    send_type: '',
    receiver: null as any,
    cc_receivers: [],
  }
  public patient: PatientModel = new PatientModel()

  constructor(
    private ref: DynamicDialogRef,
    public proofService: ProofsService,
    private invoiceService: InvoiceService,
    public config: DynamicDialogConfig,
    private dialogService: DialogService,
    public documentService: DocumentService,
    private patientService: PatientService,
    private sanitizer: DomSanitizer,
    private eventbus: EventBusService,
    private helperService: HelperService,
    private toastService: ToastService
  ) {
    dayjs.locale('de')
    dayjs.extend(customParseFormat)
    dayjs.extend(utc)
  }

  public ngOnInit(): void {
    this.data = this.config.data.invoice

    if (this.data.type === 'KM') {
      this.patient = this.data.invoiceable
    } else if (this.data.type === 'CONSULTING') {
      this.patient = this.data.invoiceable.patient
    }

    this.loadReceivers()

    this.helperService.dependencies$.subscribe((data: any) => {
      this.formOfAddress = data.form_of_address_with_company
    })
  }

  private loadReceivers(): void {
    this.patientService
      .getReceivers(this.patient.id)
      .subscribe((response: any) => {
        this.receiversData = response

        // this.setInvoiceFields()

        this.buildReceiverOptions()
        this.buildAddressReceiverOptions(false)
        this.buildCCOptions()
        // this.buildCostUnitReceiverOptions()

        // Wir setzen die Daten nochmal rein, weil diese unter umständen resetten wurden vorher.
        // this.setInvoiceFields()

        this.currentPatient =
          this.receiversData.first_patient.id == this.patient.id
            ? this.receiversData.first_patient
            : this.receiversData.second_patient

        this.getPreviewLink()

        this.loading = false
      })
  }

  public buildReceiverOptions(): void {
    this.patientReceiverOptions = []

    // Der aktuelle Patient wird als Standard ausgewählt
    this.data.patient_receiver = `patient_${this.patient.id}_`
    this.data.accounting_receiver = `patient_${this.patient.id}_`

    this.patientReceiverSelected()

    this.patientReceiverOptions.push({
      value: `patient_${this.receiversData.first_patient.id}_`,
      email: this.receiversData.first_patient.email || 'Keine E-Mail',
      label: `${this.receiversData.first_patient.full_name} - Patient`,
      // disabled: this.values.type === 'email' && !this.receiversData.first_patient.email,
    })

    if (this.receiversData.second_patient) {
      this.patientReceiverOptions.push({
        value: `patient_${this.receiversData.second_patient.id}_`,
        email: this.receiversData.second_patient.email || 'Keine E-Mail',
        label: `${this.receiversData.second_patient.full_name} - Patient`,
        // disabled: this.values.type === 'email' && !receiversData.second_patient.email,
      })
    }

    const contactPersonsForInvoice = []

    for (let contactPerson of this.receiversData.customer.contact_persons) {
      // Ich erstelle mir außerdem eine Liste von allen Ansprechpartnern, die eine Rechnung haben wollen.
      if (contactPerson.is_for_invoices) {
        contactPersonsForInvoice.push(
          `${contactPerson.first_name} ${contactPerson.last_name}`
        )
      }

      if (contactPerson.email_private) {
        this.patientReceiverOptions.push({
          value: `ap_${contactPerson.id}_private`,
          email: `Private E-Mail: ${contactPerson.email_private}`,
          label: `${contactPerson.full_name} - AP`,
          disabled: false,
        })

        // continue
      }

      if (contactPerson.email_work) {
        this.patientReceiverOptions.push({
          value: `ap_${contactPerson.id}_work`,
          email: `Geschäftliche E-Mail: ${contactPerson.email_work}`,
          label: `${contactPerson.full_name} - AP`,
          disabled: false,
        })
      }

      // Wenn gar keine E-Mail hinterlegt ist.
      if (!contactPerson.email_private && !contactPerson.email_work) {
        this.patientReceiverOptions.push({
          value: `ap_${contactPerson.id}_`,
          email: 'Keine E-Mail',
          label: `${contactPerson.full_name} - AP`,
          // disabled: this.values.type === 'email',
        })
      }
    }

    this.contactPersonsForInvoice = contactPersonsForInvoice.join(', ')
  }

  public buildCCOptions(): void {
    this.ccOptions = []

    this.ccOptions.push({
      value: this.receiversData.first_patient.email,
      email: this.receiversData.first_patient.email || 'Keine E-Mail',
      label: `${this.receiversData.first_patient.full_name} - Patient`,
      disabled: !this.receiversData.first_patient.email,
    })

    if (this.receiversData.second_patient) {
      this.ccOptions.push({
        value: this.receiversData.second_patient.email,
        email: this.receiversData.second_patient.email || 'Keine E-Mail',
        label: `${this.receiversData.second_patient.full_name} - Patient`,
        disabled: !this.receiversData.second_patient.email,
      })
    }

    for (const contactPerson of this.receiversData.contact_persons) {
      if (contactPerson.email_private) {
        this.ccOptions.push({
          value: contactPerson.email_private,
          email: `Private E-Mail: ${contactPerson.email_private}`,
          label: `${contactPerson.full_name} - AP - Private E-Mail`,
          disabled: false,
        })
      }

      if (contactPerson.email_work) {
        this.ccOptions.push({
          value: contactPerson.email_work,
          email: `Geschäftliche E-Mail: ${contactPerson.email_work}`,
          label: `${contactPerson.full_name} - AP - Geschäftliche E-Mail`,
          disabled: false,
        })
      }

      // Wenn gar keine E-Mail hinterlegt ist.
      if (!contactPerson.email_private && !contactPerson.email_work) {
        this.ccOptions.push({
          value: null,
          email: 'Keine E-Mail',
          label: `${contactPerson.full_name} - AP - Keine E-Mail`,
          disabled: true,
        })
      }
    }
  }

  public buildAddressReceiverOptions(reset = true): void {
    this.clickedOnPreviewMail = false
    this.clickedOnPreviewPdf = false

    this.costUnitReceiverSelected()

    this.addressReceiverOptions = []

    if (reset) {
      this.data.receiver = null
    }

    this.selectedReceiverAddress = ''

    this.addressReceiverOptions.push({
      value: null,
      label: 'Bitte auswählen',
      email: '',
      disabled: true,
    })

    this.addressReceiverOptions.push({
      value: `patient_${this.receiversData.first_patient.id}_`,
      email: this.receiversData.first_patient.email || 'Keine E-Mail',
      label: `${this.receiversData.first_patient.full_name} - Patient`,
      disabled:
        this.data.sent_type === 'email' &&
        !this.receiversData.first_patient.email,
    })

    if (this.receiversData.second_patient) {
      this.addressReceiverOptions.push({
        value: `patient_${this.receiversData.second_patient.id}_`,
        email: this.receiversData.second_patient.email || 'Keine E-Mail',
        label: `${this.receiversData.second_patient.full_name} - Patient`,
        disabled:
          this.data.sent_type === 'email' &&
          !this.receiversData.second_patient.email,
      })
    }

    for (const contactPerson of this.receiversData.contact_persons) {
      if (contactPerson.email_private) {
        this.addressReceiverOptions.push({
          value: `ap_${contactPerson.id}_private`,
          email: `Private E-Mail: ${contactPerson.email_private}`,
          label: `${contactPerson.full_name} - AP`,
          disabled: false,
        })

        // Bei Postversand soll der AP soll nur einmal in der Liste angezeigt
        // werden, selbst wenn er noch eine Geschäftliche E-Mail Adresse hat.
        if (this.data.sent_type === 'post') {
          continue
        }
      }

      if (contactPerson.email_work) {
        this.addressReceiverOptions.push({
          value: `ap_${contactPerson.id}_work`,
          email: `Geschäftliche E-Mail: ${contactPerson.email_work}`,
          label: `${contactPerson.full_name} - AP`,
          disabled: false,
        })
      }

      // Wenn gar keine E-Mail hinterlegt ist.
      if (!contactPerson.email_private && !contactPerson.email_work) {
        this.addressReceiverOptions.push({
          value: `ap_${contactPerson.id}_`,
          email: 'Keine E-Mail',
          label: `${contactPerson.full_name} - AP`,
          disabled: this.data.sent_type === 'email',
        })
      }
    }

    this.getPreviewLink()
  }

  public getPreviewLink(): void {
    this.pdfSrc = this.invoiceService.getPdfPreviewLink({
      id: this.data.id,
      sent_for: this.data.sent_for,
      sent_type: this.data.sent_type,
      sent_for_id: this.data.multiplier_sent_for?.id,
      address: this.data.address,
      receiver: this.data.receiver,
      cost_unit_email_receiver: this.data.cost_unit_email_receiver,
      cost_unit_receiver: this.data.cost_unit_receiver,
      accounting_receiver: this.data.accounting_receiver,
      patient_receiver: this.data.patient_receiver,
      accounting_type: this.data.accounting_type,
      invoice_receiver: this.data.invoice_receiver,
      individual_city: this.data.individual_city,
      individual_zipcode: this.data.individual_zipcode,
      individual_street_house_number: this.data.individual_street_house_number,
      individual_last_name: this.data.individual_last_name,
      individual_first_name: this.data.individual_first_name,
      individual_form_of_address: this.data.individual_form_of_address,
      individual_email: this.data.individual_email,
    })

    this.pdfSrcSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfSrc)

    this.mailPreviewSrc = this.invoiceService.getMailPreviewLink({
      id: this.data.id,
      sent_for: this.data.sent_for,
      sent_type: this.data.sent_type,
      sent_for_id: this.data.multiplier_sent_for?.id,
      address: this.data.address,
      receiver: this.data.receiver,
      cost_unit_email_receiver: this.data.cost_unit_email_receiver,
      cost_unit_receiver: this.data.cost_unit_receiver,
      accounting_receiver: this.data.accounting_receiver,
      patient_receiver: this.data.patient_receiver,
      accounting_type: this.data.accounting_type,
      invoice_receiver: this.data.invoice_receiver,
      individual_city: this.data.individual_city,
      individual_zipcode: this.data.individual_zipcode,
      individual_street_house_number: this.data.individual_street_house_number,
      individual_last_name: this.data.individual_last_name,
      individual_first_name: this.data.individual_first_name,
      individual_form_of_address: this.data.individual_form_of_address,
      individual_email: this.data.individual_email,
    })
  }

  public isMailPreviewDisabled(): boolean {
    // Falls keine E-Mail eingetragen ist für Individuell.
    if (this.data.invoice_receiver === 'other' && !this.data.individual_email) {
      return true
    }

    // Falls keine E-Mail bei der Pflegekasse hinterlegt ist.
    if (
      this.data.invoice_receiver === 'care_insurance' &&
      !this.patient.care_insurance.email
    ) {
      return true
    }

    // Falls keine E-Mail beim direkten Kostenträger hinterlegt ist.
    if (
      this.data.invoice_receiver === 'cost_unit' &&
      this.costUnitHasNoContactPersons &&
      !this.patient.cost_unit.email
    ) {
      return true
    }

    // Falls kein Empfänger bei Kostenträger mit AP ausgewählt ist.
    if (
      this.data.invoice_receiver === 'cost_unit' &&
      !this.data.cost_unit_email_receiver
    ) {
      return true
    }

    // Falls kein Empfänger bei Leistungsnehmer ausgewählt ist.
    if (this.data.invoice_receiver === 'patient' && !this.data.receiver) {
      return true
    }

    return false
  }

  public addressReceiverSelected(): void {
    this.selectedAddress = null
    this.selectedReceiverAddress = ''

    const [type, id] = this.data.receiver.split('_')

    if (type === 'patient') {
      const patient =
        this.receiversData.first_patient.id == id
          ? this.receiversData.first_patient
          : this.receiversData.second_patient

      this.selectedAddress = patient
      this.selectedReceiverAddress = `${patient.form_of_address}<br><strong>${patient.first_name} ${patient.last_name}</strong><br>${patient.street_house_number}<br>${patient.zipcode} ${patient.city}`
    } else {
      const contactPerson = this.receiversData.contact_persons.find(
        (person: any) => {
          return person.id == id
        }
      )

      this.selectedAddress = contactPerson
      this.selectedReceiverAddress = `${contactPerson.form_of_address}<br><strong>${contactPerson.first_name} ${contactPerson.last_name}</strong><br>${contactPerson.street_house_number}<br>${contactPerson.zipcode} ${contactPerson.city}`
    }

    this.getPreviewLink()
  }

  public store(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    this.invoiceService.update(this.data.id, this.data).subscribe(
      (invoice: any) => {
        this.data = invoice

        this.submitted = false
        this.eventbus.emit(GlobalEvent.InvoiceChanged)
        // this.ref.close()
        this.toastService.success(
          'Rechnung gespeichert',
          'Die Rechnung wurde erfolgreich gespeichert'
        )
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }

  public invoiceReceiverChanged(): void {
    this.clickedOnPreviewMail = false
    this.clickedOnPreviewPdf = false

    if (this.data.invoice_receiver !== 'patient') {
      if (this.data.accounting_type === 'SEPA') {
        this.data.accounting_type = ''
      }
    }

    if (this.data.invoice_receiver === 'patient') {
      this.data.patient_receiver = `patient_${this.patient.id}_`
      this.data.receiver = `patient_${this.patient.id}_`

      if (this.data.patient_first_sepa) {
        this.data.accounting_type = 'SEPA'
      } else {
        this.data.accounting_type = 'patient'
      }
    }

    if (this.data.invoice_receiver === 'cost_unit') {
      this.data.accounting_type = 'cost_unit'

      // this.buildCostUnitReceiverOptions()
    }

    if (this.data.invoice_receiver === 'care_insurance') {
      // this.data.sent_type = 'no_sending'
      // this.data.accounting_type = 'accounting_center'
    }

    this.getPreviewLink()
  }

  public patientReceiverSelected(): void {
    this.selectedReceiver = null
    this.selectedPatientAddress = ''

    const [type, id] = this.data.patient_receiver.split('_')

    if (type === 'patient') {
      const patient =
        this.receiversData.first_patient.id == id
          ? this.receiversData.first_patient
          : this.receiversData.second_patient

      this.selectedReceiver = patient
      this.selectedPatientAddress = `${patient.form_of_address}<br><strong>${patient.first_name} ${patient.last_name}</strong><br>${patient.street_house_number}<br>${patient.zipcode} ${patient.city}`
    } else {
      const contactPerson = this.receiversData.contact_persons.find(
        (person: any) => {
          return person.id == id
        }
      )

      this.selectedReceiver = contactPerson
      this.selectedPatientAddress = `${contactPerson.form_of_address}<br><strong>${contactPerson.first_name} ${contactPerson.last_name}</strong><br>${contactPerson.street_house_number}<br>${contactPerson.zipcode} ${contactPerson.city}`
    }

    this.getPreviewLink()
  }

  public costUnitReceiverSelected(): void {
    // this.costUnitEmailReceiverOptions = []
    // this.selectedUnitCostReceiver = null
    //
    // if (!this.data.cost_unit_receiver) {
    //   return
    // }
    //
    // const [type, id] = this.data.cost_unit_receiver.split('_')
    //
    // const costUnit = this.data.patient_cost_unit
    //
    // if (type === 'multi') {
    //   this.selectedCostUnitAddress = `<strong>${costUnit.name}</strong><br>${costUnit.street_house_number}<br>${costUnit.zipcode} ${costUnit.city}`
    //
    //   const isDisabled =
    //     this.data.send_type === 'email' && !costUnit.email
    //
    //   // Wenn der Multi keine E-Mail hat, soll der erste Eintrag nicht bereits ausgewählt werden.
    //   if (isDisabled) {
    //     this.costUnitEmailReceiverOptions.push({
    //       value: '',
    //       label: 'Bitte auswählen',
    //       email: '',
    //       disabled: true,
    //     })
    //   }
    //
    //   this.costUnitEmailReceiverOptions.push({
    //     value: `multi_${costUnit.id}_`,
    //     email: costUnit.email || 'Keine E-Mail',
    //     label: `${costUnit.full_name}`,
    //     disabled: isDisabled,
    //   })
    // } else {
    //   const contactPerson = costUnit.contact_persons.find((person: any) => {
    //     return person.id == id
    //   })
    //
    //   this.selectedUnitCostReceiver = contactPerson
    //
    //   if (contactPerson.email_private) {
    //     this.costUnitEmailReceiverOptions.push({
    //       value: `ap_${contactPerson.id}_private`,
    //       email: `Private E-Mail: ${contactPerson.email_private}`,
    //       label: `${contactPerson.full_name} - AP`,
    //       disabled: false,
    //     })
    //   }
    //
    //   if (contactPerson.email_work) {
    //     this.costUnitEmailReceiverOptions.push({
    //       value: `ap_${contactPerson.id}_work`,
    //       email: `Geschäftliche E-Mail: ${contactPerson.email_work}`,
    //       label: `${contactPerson.full_name} - AP`,
    //       disabled: false,
    //     })
    //   }
    //
    //   // Wenn gar keine E-Mail hinterlegt ist.
    //   if (!contactPerson.email_private && !contactPerson.email_work) {
    //     this.costUnitEmailReceiverOptions.push({
    //       value: '',
    //       label: 'Bitte auswählen',
    //       email: '',
    //       disabled: true,
    //     })
    //
    //     this.costUnitEmailReceiverOptions.push({
    //       value: `ap_${contactPerson.id}_`,
    //       email: 'Keine E-Mail',
    //       label: `${contactPerson.full_name} - AP`,
    //       disabled: this.data.send_type === 'email',
    //     })
    //   }
    //
    //   this.selectedCostUnitAddress = `${costUnit.name}<br><strong>${contactPerson.form_of_address} ${contactPerson.first_name} ${contactPerson.last_name}</strong><br>${contactPerson.street_house_number}<br>${contactPerson.zipcode} ${contactPerson.city}`
    // }
    //
    // this.getPreviewLink()
  }

  public createInvoice(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    // Wenn Individuell ausgewählt wurde, müssen alle Felder ausgefüllt sein.
    // Eine Straße muss nicht zwingend hinterlegt sein.
    if (this.data.invoice_receiver === 'other') {
      if (
        !this.data.individual_zipcode ||
        !this.data.individual_city ||
        !this.data.individual_last_name ||
        !this.data.individual_first_name ||
        !this.data.individual_form_of_address
      ) {
        alert('Bitte alle individuellen Daten eintragen')
        return
      }

      if (this.data.send_type === 'email' && !this.data.individual_email) {
        alert('Bitte individuelle E-Mail eintragen')
        return
      }
    }

    // Prüfen, ob alle Daten bei der Pflegekasse für den Brief hinterlegt sind.
    // Eine Straße muss nicht hinterlegt sein.
    if (this.data.invoice_receiver === 'care_insurance') {
      const careInsurance = this.patient.care_insurance

      if (
        !careInsurance.name ||
        !careInsurance.zipcode ||
        !careInsurance.city
      ) {
        alert('Die Adresse bei der Pflegekasse ist unvollständig')
        return
      }
    }

    if (!this.data.invoice_receiver) {
      alert('Bitte Rechnungsempfänger wählen')
      return
    }

    if (!this.data.accounting_type) {
      alert('Bitte Abrechnungsstelle wählen')
      return
    }

    if (!this.data.sent_type) {
      alert('Bitte Versand wählen')
      return
    }

    if (
      !this.clickedOnPreviewPdf ||
      (this.data.sent_type === 'email' && !this.clickedOnPreviewMail)
    ) {
      alert('Bitte PDF und Mail Vorschau ansehen')

      return
    }

    this.submitted = true

    this.invoiceService.createInvoice(this.data.id, this.data).subscribe(
      (invoice: any) => {
        this.data = invoice.invoice

        this.submitted = false
        this.eventbus.emit(GlobalEvent.InvoiceChanged)
        this.ref.close()
        this.toastService.success(
          'Rechnung erstellt',
          'Die Rechnung wurde erfolgreich erstellt'
        )

        this.openInvoiceViewDialog(this.data)
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }

  private openInvoiceViewDialog(invoice: any): void {
    this.dialogService.open(InvoiceViewDialogComponent, {
      header: 'Rechnung',
      width: '96%',
      height: '90%',
      styleClass: 'dialog-container',
      data: {
        invoice,
      },
    })
  }
}
