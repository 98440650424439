import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { StatusCodes } from 'http-status-codes'
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import * as dayjs from 'dayjs'
import { ConfirmationService } from 'primeng/api'
import { HelperService } from '../../../services/helper.service'
import { CarDamageService } from '../../../services/car-damage.service'
import {
  UserSystemModel,
  UserSystemSimpleModel,
} from '../../../models/user/user-system.model'
import { UserSystemService } from '../../../services/user-system.service'
import { AuthService } from '../../../services/auth.service'
import { CarDamageResponseModel } from '../../../models/car/car-damage.model'
import { DocumentService } from '../../../services/document.service'
import { AddDocumentDialogComponent } from '../add-document-dialog/add-document-dialog.component'
import { MediaModel } from '../../../models/document/media.model'

@Component({
  selector: 'app-add-damage-dialog',
  templateUrl: './add-damage-dialog.component.html',
})
export class AddDamageDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false

  public data: any = {}
  public values: CarDamageResponseModel = new CarDamageResponseModel()
  public caregiverOptions: any[] = []
  public userSystemOptions: any[] = []

  public submitted = false
  public submittedDelete = false

  public guiltOptions = [
    { label: 'Betreuungskraft', value: 'CAREGIVER' },
    { label: 'Unfallgegner', value: 'OPPONENT' },
    { label: 'Sonstiges', value: 'OTHER' },
  ]

  constructor(
    private ref: DynamicDialogRef,
    private authService: AuthService,
    private dialogService: DialogService,
    private config: DynamicDialogConfig,
    private helperService: HelperService,
    private carDamageService: CarDamageService,
    private userSystemService: UserSystemService,
    private eventbus: EventBusService,
    private toastService: ToastService,
    public documentService: DocumentService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.data = this.config.data

    this.helperService.dependencies$.subscribe((data: any) => {
      this.caregiverOptions.push(...data['caregivers_active'])
      this.caregiverOptions.push(...data['caregivers_deactivated'])
    })

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    this.loadAllUserSystem()
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    const subscription = this.data.isEdit
      ? this.carDamageService.update(
          this.data.damage_id,
          this.values.car_damage
        )
      : this.carDamageService.store(this.data.car_id, this.values.car_damage)

    subscription.subscribe(
      () => {
        this.submitted = false
        this.eventbus.emit(GlobalEvent.DamageChanged)
        this.ref.close()
        this.toastService.success(
          'Schaden gespeichert',
          'Der Schaden wurde erfolgreich gespeichert'
        )
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }

  private loadAllUserSystem(): void {
    this.userSystemService
      .listActiveSimple()
      .subscribe((data: UserSystemSimpleModel[]) => {
        for (const userSystem of data) {
          this.userSystemOptions.push({
            id: userSystem.id,
            full_name: userSystem.first_name + ' ' + userSystem.last_name,
          })
        }

        if (this.data.isEdit) {
          this.loadCarDamage()
        } else {
          // Der aktuell eingeloggte Benutzer soll automatisch ausgewählt werden.
          const user = this.authService.getUser()
          this.values.car_damage.tracked_by_id = user?.id
        }
      })
  }

  private loadCarDamage(): void {
    this.carDamageService
      .load(this.data.damage_id)
      .subscribe((response: CarDamageResponseModel) => {
        this.values = response
        this.values.car_damage.date = dayjs(response.car_damage.date).format(
          'DD.MM.YYYY'
        )
      })
  }

  public openAddDocumentDialog(): void {
    const ref = this.dialogService.open(AddDocumentDialogComponent, {
      data: {
        type_id: this.values.car_damage.id,
        car_damage: this.values.car_damage,
        type: 'car_damage',
      },
      header: 'Dokument hochladen',
      styleClass: 'dialog-container',
      dismissableMask: false,
      width: '450px',
    })

    ref.onClose.subscribe((document: any) => {
      if (document) {
        const model = new MediaModel()
        model.name = document.media.name
        model.uuid = document.media.uuid

        this.values.documents.push(model)
      }
    })
  }

  public remove(event: any): void {
    this.confirmationService.confirm({
      target: event.target ?? undefined,
      message: 'Schaden wirklich löschen?',
      accept: () => {
        this.submittedDelete = true

        this.carDamageService.delete(this.data.damage_id).subscribe(
          () => {
            this.submittedDelete = false
            this.eventbus.emit(GlobalEvent.DamageChanged)
            this.ref.close()
            this.toastService.success(
              'Schaden gelöscht',
              'Die Schaden wurde erfolgreich gelöscht'
            )
          },
          () => {
            this.submittedDelete = false
            this.toastService.error(
              'Löschen fehlgeschlagen',
              'Der Eintrag konnte nicht gelöscht werden'
            )
          }
        )
      },
      reject: () => {},
    })
  }
}
