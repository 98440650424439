<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body" *ngIf="!loading">
    <div class="dialog-detail-information-body">
      <div
        class="dialog-detail-information dialog-detail-information-has-actions"
      >
        <div class="dialog-detail-key p-align-center">
          <span style="margin: 0">Status</span>
        </div>
        <div class="dialog-detail-value selectbutton-small">
          <p-selectButton
            [options]="statusOptions"
            [(ngModel)]="todo.status"
            name="status"
            (onChange)="changeStatus()"
          >
            <ng-template let-item>
              <span [style]="{ backgroundColor: item.backgroundColor }">{{
                item.label
              }}</span>
            </ng-template>
          </p-selectButton>
        </div>
      </div>
      <div
        class="dialog-detail-information dialog-detail-information-has-actions"
      >
        <div class="dialog-detail-key"><span>Zugewiesen an</span></div>
        <div class="dialog-detail-value" *ngIf="!editAssignedMode">
          <div
            class="dialog-detail-inline-edit"
            (click)="openEditAssigned()"
            pTooltip="Zuweisung ändern"
          >
            <div>{{ todo.assigned_to_string || "-" }}</div>
            <i style="font-size: 14px" class="pi pi-pencil"></i>
          </div>
        </div>

        <div class="dialog-detail-value" *ngIf="editAssignedMode">
          <div style="width: 150px">
            <p-multiSelect
              selectedItemsLabel="{0} Benutzer ausgewählt"
              inputId="assignments"
              [options]="userSystemOptions"
              optionLabel="full_name"
              name="assignments"
              scrollHeight="300px"
              [filter]="true"
              [(ngModel)]="todo.user_systems"
              appendTo="body"
            ></p-multiSelect>
          </div>

          <div>
            <button
              pButton
              label="Abbrechen"
              class="p-button-text"
              type="button"
              (click)="closeEditAssigned()"
              icon="pi pi-times"
            ></button>

            <button
              pButton
              label="Speichern"
              class="p-button-text"
              type="button"
              (click)="changeAssignments()"
              [disabled]="submittedAssigned"
              [icon]="
                submittedAssigned ? 'pi pi-spin pi-spinner' : 'pi pi-check'
              "
            ></button>
          </div>
        </div>
      </div>
      <div
        class="dialog-detail-information"
        *ngIf="todo.assignment_type === 'PATIENT'"
      >
        <div class="dialog-detail-key"><span>Patient</span></div>
        <div class="dialog-detail-value">
          <div>
            <a href="/patients/{{ todo.patient?.id }}" target="_blank">{{
              data.user_type_name
            }}</a>
          </div>
        </div>
      </div>
      <div
        class="dialog-detail-information"
        *ngIf="todo.assignment_type === 'CAREGIVER'"
      >
        <div class="dialog-detail-key"><span>Betreuungskraft</span></div>
        <div class="dialog-detail-value">
          <div>
            <a href="/caregivers/{{ todo.caregiver?.id }}" target="_blank">{{
              data.user_type_name
            }}</a>
          </div>
        </div>
      </div>
      <div class="dialog-detail-information">
        <div class="dialog-detail-key"><span>Priorität</span></div>
        <div
          class="dialog-detail-value"
          style="align-items: center; justify-content: space-between"
        >
          <div [class.color-alert]="todo.priority === 1">
            {{ todo.priority_string }}
          </div>

          <!--          (change)="appointment.monthly_type = 'WEEK_DAY'"-->
          <!--          [(ngModel)]="appointment.monthly_week"-->
          <div class="has-small-input" *ngIf="isAssignedToMe">
            Eigene Priorität

            <p-dropdown
              (onChange)="changeMyPriority()"
              inputId="own_priority"
              name="own_priority"
              [(ngModel)]="myPriority"
              [options]="priorityOptions"
              appendTo="body"
            ></p-dropdown>
          </div>
        </div>
      </div>
      <div class="dialog-detail-information">
        <div class="dialog-detail-key"><span>Zu erledigen</span></div>
        <div class="dialog-detail-value">
          <div>
            {{ todo.for_when_type === "FINISH_AT" ? "am" : "bis" }}
            {{ todo.for_when_string }}
          </div>
        </div>
      </div>
      <div class="dialog-detail-information">
        <div class="dialog-detail-key"><span>Erstellt von</span></div>
        <div class="dialog-detail-value">
          <div>
            {{ todo.user?.user_system?.full_name || "??" }} am
            {{ todo.created_at | date: "dd.MM.y, H:mm" }}
          </div>
        </div>
      </div>
      <div class="dialog-detail-information">
        <div class="dialog-detail-key"><span>Titel</span></div>
        <div class="dialog-detail-value">
          <strong>{{ todo.title }}</strong>
        </div>
      </div>
      <div class="dialog-detail-information">
        <div class="dialog-detail-key"><span>Kategorie</span></div>
        <div class="dialog-detail-value">
          <div>
            {{ todo.category?.category || "-" }}
          </div>
        </div>
      </div>
      <div class="dialog-detail-information">
        <div class="dialog-detail-key"><span>Dokumente</span></div>
        <div class="dialog-detail-value">
          <div style="display: flex; gap: 10px">
            <span *ngIf="todo.media.length === 0">-</span>
            <a
              class="color-gray"
              *ngFor="let document of todo.media"
              [href]="documentService.getDocumentDownloadLink(document.uuid)"
              target="_blank"
            >{{ document.name }} <i class="pi pi-arrow-up-right" style="font-size: 11px"></i></a>
          </div>
        </div>
      </div>
      <div class="dialog-detail-information">
        <div class="dialog-detail-key"><span>Bemerkung</span></div>
        <div class="dialog-detail-value">
          <div style="white-space: pre-wrap">{{ todo.comment || "-" }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-form-body without-padding" *ngIf="!loading">
    <div class="dialog-comments">
      <div
        class="dialog-comment dialog-no-comment"
        *ngIf="!todo.comments || todo.comments.length === 0"
      >
        Noch keine Kommentare
      </div>
      <div class="dialog-comment" *ngFor="let comment of todo.comments">
        <!-- Den Kommentar darf nur der Ersteller bearbeiten bzw. ein Super Admin -->
        <i
          *ngIf="
            currentUser?.id === comment.user_id || authService.isSuperAdmin
          "
          class="pi pi-pencil"
          pTooltip="Bearbeiten"
          (click)="openEditMode(comment)"
        ></i>
        <strong>{{ comment.comment }}</strong>
        <span
          >{{ comment.created_from.user_system?.full_name }},
          {{ comment.created_at | date: "dd.MM.y, H:mm" }}</span
        >
      </div>
    </div>
  </div>
  <div class="dialog-form-footer dialog-form-footer-textarea" *ngIf="!loading">
    <textarea
      *ngIf="!editComment"
      id="comment"
      name="comment"
      [(ngModel)]="values.comment"
      [rows]="2"
      placeholder="Kommentar..."
      [autofocus]="true"
      pInputTextarea
    ></textarea>

    <textarea
      *ngIf="editComment"
      id="comment_edit"
      name="comment_edit"
      [(ngModel)]="values.comment"
      [rows]="2"
      pInputTextarea
    ></textarea>

    <div class="dialog-form-footer-actions">
      <i
        *ngIf="!editComment"
        class="pi pi-pencil"
        (click)="openTodoDialog()"
        pTooltip="Todo bearbeiten"
      ></i>

      <button
        *ngIf="editComment"
        pButton
        label="Abbrechen"
        class="p-button-text button-without-padding"
        type="button"
        (click)="cancelEditMode()"
        icon="pi pi-times"
      ></button>

      <button
        *ngIf="!editComment"
        (click)="save()"
        pButton
        label="Kommentar erstellen"
        type="button"
        [disabled]="submitted || !values.comment"
        [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
      ></button>

      <button
        *ngIf="editComment"
        (click)="update()"
        pButton
        label="Kommentar bearbeiten"
        type="button"
        [disabled]="submitted || !values.comment"
        [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
      ></button>
    </div>
  </div>
</form>
