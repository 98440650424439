<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <span>Alle aktiven Betreuungskräfte</span>

    <div class="content-header-buttons">
      <a routerLink="/caregivers/new">
        <button
          pButton
          label="Neue Betreuungskraft"
          type="button"
          icon="pi pi-plus"
          iconPos="left"
          class="p-button-outlined"
        ></button>
      </a>

      <div
        class="table-search-container"
        [class.searchfield-active]="globalFilterValue != ''"
      >
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            (input)="filterTableGlobal($event)"
            placeholder="Suche..."
            [(ngModel)]="globalFilterValue"
          />
        </div>
      </div>
    </div>
  </header>
</div>

<div class="content" style="margin-bottom: 0" *ngIf="!loading">
  <div class="content-body content-body-full-height-with-tabs without-padding">
    <p-table
      #dt
      [value]="caregivers"
      [globalFilterFields]="[
        'id',
        'first_name',
        'last_name',
        'full_name',
        'zipcode',
        'city',
        'current_job_scope.job_scope',
        'current_car.car.full_name'
      ]"
      [(first)]="firstPage"
      [rowHover]="true"
      stateStorage="local"
      stateKey="state-caregivers-list-active-2"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{totalRecords} Betreuungskräfte"
      [rows]="50"
      [paginator]="true"
      scrollHeight="100%"
      [scrollable]="true"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th style="max-width: 70px" pSortableColumn="temporarily_new">
            <i
              style="margin: 0"
              pTooltip="Temporär aktiv (bis 4 Wochen nach Arbeitsbeginn)"
              class="light-symbol light-symbol-white"
            ></i>
            <p-sortIcon field="temporarily_new"></p-sortIcon>
          </th>
          <th style="max-width: 120px" pSortableColumn="id">
            ID <p-sortIcon field="id"></p-sortIcon>
          </th>
          <th style="min-width: 300px" pSortableColumn="last_name">
            Name <p-sortIcon field="last_name"></p-sortIcon>
          </th>
          <th pSortableColumn="car.full_name">
            PKW <p-sortIcon field="car.full_name"></p-sortIcon>
          </th>
          <th pSortableColumn="city">
            Wohnort <p-sortIcon field="city"></p-sortIcon>
          </th>
          <th>Telefon</th>
          <th
            style="width: 100px"
            pSortableColumn="current_job_scope.job_scope"
          >
            Stunden
            <p-sortIcon field="current_job_scope.job_scope"></p-sortIcon>
          </th>
          <th pSortableColumn="current_employment.employed_since_date">
            Angestellt seit
            <p-sortIcon
              field="current_employment.employed_since_date"
            ></p-sortIcon>
          </th>
          <th style="width: 100px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-caregiver>
        <tr>
          <td style="max-width: 70px">
            <i
              [pTooltip]="
                'Arbeitsbeginn am ' +
                caregiver.current_employment.employed_since_date_string
              "
              *ngIf="caregiver.current_employment && caregiver.temporarily_new"
              class="light-symbol light-symbol-blue"
            ></i>
          </td>
          <td style="max-width: 120px">
            {{ caregiver.id }}
          </td>
          <td style="min-width: 300px">
            <a class="color-main1" routerLink="/caregivers/{{ caregiver.id }}">
              {{ caregiver.last_first_name }}
            </a>
          </td>

          <td *ngIf="caregiver.current_car">
            <div style="display: flex; flex-direction: column">
              <a routerLink="/cars/{{ caregiver.current_car.id }}">
                {{ caregiver.current_car.car.brand }}
                {{ caregiver.current_car.car.model }}
              </a>
              <small>{{ caregiver.current_car.car.license_plate }}</small>
            </div>
          </td>
          <td *ngIf="!caregiver.current_car">-</td>

          <td>{{ caregiver.city }}</td>
          <td>
            <div style="display: flex; flex-direction: column">
              <span *ngIf="caregiver.phone_1">
                <a
                  (click)="phoneCallService.createCalling(caregiver.phone_1)"
                  href="tel:{{ caregiver.phone_1 }}"
                  >{{ caregiver.phone_1 }}</a
                >
                <i
                  style="margin: 0 0 0 5px; font-size: 13px"
                  class="pi pi-info-circle color-gray"
                  *ngIf="caregiver.phone_1_info"
                  [pTooltip]="caregiver.phone_1_info"
                ></i>
                <br />
              </span>
              <span *ngIf="caregiver.phone_2">
                <a
                  (click)="phoneCallService.createCalling(caregiver.phone_2)"
                  href="tel:{{ caregiver.phone_2 }}"
                  >{{ caregiver.phone_2 }}</a
                >
                <i
                  style="margin: 0 0 0 5px; font-size: 13px"
                  class="pi pi-info-circle color-gray"
                  *ngIf="caregiver.phone_2_info"
                  [pTooltip]="caregiver.phone_2_info"
                ></i>
                <br />
              </span>
              <span *ngIf="caregiver.phone_3">
                <a
                  (click)="phoneCallService.createCalling(caregiver.phone_3)"
                  href="tel:{{ caregiver.phone_3 }}"
                  >{{ caregiver.phone_3 }}</a
                >
                <i
                  style="margin: 0 0 0 5px; font-size: 13px"
                  class="pi pi-info-circle color-gray"
                  *ngIf="caregiver.phone_3_info"
                  [pTooltip]="caregiver.phone_3_info"
                ></i>
              </span>
            </div>
          </td>

          <!-- Stunden -->
          <td *ngIf="caregiver.current_job_scope">
            {{ caregiver.current_job_scope.job_scope }}
          </td>
          <td *ngIf="!caregiver.current_job_scope && caregiver.first_job_scope">
            {{ caregiver.first_job_scope.job_scope }}
          </td>
          <td
            *ngIf="!caregiver.current_job_scope && !caregiver.first_job_scope"
          >
            -
          </td>

          <td
            pTooltip="Beginn möglich am"
            *ngIf="caregiver.current_status.status !== 'EMPLOYMENT'"
          >
            {{
              caregiver.employment_possible_at_string
                ? "(" + caregiver.employment_possible_at_string + ")"
                : "-"
            }}
          </td>
          <td
            *ngIf="
              caregiver.current_employment &&
              caregiver.current_status.status === 'EMPLOYMENT'
            "
          >
            {{
              caregiver.current_employment?.employed_since_date
                | date: "dd.MM.y"
            }}
            {{
              caregiver.current_employment?.employed_until_date
                ? " - " +
                  (caregiver.current_employment?.employed_until_date
                    | date: "dd.MM.y")
                : ""
            }}
          </td>

          <td class="table-action" style="width: 100px">
            <a routerLink="/caregivers/{{ caregiver.id }}/edit">
              <i
                class="pi pi-pencil"
                [class.with-margin-right]="isSuperAdmin"
                pTooltip="Bearbeiten"
                tooltipPosition="left"
              ></i>
            </a>

            <i
              class="pi pi-lock"
              *ngIf="isSuperAdmin && caregiver.user_id"
              (click)="loginUsingId(caregiver.user_id)"
              pTooltip="Als Benutzer einloggen"
              tooltipPosition="left"
            ></i>
          </td>

          <!--          <td style="width: 120px">-->
          <!--            <i-->
          <!--              class="pi pi-check color-alert"-->
          <!--              [pTooltip]="caregiver.smoking"-->
          <!--              *ngIf="caregiver.smoking != 'Nein'"-->
          <!--            ></i>-->
          <!--          </td>-->

          <!--          <td style="width: 120px">-->
          <!--            <i-->
          <!--              class="pi pi-check color-main1"-->
          <!--              *ngIf="caregiver.education_assistant"-->
          <!--            ></i>-->
          <!--          </td>-->

          <!--          <td style="width: 120px">-->
          <!--            <i-->
          <!--              class="pi pi-check color-main1"-->
          <!--              *ngIf="caregiver.education_dementia"-->
          <!--            ></i>-->
          <!--          </td>-->
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
