import { Component, OnDestroy, OnInit } from '@angular/core'
import { CaregiverService } from '../../../../services/caregivers.service'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import {
  CaregiverDetailDocumentsModel,
  CaregiverDocumentTypesModel,
} from '../../../../models/caregiver/caregiver-detail-documents.model'
import { ToastService } from '../../../../services/toast.service'
import { environment } from '../../../../../environments/environment'
import { DialogService } from 'primeng/dynamicdialog'
import {
  EventBusService,
  GlobalEvent,
} from '../../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { AddDocumentDialogComponent } from '../../../../components/dialogs/add-document-dialog/add-document-dialog.component'
import { DocumentModel } from '../../../../models/document/document.model'
import { MediaModel } from '../../../../models/document/media.model'
import { DocumentService } from '../../../../services/document.service'

@Component({
  selector: 'app-caregivers-detail-documents',
  templateUrl: './caregivers-detail-documents.component.html',
})
export class CaregiversDetailDocumentsComponent implements OnInit, OnDestroy {
  public loading = true
  public initialDocumentsSubmitted = false
  public showInitialDocumentDownload = false

  private eventBusSubscription: Subscription = new Subscription()
  public documents: CaregiverDetailDocumentsModel = new CaregiverDetailDocumentsModel()
  public documentTypes: DocumentModel[] = []

  private routeSubscription: any = null
  public onlyArchived = false

  private caregiverId: string | null = null

  constructor(
    public caregiverService: CaregiverService,
    private toastService: ToastService,
    private eventbus: EventBusService,
    private documentService: DocumentService,
    private dialogService: DialogService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (this.route.parent) {
      this.routeSubscription = this.route.parent.paramMap.subscribe(
        (params: ParamMap) => {
          this.caregiverId = params.get('id')

          this.loadDocuments()
        }
      )
    }

    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
    this.routeSubscription && this.routeSubscription.unsubscribe()
  }

  public getInitialDocumentsDownloadLink(): string {
    return `${environment.api}/documents/caregivers/${this.caregiverId}/download-init`
  }

  public createInitialDocuments(): void {
    this.initialDocumentsSubmitted = true

    this.caregiverService.initDocuments(this.caregiverId).subscribe(
      () => {
        this.initialDocumentsSubmitted = false
        this.showInitialDocumentDownload = true
      },
      () => {
        this.toastService.stickyError(
          'Initiale Erstellung fehlgeschlagen',
          'Bitte wenden Sie sich an den Support'
        )
        this.initialDocumentsSubmitted = false
      }
    )
  }

  public openAddDocumentDialog(document = null, documentType = ''): void {
    const ref = this.dialogService.open(AddDocumentDialogComponent, {
      data: {
        type_id: this.caregiverId,
        type: 'caregivers',
        document_type: documentType,
        document,
      },
      header: document ? 'Dokument bearbeiten' : 'Dokument erstellen',
      styleClass: 'dialog-container',
      dismissableMask: false,
      width: '450px',
    })

    ref.onClose.subscribe((file: any) => {
      // Wenn ein Dokument generiert wurde (und nicht manuell hochgeladen),
      // dann wird der Download für das generierte Dokument automatisch angestoßen.
      if (
        file?.media &&
        !file.media.custom_properties?.upload &&
        !Array.isArray(file.media)
      ) {
        this.openDownloadTab(file.media)
      }
    })
  }

  public openDownloadTab(document: MediaModel): void {
    window.open(this.caregiverService.getDocumentDownloadLink(document.uuid))
  }

  public downloadInitialDocuments(): void {
    this.loadDocuments()
  }

  /**
   * Hier reagieren wir auf Events im EventBusService, wie z.B. das Event,
   * dass die PhoneCall-Liste die Daten neu laden soll (wenn z.B. ein Telefonat
   * gelöscht oder bearbeitet wurde).
   */
  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.DocumentListReload:
            this.loadDocuments(false)
        }
      }
    )
  }

  public loadDocuments(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    this.caregiverService
      .documents(this.caregiverId, this.onlyArchived)
      .subscribe((data: CaregiverDetailDocumentsModel) => {
        this.documents = data
        this.documentTypes = Object.values(data.types)

        if (withLoading) {
          this.loading = false
        }
      })
  }
}
