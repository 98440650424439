<div class="content-actions">
  <h2>
    <i class="tio">chart_donut_2</i>
    Auswertungen
  </h2>
</div>

<header class="content-header-tabs">
  <a
    *ngIf="authService.can('Auswertungen.Zugriff auf globale Auswertungen')"
    routerLink="/analyses"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    Betreuungskräfte
  </a>
  <a
    *ngIf="authService.isSuperAdmin()"
    routerLink="/analyses/progress"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    Verlauf
  </a>
  <a
    *ngIf="authService.isSuperAdmin()"
    routerLink="/analyses/plannings"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    Planung
  </a>
  <a
    *ngIf="authService.isSuperAdmin()"
    routerLink="/analyses/recommendations"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    Empfehlungen
  </a>
  <a
    *ngIf="authService.isSuperAdmin()"
    routerLink="/analyses/phone-calls"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    Telefonate
  </a>
  <a
    routerLink="/analyses/budgets"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    Budgets
  </a>
</header>

<router-outlet></router-outlet>
