import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { NgForm } from '@angular/forms'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { Subscription } from 'rxjs'
import { PhoneCallModel } from 'src/app/models/phone-call/phone-call.model'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { PhoneCallService } from 'src/app/services/phone-call.service'
import { ToastService } from 'src/app/services/toast.service'
import { HelperService } from '../../../services/helper.service'
import * as dayjs from 'dayjs'
import { UserSystemSimpleModel } from '../../../models/user/user-system.model'
import { UserSystemService } from '../../../services/user-system.service'
import {AuthService} from "../../../services/auth.service";

@Component({
  templateUrl: './phone-call-edit-dialog.component.html',
})
export class PhoneCallEditDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  public attentionFrom = []

  public userSystemOptions: any[] = []
  private formSubscription: Subscription | null | undefined = null
  public phonecall: PhoneCallModel = new PhoneCallModel()
  public submitted = false
  public canEdit = false
  public submittedDelete = false
  public isDirty = false

  public priorityOptions = [
    { label: 'Hoch', value: 1 },
    { label: 'Normal', value: 2 },
    { label: 'Niedrig', value: 3 },
  ]

  public todoCategories = []

  public todo = {
    title: '',
    category_id: null,
    is_possible: false,
    create: false,
    priority: 2,
    for_when: null,
    for_when_type: 'FINISH_UNTIL',
    for_when_string: dayjs().format('DD.MM.YYYY'),
    user_systems: [],
  }

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private authService: AuthService,
    private userSystemService: UserSystemService,
    private phoneCallService: PhoneCallService,
    private toastService: ToastService,
    private eventbus: EventBusService,
    private helperService: HelperService
  ) {}

  ngOnInit(): void {
    this.phonecall = this.config.data

    this.setCanEdit()

    this.helperService.dependencies$.subscribe((data: any) => {
      this.todoCategories = data['todo_categories']
      this.attentionFrom = data['attention_from']
    })

    if (!this.phonecall.phone_caller_type) {
      this.phonecall.phone_caller_type = 'other'
    }

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      // sonst wird isDirty durch das initiale rendern der form gesetzt
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    // To-Dos können nur für Patienten oder BKs erstellt werden.
    if (['customer', 'caregiver'].includes(this.phonecall.phone_caller_type)) {
      this.loadAllUserSystem()
      this.todo.is_possible = true
    }
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  // Die History darf nur der Ersteller oder Super-Admin bearbeiten.
  private setCanEdit(): void {
    const user = this.authService.getUser()

    this.canEdit = this.authService.isSuperAdmin() || this.phonecall.user_id === user?.id
  }

  /**
   * Speichert das Bearbeiten des Telefonats.
   */
  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    // Wenn "Sonstiges" ausgewählt ist, darf kein "Wichtiges Telefonat" angehakt sein,
    // da er sonst nur "OTHER" in die histories Tabelle für die Morph Spalte reinschreibt
    // und er dann einen Fehler wirft, da es die Klasse "OTHER" nicht gibt.
    if (this.phonecall.phone_caller_type === 'other') {
      this.phonecall.history.important = false
    }

    this.submitted = true

    this.phoneCallService.update(this.phonecall, this.todo).subscribe(
      () => {
        this.submitted = false
        this.eventbus.emit(GlobalEvent.PhoneCallListReload)
        this.ref.close()
        this.toastService.success(
          'Telefonat bearbeitet',
          'Das Telefonat wurde erfolgreich bearbeitet'
        )
      },
      () => {
        this.toastService.error(
          'Etwas ist schief gelaufen...',
          'Bitte wenden Sie sich an den Support'
        )

        this.submitted = false
      }
    )
  }

  private loadAllUserSystem(): void {
    this.userSystemService
      .listActiveSimple()
      .subscribe((data: UserSystemSimpleModel[]) => {
        for (const userSystem of data) {
          this.userSystemOptions.push({
            id: userSystem.id,
            full_name: userSystem.first_name + ' ' + userSystem.last_name,
          })
        }
      })
  }
}
