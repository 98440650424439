<div *ngIf="customerDetail.notice" class="notice-wrap">
  <i class="pi pi-paperclip notice-wrap-drag-handle notice-icon"></i>
  <div
    [class.show-more]="showMoreNotice"
    (click)="openNoticeDialog()"
    class="notice-text"
  >
    {{ customerDetail.notice }}
  </div>
  <span
    (click)="showMoreNotice = !showMoreNotice"
    [pTooltip]="showMoreNotice ? 'Weniger anzeigen' : 'Mehr anzeigen'"
    tooltipPosition="bottom"
    class="notice-action"
    ><i
      class="pi"
      [class.pi-chevron-down]="!showMoreNotice"
      [class.pi-chevron-up]="showMoreNotice"
    ></i
  ></span>
</div>

<div
  *ngIf="customerDetail.notice_accounting"
  class="notice-wrap budget-notice-wrap"
>
  <i class="pi pi-paperclip notice-wrap-drag-handle notice-icon"></i>
  <div
    [class.show-more]="showMoreNotice"
    (click)="openNoticeAccountingDialog()"
    class="notice-text"
  >
    {{ customerDetail.notice_accounting }}
  </div>
  <span
    (click)="showMoreNotice = !showMoreNotice"
    [pTooltip]="showMoreNotice ? 'Weniger anzeigen' : 'Mehr anzeigen'"
    tooltipPosition="bottom"
    class="notice-action"
    ><i
      class="pi"
      [class.pi-chevron-down]="!showMoreNotice"
      [class.pi-chevron-up]="showMoreNotice"
    ></i
  ></span>
</div>

<div class="content-actions">
  <h2>
    {{ customerDetail.first_patient.form_of_address }}
    {{ customerDetail.first_patient.first_name }}
    {{ customerDetail.first_patient.last_name }}

    <span *ngIf="customerDetail.first_patient.id"
      >#{{ customerDetail.first_patient.id }}</span
    >

    <i
      *ngIf="customerDetail.first_patient.status"
      class="p-tag p-tag-rounded p-tag-{{
        customerDetail.first_patient.status
      }}"
      >{{ customerDetail.first_patient.status_string }}</i
    >
    <i
      *ngIf="customerDetail.first_patient.is_paused"
      class="p-tag p-tag-rounded p-tag-PAUSED"
      >Pausiert</i
    >

    <i
      *ngIf="customerDetail.consulting_assignment"
      class="p-tag p-tag-rounded p-tag-outline"
      >Beratungseinsatz</i
    >

    <i
      *ngIf="customerDetail.offer_type === 'CALL'"
      class="p-tag p-tag-rounded p-tag-outline"
      >NK-Call</i
    >

    <i
      *ngIf="customerDetail.created_from_multiplier"
      class="p-tag p-tag-rounded p-tag-outline"
      >Von Multiplikator</i
    >

    <i
      *ngIf="customerDetail.created_from_caregiver"
      class="p-tag p-tag-rounded p-tag-outline"
      >Von Betreuungskraft</i
    >
  </h2>

  <a *ngIf="customerDetail.id" routerLink="/offer/{{ customerDetail.id }}">
    <button
      pButton
      label="Bearbeiten"
      class="p-button-text"
      type="button"
      icon="pi pi-pencil"
      iconPos="right"
    ></button>
  </a>
</div>

<header class="content-header-tabs" [class.disabled]="!customerDetail.id">
  <a
    routerLink="/patients/{{ customerDetail.first_patient.id }}"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
    >Allgemeines</a
  >
  <a
    *ngIf="customerDetail.first_patient.status"
    routerLink="/patients/{{ customerDetail.first_patient.id }}/budget"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
    >Budget</a
  >
  <a
    *ngIf="customerDetail.first_patient.status"
    routerLink="/patients/{{ customerDetail.first_patient.id }}/planning"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
    >Planung</a
  >
  <a
    routerLink="/patients/{{ customerDetail.first_patient.id }}/consulting"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
    >Beratungseinsatz</a
  >
  <a
    routerLink="/patients/{{ customerDetail.first_patient.id }}/documents"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
    >Dokumente
    <i
      *ngIf="
        !customerDetail.first_patient.initial_documents_generated ||
        (customerDetail.second_patient &&
          !customerDetail.second_patient.initial_documents_generated)
      "
      class="p-tag p-tag-rounded"
      >Neu</i
    >
  </a>
  <a
    *ngIf="customerDetail.first_patient.status"
    routerLink="/patients/{{ customerDetail.first_patient.id }}/veto"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
    >Veto</a
  >
  <a
    routerLink="/patients/{{ customerDetail.first_patient.id }}/accounting"
    routerLinkActive="active"
    >Fibu</a
  >
  <a
    routerLink="/patients/{{ customerDetail.first_patient.id }}/history"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
    >Historie</a
  >
</header>

<router-outlet></router-outlet>
