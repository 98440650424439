<app-loader [loading]="loading"></app-loader>

<div
  class="content new-content-container"
  style="margin-bottom: 0"
  *ngIf="!loading"
>
  <header class="content-header" *ngIf="data.length > 0">
    <span>Rechnungsentwürfe</span>

    <div class="content-header-buttons">
      <div
        class="table-search-container"
        [class.searchfield-active]="globalFilterValue != ''"
      >
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            (input)="filterTableGlobal($event)"
            placeholder="Suche..."
            [(ngModel)]="globalFilterValue"
          />
        </div>
      </div>
    </div>
  </header>

  <div
    class="content-body"
    [class.content-loading]="contentLoading"
    *ngIf="data.length == 0"
  >
    <span class="content-no-message">Keine Entwürfe vorhanden</span>
  </div>

  <div
    [class.content-loading]="contentLoading"
    class="content-body content-body-full-height-with-subtabs without-padding"
    *ngIf="data.length > 0"
  >
    <p-table
      dataKey="key"
      #dt
      scrollHeight="100%"
      [scrollable]="true"
      [value]="data"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{totalRecords} Entwürfe"
      [globalFilterFields]="[
        'patient_id',
        'patient_first_name',
        'patient_last_name',
        'patient_full_name',
        'patient_zipcode',
        'patient_city',
        'persplan_budgets.persplan_function',
        'proof_status.cover_key',
      ]"
      [rowHover]="true"
      [paginator]="true"
      [rows]="50"
      [(first)]="firstPage"
      stateStorage="local"
      stateKey="invoices-appointments-drafts"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th pSortableColumn="patient_last_name">
            Patient <p-sortIcon field="patient_last_name"></p-sortIcon>
          </th>
          <th pSortableColumn="proof_status.month_sort">
            Leistungsmonat
            <p-sortIcon field="proof_status.month_sort"></p-sortIcon>
          </th>
          <th pSortableColumn="persplan_budgets.persplan_function">
            Leistungsart
            <p-sortIcon field="persplan_budgets.persplan_function"></p-sortIcon>
          </th>
          <th pSortableColumn="proof_status.accounting_type">
            Abrechnungsstelle
            <p-sortIcon field="proof_status.accounting_type"></p-sortIcon>
          </th>
          <th pSortableColumn="proof_status.invoice_receiver">
            Rechnungsempfänger
            <p-sortIcon field="proof_status.invoice_receiver"></p-sortIcon>
          </th>
          <th pSortableColumn="costs_in_euro_no_format">
            Gesamtkosten
            <p-sortIcon field="costs_in_euro_no_format"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item let-expanded="expanded">
        <tr
          class="clickable"
          [class.background-hightlight-orange]="
            item.proof_status?.opened_reworkings_count > 0
          "
        >
          <td (click)="openProofCheckDialog(item)">
            <a class="color-main1" routerLink="/patients/{{ item.patient_id }}"
              >{{ item.patient_last_name }}, {{ item.patient_first_name }}</a
            >
          </td>
          <td (click)="openProofCheckDialog(item)">
            {{ item.proof_status?.month }} {{ item.proof_status?.year }}
          </td>
          <td (click)="openProofCheckDialog(item)">
            {{ item.persplan_budgets?.persplan_function || "??" }}
          </td>
          <td (click)="openProofCheckDialog(item)">
            {{ item.proof_status?.accounting_type_string || "??" }}
          </td>
          <td (click)="openProofCheckDialog(item)">
            {{ item.proof_status?.invoice_receiver_string || "??" }}
          </td>
          <td (click)="openProofCheckDialog(item)">
            {{ item.costs_in_euro }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
