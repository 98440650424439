import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class PlanningService {
  constructor(private http: HttpClient) {}

  public loadPatientPlanning(
    id: string | null,
    month: string,
    year: string
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.api}/plannings/patient/${id}?month=${month}&year=${year}`
    )
  }

  public loadChangesRequired(filters: any): Observable<any> {
    return this.http.get<any>(`${environment.api}/plannings/changes-required`,
      {
        params: {
          ...filters,
        }
      })
  }

  public checkBudgetsForIntervalAppointments(appointment: any, intervalAppointments: any): Observable<any> {
    return this.http.post<any>(`${environment.api}/plannings/check-budgets-for-intervall-appointments`, {
      appointment,
      intervalAppointments
    })
  }

  public loadAppointmentHistories(id: string | null): Observable<any> {
    return this.http.get<any>(
      `${environment.api}/plannings/appointment/${id}/histories`
    )
  }

  public loadAppointment(id: string | null): Observable<any> {
    return this.http.get<any>(
      `${environment.api}/plannings/appointment/${id}`
    )
  }

  public loadAllHolidays(): Observable<any> {
    return this.http.get<any>(`${environment.api}/plannings/holidays`)
  }

  public selectCaregiver(
    caregiverId: string | null,
    patientId: string | null,
    month: string,
    year: string
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.api}/plannings/select-caregiver/${caregiverId}?month=${month}&year=${year}&patient_id=${patientId}`
    )
  }

  public loadPatientBudgets(
    patientId: string | null,
    date: string
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.api}/plannings/patient-budgets/${patientId}`,
      {
        params: {
          date
        }
      }
    )
  }

  public loadLastAppointmentsForPatient(
    patientId: string | null,
    date: string
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.api}/plannings/last-appointments?patient_id=${patientId}&date=${date}`
    )
  }

  public checkForAppointmentPlausibility(
    data: any,
    appointmentId: any
  ): Observable<{ plausible: boolean; message: string }[]> {
    return this.http.post<{ plausible: boolean; message: string }[]>(
      `${environment.api}/plannings/appointment-plausibility`,
      {
        ...data,
        appointmentId,
      }
    )
  }

  public checkIntervalAppointments(
    data: any
  ): Observable<{ plausible: boolean; message: string }[]> {
    return this.http.post<{ plausible: boolean; message: string }[]>(
      `${environment.api}/plannings/check-interval-appointments`,
      {
        ...data,
      }
    )
  }

  public deleteAppointment(id: number | string, values: any): Observable<[]> {
    return this.http.post<[]>(
      `${environment.api}/plannings/appointment/delete/${id}`,
      {
        values,
      }
    )
  }

  public changeAppointment(id: number, data: any): Observable<any> {
    return this.http.put<any>(
      `${environment.api}/plannings/appointment/${id}`,
      { ...data }
    )
  }

  public removeFromBudgetList(id: any, data: any): Observable<any> {
    return this.http.put<any>(
      `${environment.api}/plannings/remove-from-list/${id}`,
      { ...data }
    )
  }

  public addAppointment(data: any): Observable<any> {
    return this.http.post<any>(`${environment.api}/plannings/appointment`, {
      ...data,
    })
  }

  public addIntervalAppointments(
    data: any,
    appointments: any
  ): Observable<any> {
    return this.http.post<any>(
      `${environment.api}/plannings/interval-appointments`,
      {
        data,
        appointments,
      }
    )
  }

  public loadPatientsAppointmentsSinceDate(
    patientIds: any,
    from: string,
    to: any = null
  ): Observable<string[]> {
    return this.http.get<string[]>(
      `${environment.api}/plannings/patient/appointments-since-date`,
      {
        params: {
          patientIds: JSON.stringify(patientIds),
          from,
          to,
        },
      }
    )
  }

  public loadPatientsAppointmentsSinceToday(
    patientIds: any
  ): Observable<string[]> {
    return this.http.get<string[]>(
      `${environment.api}/plannings/patient/appointments-since-today`,
      {
        params: {
          patientIds: JSON.stringify(patientIds),
        },
      }
    )
  }

  public vacations(filters: any): Observable<any> {
    return this.http.get<any>(`${environment.api}/plannings/vacations`, {
      params: {
        start: filters.start,
        end: filters.end,
        caregiverIds: JSON.stringify(filters.caregivers),
      },
    })
  }

  public overviewChecks(filters: any): Observable<any> {
    return this.http.get<any>(`${environment.api}/plannings/overview-checks`, {
      params: {
        start: filters.start,
        caregiverIds: JSON.stringify(filters.caregivers),
      },
    })
  }

  public releaseAppointmentFromAdmin(values: any): Observable<any> {
    return this.http.post<any>(
      `${environment.api}/plannings/release-appointment-from-admin/${values.id}`,
      {
        ...values
      }
    )
  }
}
