import { Component, OnInit } from '@angular/core'
import { TitleService } from 'src/app/services/title.service'

@Component({
  selector: 'app-plannings',
  templateUrl: './plannings.component.html',
})
export class PlanningsComponent implements OnInit {
  constructor(private titleService: TitleService) {}

  ngOnInit(): void {
    this.titleService.setTitle(`Planung`)
  }
}
