<span
  *ngIf="user?.is_user_system"
  (click)="toggleNav()"
  class="nav-switch"
  [pTooltip]="navHidden ? 'Ausklappen' : 'Einklappen'"
  ><i
    class="pi"
    [class.pi-chevron-left]="!navHidden"
    [class.pi-chevron-right]="navHidden"
  ></i
></span>

<!-- Betreuungskraft -->
<nav *ngIf="user?.is_caregiver">
  <i class="pi pi-times" (click)="toggleMobileNav()"></i>

  <div class="nav-items">
    <a
      routerLink="/neuer-kunde"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <i class="tio">file_add_outlined</i>
      <span>Neuer Kunde</span>
    </a>

    <a
      routerLink="/dienstplan"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <i class="tio">time</i>
      <span>Dienstplan</span>
    </a>
    <a routerLink="/daten" routerLinkActive="active">
      <i class="tio">explore_outlined</i>
      <span>Meine Daten</span>
    </a>
    <a
      routerLink="/kalender"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <i class="tio">calendar_month</i>
      <span>Kalender</span>
    </a>
    <a
      routerLink="/auswertungen"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <i class="tio">chart_donut_2</i>
      <span>Auswertungen</span>
    </a>
    <a
      routerLink="/leistungsnachweise"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <i class="tio">heart_outlined</i>
      <span>Leistungsnachweise</span>
    </a>
    <a
      routerLink="/dokumente"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <i class="tio">document_text_outlined</i>
      <span>Dokumente</span>
    </a>
    <a
      routerLink="/empfehlungen"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <i class="tio">group_equal</i>
      <span>Empfehlungen</span>
    </a>
    <a
      *ngIf="user?.caregiver?.is_mentor"
      routerLink="/hospitation"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <i class="tio">visible_outlined</i>
      <span>Hospitation</span>
    </a>
  </div>
</nav>

<!-- Multiplikator -->
<nav *ngIf="user?.is_multiplier">
  <i class="pi pi-times" (click)="toggleMobileNav()"></i>

  <div class="nav-items">
    <a
      routerLink="/neu"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <i class="tio">file_add_outlined</i>
      <span>Neuer Kunde</span>
    </a>

    <a routerLink="/patienten" routerLinkActive="active">
      <i class="tio">group_equal</i>
      <span>Patienten</span>
    </a>
  </div>
</nav>

<!-- Systembenutzer -->
<nav *ngIf="user?.is_user_system">
  <div class="nav-items">
    <a
      [pTooltip]="navHidden ? 'Übersicht' : ''"
      routerLink="/"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <i class="tio">explore_outlined</i>
      <span>Übersicht</span>
    </a>
    <a
      [pTooltip]="navHidden ? 'Telefonate' : ''"
      routerLink="/phone-calls"
      routerLinkActive="active"
    >
      <i class="tio">call_talking</i>
      <span>Telefonate</span>
    </a>
    <a
      [pTooltip]="navHidden ? 'Todos' : ''"
      routerLink="/todos"
      routerLinkActive="active"
    >
      <i class="tio">checkmark_circle_outlined</i>
      <span>
        Todos

        <p-badge
          *ngIf="amounts.todos"
          [value]="amounts.todos.toString()"
        ></p-badge>
      </span>
    </a>

    <span>Vertrieb</span>
    <a
      [pTooltip]="navHidden ? 'Erfassung Neukunde' : ''"
      routerLink="/offer"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <i class="tio">file_add_outlined</i>
      <span>Erfassung Neukunde</span>
    </a>
    <a
      [pTooltip]="navHidden ? 'Qualifizierung' : ''"
      routerLink="/patients"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <i class="tio">help_outlined</i>
      <span>Qualifizierung</span>
    </a>
    <a
      [pTooltip]="navHidden ? 'Neukunden' : ''"
      routerLink="/patients/new"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <i class="tio">group_equal</i>
      <span>Neukunden</span>
    </a>
    <a
      [pTooltip]="navHidden ? 'Kalender' : ''"
      routerLink="/calendar"
      routerLinkActive="active"
    >
      <i class="tio">calendar_month</i>
      <span>Kalender</span>
    </a>

    <span>Kundenbetreuung</span>
    <a
      [pTooltip]="navHidden ? 'Storno' : ''"
      routerLink="/patients/inactive"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <i class="tio">clear</i>
      <span>Storno</span>
    </a>
    <a
      [pTooltip]="navHidden ? 'Pausiert' : ''"
      routerLink="/patients/paused"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <i class="tio">pause_circle_outlined</i>
      <span>Pausiert</span>
    </a>
    <a
      [pTooltip]="navHidden ? 'Stammkunden' : ''"
      routerLink="/patients/regular"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <i class="tio">group_equal</i>
      <span>Stammkunden</span>
    </a>
    <a
      [pTooltip]="navHidden ? 'Wunschtermine' : ''"
      routerLink="/desired-dates"
      routerLinkActive="active"
    >
      <i class="tio">appointment</i>
      <span>Wunschtermine</span>
    </a>
    <a
      [pTooltip]="navHidden ? 'Terminabsagen' : ''"
      routerLink="/cancelled-dates"
      routerLinkActive="active"
    >
      <i class="tio">appointment_cancelled</i>
      <span>Terminabsagen</span>
    </a>
    <a
      [pTooltip]="navHidden ? 'Beratungseinsätze' : ''"
      routerLink="/consultings"
      routerLinkActive="active"
    >
      <i class="tio">event</i>
      <span>Beratungseinsätze</span>
    </a>

    <a
      [pTooltip]="navHidden ? 'Übergabe' : ''"
      routerLink="/handover"
      routerLinkActive="active"
    >
      <i class="tio">send</i>
      <span>Terminübergabe</span>
    </a>

    <span>
      Außendienst
    </span>

    <a
      [pTooltip]="navHidden ? 'Betreuungskräfte' : ''"
      routerLink="/caregivers"
      routerLinkActive="active"
    >
      <i class="tio">heart_outlined</i>
      <span>Betreuungskräfte</span>
    </a>

    <a
      [pTooltip]="navHidden ? 'Bewerber' : ''"
      routerLink="/applicants"
      routerLinkActive="active"
    >
      <i class="tio">users_switch</i>
      <span>Bewerber</span>
    </a>

    <a
      [pTooltip]="navHidden ? 'Planung' : ''"
      routerLink="/plannings"
      routerLinkActive="active"
    >
      <i class="tio">time</i>
      <span>
        Planung
        <p-badge
          *ngIf="amounts.plannings"
          [value]="amounts.plannings.toString()"
        ></p-badge>
      </span>
    </a>

    <a
      [pTooltip]="navHidden ? 'Freie Kapazität' : ''"
      routerLink="/capacity"
      routerLinkActive="active"
    >
      <i class="tio">remaining_time</i>
      <span>Freie Kapazität</span>
    </a>

    <a
      [pTooltip]="navHidden ? 'Dienstpläne' : ''"
      routerLink="/plans"
      routerLinkActive="active"
    >
      <i class="tio">calendar_note</i>
      <span>Dienstpläne</span>
    </a>

    <a
      [pTooltip]="navHidden ? 'Leistungsnachweise' : ''"
      routerLink="/proofs"
      routerLinkActive="active"
    >
      <i class="tio">file_text_outlined</i>
      <span>Leistungsnachweise</span>
    </a>

    <span>Verwaltung</span>
    <a
      [pTooltip]="navHidden ? 'Auswertungen' : ''"
      [routerLink]="
        authService.can('Auswertungen.Zugriff auf globale Auswertungen')
          ? '/analyses'
          : '/analyses/budgets'
      "
      routerLinkActive="active"
    >
      <i class="tio">chart_donut_2</i>
      <span>Auswertungen</span>
    </a>
    <a
      [pTooltip]="navHidden ? 'Rechnungen' : ''"
      routerLink="/invoices"
      routerLinkActive="active"
    >
      <i class="tio">euro_outlined</i>
      <span>Rechnungen</span>
    </a>
    <a
      [pTooltip]="navHidden ? 'Abrechnung' : ''"
      routerLink="/accounting/returns"
      routerLinkActive="active"
    >
      <i class="tio">euro_outlined</i>
      <span>Abrechnung</span>
    </a>
    <a
      [pTooltip]="navHidden ? 'Dokumente' : ''"
      routerLink="/documents"
      routerLinkActive="active"
    >
      <i class="tio">document_text_outlined</i>
      <span>Dokumente</span>
    </a>
    <a
      [pTooltip]="navHidden ? 'Multiplikatoren' : ''"
      routerLink="/multipliers"
      routerLinkActive="active"
    >
      <i class="tio">quarantine_outlined</i>
      <span>Multiplikatoren</span>
    </a>
    <a
      [pTooltip]="navHidden ? 'PKW' : ''"
      routerLink="/cars"
      routerLinkActive="active"
    >
      <i class="tio">car</i>
      <span>PKW</span>
    </a>
    <a
      *ngIf="authService.canAny('Verwaltung.Systembenutzer verwalten')"
      [pTooltip]="navHidden ? 'Verwaltung' : ''"
      routerLink="/administration"
      routerLinkActive="active"
    >
      <i class="tio">settings_outlined</i>
      <span>Verwaltung</span>
    </a>
  </div>
</nav>
