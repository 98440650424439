import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { ToastService } from '../../../../services/toast.service'
import { environment } from '../../../../../environments/environment'
import { DialogService } from 'primeng/dynamicdialog'
import { StatusCodes } from 'http-status-codes'
import {
  EventBusService,
  GlobalEvent,
} from '../../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { AddDocumentDialogComponent } from '../../../../components/dialogs/add-document-dialog/add-document-dialog.component'
import { DocumentModel } from '../../../../models/document/document.model'
import { PatientService } from '../../../../services/patient.service'
import {
  CustomerDetailDocumentsModel,
  PatientDetailDocumentsModel,
} from '../../../../models/customer-patient/patient-detail-documents.model'
import { HttpErrorResponse } from '@angular/common/http'
import { MediaModel } from '../../../../models/document/media.model'

@Component({
  selector: 'app-patients-detail-documents',
  templateUrl: './patients-detail-documents.component.html',
})
export class PatientsDetailDocumentsComponent implements OnInit, OnDestroy {
  public loading = true
  public onlyArchived = false

  public initialDocumentsSubmitted = {
    first_patient: false,
    second_patient: false,
  }
  public showInitialDocumentDownload = {
    first_patient: false,
    second_patient: false,
  }

  private eventBusSubscription: Subscription = new Subscription()
  public patients: CustomerDetailDocumentsModel = new CustomerDetailDocumentsModel()
  public documents: PatientDetailDocumentsModel = new PatientDetailDocumentsModel()

  public receiver = ''
  public receiverOptions: any[] = []

  public documentTypes: {
    first_patient: DocumentModel[]
    second_patient: DocumentModel[]
  } = {
    first_patient: [],
    second_patient: [],
  }

  private patientId: string | null = null
  public selectedPatient: 'first_patient' | 'second_patient' = 'first_patient'

  constructor(
    public patientService: PatientService,
    private toastService: ToastService,
    private eventbus: EventBusService,
    private dialogService: DialogService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (this.route.parent) {
      this.patientId = this.route.parent.snapshot.params.id

      this.loadDocuments()
    }

    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
  }

  public changePatient(type: 'first_patient' | 'second_patient'): void {
    this.selectedPatient = type
  }

  public getInitialDocumentsDownloadLink(): string {
    return `${environment.api}/documents/patients/${
      this.patients[this.selectedPatient]?.id
    }/download-init`
  }

  public openDownloadTab(document: MediaModel): void {
    window.open(this.patientService.getDocumentDownloadLink(document.uuid))
  }

  public createInitialDocuments(): void {
    this.initialDocumentsSubmitted[this.selectedPatient] = true

    this.patientService
      .initDocuments(this.patients[this.selectedPatient]?.id, this.receiver)
      .subscribe(
        () => {
          this.initialDocumentsSubmitted[this.selectedPatient] = false
          this.showInitialDocumentDownload[this.selectedPatient] = true

          this.loadDocuments()
        },
        (error: HttpErrorResponse) => {
          if (error.status === StatusCodes.EXPECTATION_FAILED) {
            this.toastService.error('Kein Ansprechpartner vorhanden')
          } else {
            this.toastService.error(
              'Initiale Erstellung fehlgeschlagen',
              'Bitte wenden Sie sich an den Support'
            )
          }
          this.initialDocumentsSubmitted[this.selectedPatient] = false
        }
      )
  }

  public openAddDocumentDialog(document = null, documentType = ''): void {
    const ref = this.dialogService.open(AddDocumentDialogComponent, {
      data: {
        type_id: this.patients[this.selectedPatient]?.id,
        patients: this.patients,
        contact_persons: this.patients.contact_persons,
        type: 'patients',
        document_type: documentType,
        document,
      },
      header: document ? 'Dokument bearbeiten' : 'Dokument erstellen',
      styleClass: 'dialog-container',
      dismissableMask: false,
      width: '450px',
    })

    ref.onClose.subscribe((file: any) => {
      // Wenn ein Dokument generiert wurde (und nicht manuell hochgeladen),
      // dann wird der Download für das generierte Dokument automatisch angestoßen.
      if (
        file?.media &&
        !file.media.custom_properties?.upload &&
        !Array.isArray(file.media)
      ) {
        this.openDownloadTab(file.media)
      }
    })
  }

  public downloadInitialDocuments(): void {
    this.loadDocuments()
  }

  /**
   * Hier reagieren wir auf Events im EventBusService, wie z.B. das Event,
   * dass die PhoneCall-Liste die Daten neu laden soll (wenn z.B. ein Telefonat
   * gelöscht oder bearbeitet wurde).
   */
  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.DocumentListReload:
            this.loadDocuments(false)
        }
      }
    )
  }

  private buildReceiverOptions(): void {
    this.receiverOptions.push({
      value: '',
      label: 'Bitte auswählen',
    })

    this.receiverOptions.push({
      value: `patient_${this.patients.first_patient.id}`,
      label: this.patients.first_patient.full_name + ' - Patient',
    })

    if (this.patients.second_patient) {
      this.receiverOptions.push({
        value: `patient_${this.patients.second_patient.id}`,
        label: this.patients.second_patient.full_name,
      })
    }

    for (let contactPerson of this.patients.contact_persons) {
      this.receiverOptions.push({
        value: `ap_${contactPerson.id}`,
        label: contactPerson.full_name + ' - Ansprechpartner',
      })
    }
  }

  public loadDocuments(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    this.patientService
      .documents(this.patientId, this.onlyArchived)
      .subscribe((data: CustomerDetailDocumentsModel) => {
        this.patients = data
        this.documentTypes.first_patient = Object.values(
          data.first_patient.types
        )

        if (data.second_patient) {
          this.documentTypes.second_patient = Object.values(
            data.second_patient.types
          )
        }

        this.buildReceiverOptions()

        if (withLoading) {
          this.loading = false
        }
      })
  }
}
