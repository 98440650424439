import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { StatusCodes } from 'http-status-codes'
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import { HelperService } from '../../../services/helper.service'
import { AuthService } from '../../../services/auth.service'
import { AuthModel } from '../../../models/user/auth.model'
import { DocumentService } from '../../../services/document.service'
import { TodoModel } from '../../../models/todo/todo.model'
import { TodoService } from '../../../services/todo.service'
import { TodoFormDialogComponent } from '../todo-form-dialog/todo-form-dialog.component'
import { TodoCommentModel } from '../../../models/todo/todo-comment.model'
import {
  UserSystemModel,
  UserSystemSimpleModel,
} from '../../../models/user/user-system.model'
import { UserSystemService } from '../../../services/user-system.service'

@Component({
  selector: 'app-show-todo-dialog',
  templateUrl: './show-todo-dialog.component.html',
})
export class ShowTodoDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false

  public priorityOptions = [
    { label: 'Hoch', value: 1 },
    { label: 'Normal', value: 2 },
    { label: 'Niedrig', value: 3 },
  ]

  public loading = false
  public editAssignedMode = false
  public isAssignedToMe = false
  public currentUser: AuthModel | null = new AuthModel()
  public data: any = {}
  public values = {
    comment: '',
  }
  public todo: TodoModel = new TodoModel()
  public editComment: TodoCommentModel | null = null
  public userSystemOptions: any[] = []
  public myPriority = 2

  public submitted = false
  public submittedStatus = false
  public submittedAssigned = false
  public submittedDelete = false

  private currentStatus = ''

  public statusOptions = [
    { label: 'OFFEN', value: 'OPEN', backgroundColor: '#3296dc' },
    { label: 'ERLEDIGT', value: 'FINISHED', backgroundColor: '#84bc3c' },
  ]

  constructor(
    private ref: DynamicDialogRef,
    public authService: AuthService,
    private config: DynamicDialogConfig,
    private helperService: HelperService,
    private dialogService: DialogService,
    private todoService: TodoService,
    public documentService: DocumentService,
    private userSystemService: UserSystemService,
    private eventbus: EventBusService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.data = this.config.data

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    this.currentUser = this.authService.getUser()

    this.loadTodo()
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public changeMyPriority(): void {
    this.todoService.changeMyPriority(this.todo.id, this.myPriority).subscribe(
      () => {
        this.eventbus.emit(GlobalEvent.TodoChanged)
        this.toastService.success('Priorität gespeichert')
      },
      () => {
        this.toastService.error(
          'Etwas ist schief gelaufen...',
          'Bitte wenden Sie sich an den Support'
        )
      }
    )
  }

  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    this.todoService.storeComment(this.todo.id || '', this.values).subscribe(
      () => {
        this.loadTodo(false)
        this.values.comment = ''
        this.submitted = false
        this.eventbus.emit(GlobalEvent.TodoChanged)
        this.toastService.success(
          'Kommentar gespeichert',
          'Der Kommentar wurde erfolgreich gespeichert'
        )
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }

  public update(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    this.todoService
      .updateComment(this.editComment?.id || '', this.values)
      .subscribe(
        () => {
          this.loadTodo(false)
          this.values.comment = ''
          this.editComment = null
          this.submitted = false
          this.eventbus.emit(GlobalEvent.TodoChanged)
          this.toastService.success(
            'Kommentar bearbeitet',
            'Der Kommentar wurde erfolgreich bearbeitet'
          )
        },
        (error: HttpErrorResponse) => {
          if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
            this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
          } else {
            this.toastService.error(
              'Etwas ist schief gelaufen...',
              'Bitte wenden Sie sich an den Support'
            )
          }
          this.submitted = false
        }
      )
  }

  public changeStatus(): void {
    this.submittedStatus = true

    this.todoService.changeStatus(this.todo).subscribe(
      () => {
        this.loadTodo(false)
        this.submittedStatus = false
        this.eventbus.emit(GlobalEvent.TodoChanged)
        this.toastService.success(
          'Status gespeichert',
          'Der Status wurde erfolgreich geändert'
        )
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submittedStatus = false
      }
    )
  }

  public changeAssignments(): void {
    this.submittedAssigned = true

    this.todoService.changeAssignments(this.todo).subscribe(
      () => {
        this.loadTodo(false)
        this.editAssignedMode = false
        this.submittedAssigned = false
        this.eventbus.emit(GlobalEvent.TodoChanged)
        this.toastService.success(
          'Zuweisung gespeichert',
          'Die Zuweisung wurde erfolgreich geändert'
        )
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submittedAssigned = false
      }
    )
  }

  private loadAllUserSystem(): void {
    this.userSystemOptions = []

    this.userSystemService
      .listActiveSimple()
      .subscribe((data: UserSystemSimpleModel[]) => {
        for (const userSystem of data) {
          this.userSystemOptions.push({
            id: userSystem.id,
            full_name: userSystem.full_name.trim(),
          })
        }

        // Richtige Struktur für PrimeNG zusammenstellen.
        // @ts-ignore
        this.todo.user_systems = this.todo.user_systems.map(
          (userSystem: UserSystemModel) => {
            return {
              id: userSystem.id,
              full_name: userSystem.full_name.trim(),
            }
          }
        )
      })
  }

  public openEditAssigned(): void {
    // this.currentStatus = this.todo.status
    this.editAssignedMode = true
  }

  public closeEditAssigned(): void {
    // this.todo.status = this.currentStatus
    this.editAssignedMode = false
  }

  public openEditMode(comment: TodoCommentModel): void {
    this.editComment = comment
    this.values.comment = comment.comment
  }

  public cancelEditMode(): void {
    this.editComment = null
    this.values.comment = ''
  }

  public openTodoDialog(): void {
    this.ref.close()
    this.dialogService.open(TodoFormDialogComponent, {
      header: 'Todo bearbeiten',
      width: '520px',
      styleClass: 'dialog-container',
      data: {
        isEdit: true,
        todo: this.todo,
        user_type_name:
          this.todo?.caregiver?.full_name || this.todo?.patient?.full_name,
      },
    })
  }

  private loadTodo(withLoading: boolean = true): void {
    this.todoService
      .load(this.data.todo_id)
      .subscribe((response: TodoModel) => {
        this.todo = response

        this.todo.user_systems.forEach((systemUser: any) => {
          if (systemUser.id === this.currentUser?.user_system.id) {
            this.isAssignedToMe = true
            this.myPriority = systemUser.pivot.priority
          }
        })

        this.data.user_type_name =
          this.todo?.caregiver?.full_name || this.todo?.patient?.full_name

        this.loadAllUserSystem()

        if (withLoading) {
          setTimeout(() => {
            this.loading = false
          }, 100)
        }
      })
  }
}
