import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'
import { DashboardModel } from '../models/dashboard/dashboard.model'
import { PatientModel } from '../models/customer-patient/patient.model'
import { TodoModel } from '../models/todo/todo.model'
import { HistoryListModel } from '../models/history/history-list.model'
import { CaregiverDetailModel } from '../models/caregiver/caregiver-detail.model'
import { UserSystemModel } from '../models/user/user-system.model'
import { VacationIllModel } from '../models/vacation-ill/vacation-ill.model'
import { PhoneCallsListItemModel } from '../models/phone-call/phone-calls-list.model'
import { TodoHeaderModel } from '../models/todo/todo-header.model'

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private http: HttpClient) {}

  public load(settings: any): Observable<DashboardModel> {
    return this.http.get<DashboardModel>(`${environment.api}/dashboard`, {
      params: {
        ...settings,
      },
    })
  }

  public loadPauseEnding(): Observable<PatientModel[]> {
    return this.http.get<PatientModel[]>(
      `${environment.api}/dashboard/pause-ending`
    )
  }

  public loadImportantHistories(): Observable<HistoryListModel[]> {
    return this.http.get<HistoryListModel[]>(
      `${environment.api}/dashboard/important-histories`
    )
  }

  public loadNewCreated(): Observable<PatientModel[]> {
    return this.http.get<PatientModel[]>(
      `${environment.api}/dashboard/new-created`
    )
  }

  public loadPhoneCalls(): Observable<PhoneCallsListItemModel[]> {
    return this.http.get<PhoneCallsListItemModel[]>(
      `${environment.api}/dashboard/phone-calls`
    )
  }

  public loadBirthdays(): Observable<
    PatientModel[] | CaregiverDetailModel[] | UserSystemModel[]
  > {
    return this.http.get<
      PatientModel[] | CaregiverDetailModel[] | UserSystemModel[]
    >(`${environment.api}/dashboard/birthdays`)
  }

  public loadVacationIll(): Observable<VacationIllModel[]> {
    return this.http.get<VacationIllModel[]>(
      `${environment.api}/dashboard/vacation-ill`
    )
  }

  public loadNavAmounts(): Observable<any> {
    return this.http.get<any>(`${environment.api}/dashboard/nav-amounts`)
  }

  public loadTodos(settings: any): Observable<TodoModel[]> {
    return this.http.get<TodoModel[]>(`${environment.api}/dashboard/todos`, {
      params: {
        ...settings,
      },
    })
  }
}
