import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { Router } from '@angular/router'
import { TitleService } from 'src/app/services/title.service'
import { DocumentService } from '../../../services/document.service'
import { DialogService } from 'primeng/dynamicdialog'
import {
  EventBusService,
  GlobalEvent,
} from '../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import * as dayjs from 'dayjs'
import { Dayjs } from 'dayjs'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import * as utc from 'dayjs/plugin/utc'
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular'
import deLocale from '@fullcalendar/core/locales/de'
import { CaregiverService } from '../../../services/caregivers.service'
import { HelperService } from '../../../services/helper.service'
import { ThcDateDialogComponent } from '../../../components/dialogs/thc-date-dialog/thc-date-dialog.component'
import { UserSystemService } from '../../../services/user-system.service'

@Component({
  selector: 'app-thc-dates',
  templateUrl: './thc-dates.component.html',
})
export class ThcDatesComponent implements OnInit, OnDestroy {
  @ViewChild('dt') table: ElementRef | any = null
  @ViewChild('calendar') calendarComponent: FullCalendarComponent | any = null

  private startWeek: Dayjs = dayjs()
  private eventBusSubscription: Subscription = new Subscription()

  public usersOptions: any[] = []

  public contentLoading = true
  public loading = true
  public week = {
    monday: '',
    tuesday: '',
    wednesday: '',
    thursday: '',
    friday: '',
    saturday: '',
    sunday: '',
  }

  public filters = {
    users: [] as any[],
  }

  public viewOptions = [
    { label: 'Woche', value: 'timeGridWeek' },
    { label: 'Monat', value: 'dayGridMonth' },
  ]

  public calendarOptions: CalendarOptions = {
    initialView: 'timeGridWeek',
    headerToolbar: false,
    locales: [deLocale],
    locale: 'de',
    eventStartEditable: true,
    allDaySlot: false,
    slotMinTime: '07:00:00',
    slotMaxTime: '21:00:00',
    // slotDuration: '00:15:00',
    // dayHeaderFormat: {
    //   weekday: 'long',
    // },
    eventDidMount: (info: any) => {
      info.el.title = `${info.event.title}\n${
        info.event.extendedProps.time
      } Uhr\n\n${info.event.extendedProps.comment || ''}`
    },
    eventDrop: (info: any) => {
      if (!confirm('THC wirklich verschieben?')) {
        info.revert()
      } else {
        this.editDate(info.event.id, info.event.start)
      }
    },
    eventClick: (info: any) => {
      this.openThcDateDialog(
        info.event.title,
        info.event.id,
        info.event.extendedProps.patient_id,
        info.event.extendedProps.type
      )
    },
  }

  constructor(
    public documentService: DocumentService,
    private dialogService: DialogService,
    private caregiverService: CaregiverService,
    private userSystemService: UserSystemService,
    private router: Router,
    private helperService: HelperService,
    private eventbus: EventBusService,
    private titleService: TitleService
  ) {
    dayjs.locale('de')
    dayjs.extend(customParseFormat)
    dayjs.extend(utc)
  }

  ngOnInit(): void {
    this.startWeek = dayjs.utc().startOf('week')
    this.setWeek()

    this.helperService.dependencies$.subscribe((data: any) => {
      for (const user of data.thc_users) {
        this.filters.users.push(user.id)

        this.usersOptions.push({
          id: user.id,
          last_name: user.user_system.last_name,
        })
      }
    })

    this.loadDates()
    this.listenForEventbus()
    this.titleService.setTitle('THC')

    this.loading = false
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.ThcDateChanged:
            this.loadDates(false)
        }
      }
    )
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
  }

  /**
   * Springt zur Woche vom ausgewählten Datum aus dem Datepicker.
   *
   * @param event
   */
  public goToSelectedWeek(event: any) {
    this.startWeek = dayjs(event).startOf('week')
    this.setWeek()

    const api = this.calendarComponent.getApi()
    api.gotoDate(this.startWeek.toISOString())

    this.loadDates(false)
  }

  public setView(event: any): void {
    const api = this.calendarComponent.getApi()
    api.changeView(event.value)
  }

  /**
   * Springt zur letzten Woche.
   */
  public goToPreviousWeek(): void {
    this.startWeek = this.startWeek.subtract(1, 'week').startOf('week')
    this.setWeek()

    const api = this.calendarComponent.getApi()
    api.prev()

    this.loadDates(false)
  }

  /**
   * Springt zur nächsten Woche.
   */
  public goToNextWeek(): void {
    this.startWeek = this.startWeek.add(1, 'week').startOf('week')
    this.setWeek()

    const api = this.calendarComponent.getApi()
    api.next()

    this.loadDates(false)
  }

  /**
   * Baut die komplette Woche zusammen.
   */
  private setWeek(): void {
    this.week.monday = this.startWeek.format('DD.MM.YYYY')
    this.week.tuesday = this.startWeek.add(1, 'day').format('DD.MM.YYYY')
    this.week.wednesday = this.startWeek.add(2, 'days').format('DD.MM.YYYY')
    this.week.thursday = this.startWeek.add(3, 'days').format('DD.MM.YYYY')
    this.week.friday = this.startWeek.add(4, 'days').format('DD.MM.YYYY')
    this.week.saturday = this.startWeek.add(5, 'days').format('DD.MM.YYYY')
    this.week.sunday = this.startWeek.add(6, 'days').format('DD.MM.YYYY')
  }

  public loadDates(withLoading: boolean = true): void {
    this.contentLoading = true

    if (withLoading) {
      this.loading = true
    }

    this.userSystemService
      .loadThcData(this.week.monday, this.filters.users)
      .subscribe((data: any[]) => {
        this.calendarOptions.events = data

        this.contentLoading = false

        if (withLoading) {
          this.loading = false
        }
      })
  }

  public editDate(id: any, newDate: any): void {
    // Wird in einen Datumstring formatiert, da er sonst
    // Probleme mit der Zeitzone hat...
    const date = dayjs(newDate).format('DD.MM.YYYY HH:mm')

    this.caregiverService.editThcDate(id, date).subscribe(() => {
      this.loadDates(false)
    })
  }

  public openThcDateDialog(
    title: string,
    id: string,
    patientId: number,
    type = 'patient'
  ): void {
    this.dialogService.open(ThcDateDialogComponent, {
      header: 'THC Eintrag',
      width: '650px',
      styleClass: 'dialog-container',
      data: {
        title,
        id,
        type,
        patient_id: patientId,
      },
    })
  }

  public openNewThcDateDialog(): void {
    this.dialogService.open(ThcDateDialogComponent, {
      header: 'Neuer THC Eintrag',
      width: '650px',
      styleClass: 'dialog-container',
      data: {
        isNew: true,
      },
    })
  }
}
