<p-confirmPopup></p-confirmPopup>
<app-loader [loading]="loading"></app-loader>

<div class="content-actions limit-width" *ngIf="!loading">
  <h2>
    <i class="tio">quarantine_outlined</i>
    {{ isEditMode ? "Multiplikator bearbeiten" : "Neuer Multiplikator" }}
  </h2>
  <button
    *ngIf="isEditMode && !data.deleted_at"
    pButton
    (click)="deactivate($event)"
    label="Deaktivieren"
    type="button"
    icon="pi pi-times"
    class="p-button-danger"
  ></button>
  <button
    *ngIf="isEditMode && data.deleted_at"
    pButton
    (click)="activate($event)"
    label="Aktivieren"
    type="button"
    icon="pi pi-check"
    class="p-button"
  ></button>
</div>

<form #form="ngForm">
  <div class="content limit-width" *ngIf="!loading">
    <header class="content-header">
      <span>Daten</span>
    </header>

    <div class="content-body">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-6">
          <label for="categories">Kategorien *</label>
          <p-multiSelect
            inputId="categories"
            selectedItemsLabel="{0} Kategorien ausgewählt"
            [maxSelectedLabels]="5"
            [options]="dependencies.disciplines"
            [(ngModel)]="data.disciplines"
            optionLabel="category"
            name="categories"
            [required]="true"
            #disciplines="ngModel"
            [class.p-invalid]="
              disciplines.invalid && (disciplines.dirty || disciplines.touched)
            "
          ></p-multiSelect>
          <div
            *ngIf="
              disciplines.invalid && (disciplines.dirty || disciplines.touched)
            "
            class="p-invalid"
          >
            <div *ngIf="disciplines.errors?.required">
              <small>Bitte wählen Sie eine Kategorie aus</small>
            </div>
          </div>
        </div>

        <div class="p-field p-col-6">
          <label for="name">Name *</label>
          <input
            id="name"
            name="name"
            [(ngModel)]="data.name"
            type="text"
            pInputText
            #name="ngModel"
            required
            [class.p-invalid]="name.invalid && (name.dirty || name.touched)"
          />
          <div
            *ngIf="name.invalid && (name.dirty || name.touched)"
            class="p-invalid"
          >
            <div *ngIf="name.errors?.required">
              <small>Bitte geben Sie einen Namen ein</small>
            </div>
          </div>
        </div>
      </div>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-4">
          <label for="address">Straße / Hausnummer</label>
          <input
            id="address"
            name="address"
            [(ngModel)]="data.street_house_number"
            type="text"
            pInputText
          />
        </div>

        <div class="p-field p-col-4">
          <label for="zipcode">PLZ</label>
          <input
            id="zipcode"
            [(ngModel)]="data.zipcode"
            (change)="loadCity()"
            type="text"
            name="zipcode"
            pInputText
          />
        </div>

        <div class="p-field p-col-4">
          <label for="city">Ort</label>
          <span class="p-input-icon-right">
            <i *ngIf="cityLoading" class="pi pi-spin pi-spinner"></i>
            <input
              id="city"
              name="city"
              [(ngModel)]="data.city"
              type="text"
              pInputText
            />
          </span>
        </div>
      </div>

      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-4">
          <label for="city_part">Ortsteil</label>
          <input
            id="city_part"
            name="city_part"
            [(ngModel)]="data.city_part"
            type="text"
            pInputText
          />
        </div>

        <div class="p-field p-col-4">
          <label for="county">Landkreis</label>
          <input
            id="county"
            name="county"
            [(ngModel)]="data.county"
            type="text"
            pInputText
          />
        </div>

        <div class="p-field p-col-4">
          <label for="state">Bundesland</label>
          <input
            id="state"
            name="state"
            [(ngModel)]="data.state"
            type="text"
            pInputText
          />
        </div>
      </div>

      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-6">
          <label for="kassen-ik">Kassen IK</label>
          <input
            id="kassen-ik"
            name="kassen-ik"
            [(ngModel)]="data.kassen_ik"
            type="text"
            pInputText
          />
        </div>

        <div class="p-field p-col-6">
          <label for="pflege-ik">Pflege IK</label>
          <input
            id="pflege-ik"
            name="pflege-ik"
            [(ngModel)]="data.pflege_ik"
            type="text"
            pInputText
          />
        </div>
      </div>
    </div>
  </div>

  <div class="content limit-width" *ngIf="!loading">
    <header class="content-header">
      <span>Kontaktdaten</span>
    </header>

    <div class="content-body">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-3">
          <label for="phone1">Telefon Zentrale</label>
          <input
            id="phone1"
            name="phone1"
            [(ngModel)]="data.phone_central"
            type="text"
            pInputText
          />
        </div>

        <div class="p-field p-col-3">
          <label for="phone2">Mobil</label>
          <input
            id="phone2"
            name="phone2"
            [(ngModel)]="data.mobile"
            type="text"
            pInputText
          />
        </div>

        <div class="p-field p-col-3">
          <label for="fax">Fax</label>
          <input
            id="fax"
            name="fax"
            [(ngModel)]="data.fax"
            type="text"
            pInputText
          />
        </div>

        <div class="p-field p-col-3">
          <label for="email">E-Mail</label>
          <input
            id="email"
            name="email"
            [(ngModel)]="data.email"
            type="text"
            pInputText
          />
        </div>
      </div>
    </div>
  </div>

  <div
    class="content-footer limit-width"
    [class.sticky]="isEditMode"
    *ngIf="!loading"
  >
    <span></span>

    <button
      (click)="save()"
      pButton
      [label]="isEditMode ? 'Änderungen speichern' : 'Multiplikator erstellen'"
      type="button"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>
