<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <span>Alle aktiven PKW</span>

    <div class="content-header-buttons">
      <a routerLink="/cars/new">
        <button
          pButton
          label="Neuer PKW"
          type="button"
          icon="pi pi-plus"
          iconPos="left"
          class="p-button-outlined"
        ></button>
      </a>

      <div
        class="table-search-container"
        [class.searchfield-active]="globalFilterValue != ''"
        *ngIf="cars.length > 0"
      >
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            (input)="filterTableGlobal($event)"
            placeholder="Suche..."
            [(ngModel)]="globalFilterValue"
          />
        </div>
      </div>
    </div>
  </header>
</div>

<div class="content" style="margin-bottom: 0" *ngIf="!loading">
  <div class="content-body" *ngIf="cars.length == 0">
    <span class="content-no-message">Keine PKW vorhanden</span>
  </div>

  <div
    class="content-body content-body-full-height-with-tabs without-padding"
    *ngIf="cars.length > 0"
  >
    <p-table
      #dt
      [value]="cars"
      [globalFilterFields]="[
        'id',
        'brand',
        'model',
        'license_plate',
        'current_caregiver.caregiver.first_name',
        'current_caregiver.caregiver.last_name'
      ]"
      scrollHeight="100%"
      [scrollable]="true"
      [(first)]="firstPage"
      [rowHover]="true"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{totalRecords} PKW"
      [rows]="50"
      [paginator]="true"
      stateStorage="local"
      stateKey="state-cars-list-2"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th pSortableColumn="brand">
            Auto <p-sortIcon field="brand"></p-sortIcon>
          </th>
          <th pSortableColumn="license_plate">
            Kennzeichen <p-sortIcon field="license_plate"></p-sortIcon>
          </th>
          <th pSortableColumn="initial_registration">
            EZ <p-sortIcon field="initial_registration"></p-sortIcon>
          </th>
          <th pSortableColumn="current_mileage.mileage">
            KM <p-sortIcon field="current_mileage.mileage"></p-sortIcon>
          </th>
          <th style="width: 100px" pSortableColumn="current_status.status">
            Status
            <p-sortIcon field="current_status.status"></p-sortIcon>
          </th>
          <th>Zugewiesen an</th>
          <th pSortableColumn="current_place">
            Standort <p-sortIcon field="current_place"></p-sortIcon>
          </th>
          <th pSortableColumn="private_use_allowed">
            Privatnutzung <p-sortIcon field="private_use_allowed"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-car>
        <tr>
          <td>
            <a class="color-main1" routerLink="/cars/{{ car.id }}">
              {{ car.brand }} {{ car.model }}
            </a>
          </td>
          <td>{{ car.license_plate }}</td>
          <td>{{ car.initial_registration_string || "-" }}</td>
          <td>{{ car.current_mileage?.mileage || "-" }}</td>

          <td *ngIf="!car.current_status">-</td>
          <td *ngIf="car.current_status">
            <i
              [pTooltip]="car.current_status.status_text"
              [class.parking-icon]="car.current_status.status === 'PARKING'"
              [class.shop-icon]="car.current_status.status === 'SHOP'"
              [class.heart-icon-small]="car.current_status.status === 'ACTIVE'"
            ></i>
          </td>

          <td *ngIf="!car.current_caregiver">-</td>
          <td *ngIf="car.current_caregiver">
            <a
              routerLink="/caregivers/{{ car.current_caregiver.caregiver.id }}"
            >
              {{ car.current_caregiver.caregiver.first_name }}
              {{ car.current_caregiver.caregiver.last_name }}
            </a>
          </td>

          <td>{{ car.current_place || "-" }}</td>
          <td>
            <i
              class="pi pi-check color-main1"
              *ngIf="car.private_use_allowed === 'Ja'"
            ></i>
            <i
              class="pi pi-times color-alert"
              *ngIf="car.private_use_allowed === 'Nein'"
            ></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
