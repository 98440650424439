import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { CustomerDetailGeneralModel } from '../../../models/customer-patient/customer-detail-general.model'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import { PatientService } from '../../../services/patient.service'
import { PlanningService } from '../../../services/planning.service'

@Component({
  selector: 'app-change-status-patient-dialog',
  templateUrl: './change-status-patient-dialog.component.html',
})
export class ChangeStatusPatientDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  public customer: CustomerDetailGeneralModel = new CustomerDetailGeneralModel()
  private formSubscription: Subscription | null | undefined = null
  private isDirty = false

  public appointments: any[] = []

  submitted = false
  submittedDelete = false

  public patientOptions: any[] = []
  public values = {
    patients: [] as any,
    status: '',
    comment: '',
  }

  constructor(
    private ref: DynamicDialogRef,
    private planningService: PlanningService,
    private config: DynamicDialogConfig,
    private patientService: PatientService,
    private eventbus: EventBusService,
    private toastService: ToastService
  ) {}

  public ngOnInit(): void {
    this.customer = this.config.data.customer

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    this.values.patients.push({
      id: this.customer.first_patient.id,
      full_name: this.customer.first_patient.full_name,
    })

    this.patientOptions.push({
      id: this.customer.first_patient.id,
      full_name: this.customer.first_patient.full_name,
    })

    if (this.customer.second_patient) {
      this.patientOptions.push({
        id: this.customer.second_patient.id,
        full_name: this.customer.second_patient.full_name,
      })
    }

    this.loadPatientsAppointments()
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public loadPatientsAppointments(): void {
    this.appointments = []

    const patientIds = this.values.patients.map((patient: any) => patient.id)

    this.planningService
      .loadPatientsAppointmentsSinceToday(patientIds)
      .subscribe((response: any[]) => {
        this.appointments = response
      })
  }

  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    this.patientService.changeStatus(this.values).subscribe(
      () => {
        this.submitted = false
        this.eventbus.emit(GlobalEvent.PatientStatusChanged)
        this.ref.close()
        this.toastService.success(
          'Status geändert',
          'Der Status wurde erfolgreich geändert'
        )

        setTimeout(() => {
          location.reload()
        }, 0)
      },
      () => {
        this.toastService.error(
          'Etwas ist schiefgelaufen...',
          'Bitte wenden Sie sich an den Support'
        )

        this.submitted = false
      }
    )
  }
}
