import { Component, OnInit, ViewChild } from '@angular/core'
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { ProofsService } from '../../../services/proofs.service'
import { DocumentService } from '../../../services/document.service'
import * as dayjs from 'dayjs'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import * as utc from 'dayjs/plugin/utc'
import { PatientService } from '../../../services/patient.service'
import { HelperService } from '../../../services/helper.service'
import { InvoiceService } from '../../../services/invoice.service'
import { DomSanitizer } from '@angular/platform-browser'
import { ConfirmWithTextDialogComponent } from '../confirm-with-text-dialog/confirm-with-text-dialog.component'
import * as currency from 'currency.js'
import { HttpErrorResponse } from '@angular/common/http'
import { StatusCodes } from 'http-status-codes'
import { PatientModel } from '../../../models/customer-patient/patient.model'
import {AuthService} from "../../../services/auth.service";

@Component({
  selector: 'app-invoice-view-dialog',
  templateUrl: './invoice-view-dialog.component.html',
})
export class InvoiceViewDialogComponent implements OnInit {
  @ViewChild('form', { static: true }) form!: NgForm

  public submittedDelete = false
  public submitted = false

  private currencyOptions = {
    // Cent
    decimal: ',',
    // Tausender
    separator: '.',
    symbol: '€',
    pattern: `# !`,
  }

  public activeTab = 'CHECK'
  public loading = true

  public showSidebar = false
  public dataForCheckStatusIsValid = false
  public dataForBudgetStatusIsValid = false
  public hasOpenedReworks = false
  public hasBudgetIssueRework = false
  public data: any = {}
  public patient: PatientModel = new PatientModel()

  public pdfSrc: any = ''
  public pdfSrcSafe: any = ''

  public clickedOnPreviewLnw = false
  public clickedOnPreviewMail = false
  public clickedOnPreviewPdf = false

  public lnwBase64 = null
  public mediaGenerated = false
  public generatedMediaLink = ''

  public viewerLoading = true
  public rotating = false

  public currentPatient: any = null
  public selectedPatientAddress = ''
  public selectedCostUnitAddress = ''
  public selectedReceiverAddress = ''
  public selectedAddress: any = {}
  public selectedUnitCostReceiver: any = null
  public selectedReceiver: any = {}
  private viewer: any = null

  public currentDate = ''

  public selectedGlobalTab: any = {
    type: 'PROOF',
    index: 0,
  }

  public type: any = []
  public comment = ''

  public isPkv = false
  public isSelbstzahler = false
  public hasMedia = false

  public typeOptions = [
    { label: 'Sonstiges', value: 'OTHER' },
    { label: 'Fehlerhafter Leistungsnachweis', value: 'PROOF_ISSUE' },
    { label: 'Fehlerhaftes Budget', value: 'BUDGET_ISSUE' },
    { label: 'Fehlerhafte Daten Kunde', value: 'CUSTOMER_DATA_ISSUE' },
    { label: 'Änderung Termin', value: 'APPOINTMENT_CHANGE' },
  ]

  public stampSaveSubmitted = false
  public withDateStamp = false
  private positions = {
    stamp: {
      x: 0,
      y: 0,
    },
    date: {
      x: 0,
      y: 0,
    },
  }
  public costUnitHasNoContactPersons = false
  public contactPersonsForInvoice = ''
  public receiversData: any = {}
  public patientReceiverOptions: any[] = []
  public costUnitReceiverOptions: any[] = []
  public costUnitEmailReceiverOptions: any[] = []
  public addressReceiverOptions: any[] = []
  public ccOptions: any[] = []
  public formOfAddress: any = []

  public partialPaymentEditId: any = null
  public partialPayment = ''

  public invoiceValues = {
    invoice_receiver: '',
    individual_email: '',
    individual_address: '',
    individual_form_of_address: '',
    individual_first_name: '',
    individual_last_name: '',
    individual_street_house_number: '',
    individual_zipcode: '',
    individual_city: '',
    accounting_type: '',
    patient_receiver: '',
    cost_unit_receiver: '',
    cost_unit_email_receiver: '',
    accounting_receiver: '',
    payment_type: '',
    send_type: '',
    receiver: null as any,
    cc_receivers: [],
  }

  constructor(
    public authService: AuthService,
    private ref: DynamicDialogRef,
    public proofService: ProofsService,
    private invoiceService: InvoiceService,
    public config: DynamicDialogConfig,
    private dialogService: DialogService,
    public documentService: DocumentService,
    private patientService: PatientService,
    private sanitizer: DomSanitizer,
    private eventbus: EventBusService,
    private helperService: HelperService,
    private toastService: ToastService
  ) {
    dayjs.locale('de')
    dayjs.extend(customParseFormat)
    dayjs.extend(utc)
  }

  public ngOnInit(): void {
    this.data = this.config.data.invoice

    if (this.data.type === 'KM') {
      this.patient = this.data.invoiceable
    } else if (this.data.type === 'CONSULTING') {
      this.patient = this.data.invoiceable.patient
    } else if (this.data.type === 'APPOINTMENT') {
      this.patient = this.data.invoiceable.patient
    }

    this.pdfSrc = this.documentService.getProofImageDocumentDownloadLink(
      this.data.media[0].uuid
    )

    this.pdfSrcSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfSrc)

    this.loading = false
  }

  public editPayment(payment: any): void {
    this.partialPaymentEditId = payment.id
    this.partialPayment = payment.payment_euro
  }

  public markInvoiceAsCompleted(): void {
    if (!window.confirm('Rechnung wirklich abschließen?')) {
      return
    }

    this.submitted = true

    this.invoiceService.markInvoiceAsCompleted(this.data.id).subscribe(
      (invoice: any) => {
        this.data = invoice

        this.submitted = false
        this.eventbus.emit(GlobalEvent.InvoiceChanged)
        // this.ref.close()
        this.toastService.success(
          'Rechnung abgeschlossen',
          'Die Rechnung wurde erfolgreich abgeschlossen'
        )
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }

  public resetCompletedStatus(): void {
    if (!window.confirm('Rechnung wirklich zurücksetzen?')) {
      return
    }

    this.submitted = true

    this.invoiceService.resetCompletedStatus(this.data.id).subscribe(
      (invoice: any) => {
        this.data = invoice

        this.submitted = false
        this.eventbus.emit(GlobalEvent.InvoiceChanged)
        this.toastService.success(
          'Rechnung zurückgesetzt',
          'Die Rechnung wurde erfolgreich zurückgesetzt'
        )
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }

  public addPayment(): void {
    this.submitted = true

    // Formatiert die Zahl in einen €-String
    const partialPaymentFormatted = currency(
      this.partialPayment,
      this.currencyOptions
    ).format()

    const subscription = this.partialPaymentEditId
      ? this.invoiceService.updatePayment(
          this.partialPaymentEditId,
          partialPaymentFormatted
        )
      : this.invoiceService.addPayment(this.data.id, partialPaymentFormatted)

    subscription.subscribe(
      (invoice: any) => {
        this.data.part_payments = invoice.part_payments
        this.data.part_payments_sum = invoice.part_payments_sum
        this.data.part_payments_sum_euro_formatted =
          invoice.part_payments_sum_euro_formatted

        this.partialPayment = ''
        this.partialPaymentEditId = null

        this.submitted = false
        this.eventbus.emit(GlobalEvent.InvoiceChanged)
        // this.ref.close()
        this.toastService.success('Teilzahlung hinzugefügt')
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }

  public stornoInvoice(): void {
    const ref = this.dialogService.open(ConfirmWithTextDialogComponent, {
      header: 'Rechnung wirklich stornieren?',
      width: '450px',
      styleClass: 'dialog-container',
      data: {
        withExtraText:
          this.data.type === 'APPOINTMENT'
            ? 'Es wird ein neuer Entwurf erstellt'
            : '',
        isRequired: true,
      },
    })

    ref.onClose.subscribe((values: any) => {
      if (values) {
        this.submittedDelete = true
        this.submitted = true

        this.invoiceService
          .stornoInvoice(this.data.id, values.storno_comment)
          .subscribe(
            (response: any) => {
              this.eventbus.emit(GlobalEvent.InvoiceChanged)
              this.ref.close()

              this.toastService.success(
                'Rechnung storniert',
                'Die Rechnung wurde erfolgreich storniert'
              )
            },
            () => {
              this.toastService.error(
                'Etwas ist schiefgelaufen...',
                'Bitte wenden Sie sich an den Support'
              )

              this.submittedDelete = false
            }
          )
      }
    })
  }

  private loadData(appointment: any): void {
    // this.proofService
    //   .loadSpecific(this.data.proof_status.id)
    //   .subscribe((response: any) => {
    //     this.data.persplan_budgets = response.persplan_budgets
    //     this.data.budgets = response.budgets
    //     this.data.costs_in_euro = response.costs_in_euro
    //     this.data.costs_in_euro_no_format = response.costs_in_euro_no_format
    //
    //     // Es soll zusätzlich eine Nachbearbeitung erstellt werden.
    //     const comment = `Einsatz vom ${appointment.date}, ${appointment.from_h} - ${appointment.to_h} für ${appointment.caregiver_name} wurde geändert. Bitte überprüfen.`
    //     this.proofService
    //       .addProofRework(
    //         this.data.proof_status.id,
    //         ['APPOINTMENT_CHANGE'],
    //         comment
    //       )
    //       .subscribe((r: any) => {
    //         this.data.proof_status.reworkings.push(r)
    //
    //         this.checkForOpenedReworks()
    //         this.checkForBudgetIssueRework()
    //
    //         this.eventbus.emit(GlobalEvent.ProofsChanged)
    //         // this.toastService.success('Erfolgreich')
    //       })
    //   })
  }
}
