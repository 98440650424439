import { MultiplierFormModel } from '../multiplier/multiplier-form.model'
import { VetoModel } from '../veto/veto.model'
import { PatientPauseModel } from './patient-pause.model'
import { AppointmentLockModel } from '../appointments/appointment-lock.model'
import { MediaModel } from '../document/media.model'
import { PatientTimeModel } from './patient-time.model'
import { CustomerModel } from './customer.model'

export class PatientModel {
  id: number | null = null
  customer_id: number | null = null
  customer: CustomerModel = new CustomerModel()
  persplan_id: number | null = null
  status = ''
  workflow_status = ''
  workflow_status_string = ''
  from_campaign = false
  status_string = ''
  summary_intern = ''
  patient_type = ''
  full_name = ''
  full_address = ''
  is_paused = false
  is_gkv_only = false
  is_gkv_selbstzahler_only = false
  form_of_address = ''
  first_name = ''
  last_name = ''
  weight = ''
  size = ''
  appointment_type_comment = ''
  invoice_type_comment = ''
  street_house_number = ''
  zipcode = ''
  city = ''
  local_specialties = ''
  city_part = ''
  county = ''
  state = ''
  phone_1 = ''
  phone_2 = ''
  email = ''
  phone_1_info = ''
  phone_2_info = ''
  email_info = ''
  hours_per_month = null
  appointment_interval = ''
  selbstzahler_budget_type = null
  selbstzahler_budget_max = ''
  kostentraeger_valid_type = ''
  kostentraeger_valid_until_date = ''
  kostentraeger_valid_until_date_string = ''
  kostentraeger_budget = ''
  is_pflegeherzen_customer = false
  insured_via: string[] = []
  insured_via_string = ''
  insured_via_other_text = ''
  has_degree_of_care_old = false
  degree_of_care = ''
  degree_of_care_old = ''
  care_insurance_id = ''
  cost_unit_id = ''
  care_insurance_pflege_ik = ''
  care_insurance_name = ''
  cost_unit_name = ''
  care_insurance: MultiplierFormModel = new MultiplierFormModel()
  cost_unit: MultiplierFormModel = new MultiplierFormModel()
  insurer_number = ''
  file_number = ''
  stroke_left = false
  stroke_right = false
  initial_documents_generated = false
  is_contact_person = false

  flexible_times = false
  times: PatientTimeModel = new PatientTimeModel()

  first_sepa: MediaModel | null = null
  first_dlv: MediaModel | null = null
  price_change2022: MediaModel | null = null
  has_price_change2022 = false

  budget_for_current_month = 0
  budget_for_next_month = 0

  persplan_hours_real_for_current_month = 0
  persplan_hours_real_for_last_month = 0
  persplan_hours_real_for_next_month = 0

  services = []
  service_ids: number[] = []
  diseases = []
  disease_ids = []
  tools = []
  tool_ids = []
  communicationTypes = []
  communication_type_ids = []

  appointment_lock: AppointmentLockModel | null = null
  current_pause: PatientPauseModel | null = null
  pause_in_current_month: PatientPauseModel | null = null
  pause_in_next_month: PatientPauseModel | null = null
  pause_in_current_month_active: PatientPauseModel | null = null
  pause_that_will_end: PatientPauseModel | null = null

  vetos: VetoModel[] = []
  consultings: any[] = []

  // TODO Model erstellen
  appointment_types: any
  appointment_types_string = ''
  appointment_type_tooltip = ''
  invoice_types: any
  invoice_types_string = ''
  invoice_type_tooltip = ''
  appointment_receiver_labels: string[] = []
  appointment_receiver_values: string[] = []
  invoice_receiver_labels: string[] = []
  invoice_receiver_values: string[] = []

  transfer_needed_general = ''
  transfer_needed_general_info = ''
  services_string = ''
  service_for = ''
  service_for_formatted = ''
  driving_license_needed = ''
  smoker_allowed = ''
  should_be_vaccinated = ''
  diseases_string = ''
  communication_types_string = ''
  services_text = ''
  diseases_text = ''
  incontinence_urine = ''
  incontinence_stool = ''
  incontinence_text = ''
  tools_string = ''
  tools_text = ''
  infectious_diseases = ''
  infectious_diseases_info = ''
  blood_thinner = ''
  blood_thinner_info = ''
  communication_complete_maintained = ''
  no_character_text = false
  character = ''
  communication_text = ''
  no_incontinence = false
  deleted_at: Date | null = null
  created_at: Date | null = null
  updated_at: Date | null = null
  birthday: Date | null = null
  birthday_date_string = ''
  degree_of_care_since: Date | null = null
  degree_of_care_since_string = ''
  degree_of_care_old_since: Date | null = null
  degree_of_care_old_since_string = ''
  nursing_person = ''
  nursing_person_name = ''
  nursing_aids_info = ''
  nursing_aids = ''
  other_service_providers = ''
  already_consulting_assignment = ''
  already_consulting_assignment_info = ''
  parking_possible = ''
  parking_possible_info = ''
  request_39_vpf = ''
  request_39_question = ''
  request_39_question_info = ''
  request_42_kzpf = ''
  request_37 = ''
  request_45a = ''
  request_45b = ''
  request_45b_question = ''
  request_45b_question_info = ''
  other_service_provider = ''
  other_service_provider_text = ''
  budget_information = ''

  budget_calculations = {
    budget_45b_old: {
      tooltip: '',
      value: 'nicht möglich',
      request: false,
    },
    budget_45b_current: {
      tooltip: '',
      value: 'nicht möglich',
      request: false,
    },
    budget_45a: {
      tooltip: '',
      value: 'nicht möglich',
      request: false,
    },
    budget_37: {
      tooltip: '',
      value: 'nicht möglich',
      request: false,
    },
    budget_39: {
      tooltip: '',
      value: 'nicht möglich',
      request: false,
    },
    budget_42: {
      tooltip: '',
      value: 'nicht möglich',
      request: false,
    },
  }
}
