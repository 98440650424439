<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <span>
      <p-multiSelect
        [style]="{ width: '320px' }"
        selectedItemsLabel="{0} Mitarbeiter ausgewählt"
        inputId="caregivers"
        placeholder="Mitarbeiter auswählen"
        [options]="usersOptions"
        optionValue="id"
        [showHeader]="false"
        optionLabel="last_name"
        name="caregivers"
        [filter]="false"
        scrollHeight="540px"
        [(ngModel)]="filters.users"
        appendTo="body"
        (onChange)="loadDates(false)"
      >
        <ng-template let-user pTemplate="item">
          <div>{{ user.last_name }}</div>
        </ng-template>
      </p-multiSelect>
      <i
        (click)="goToPreviousWeek()"
        style="margin: 0 10px 0 10px"
        class="pi pi-chevron-left"
      ></i>
      {{ week.monday }} - {{ week.sunday }}
      <i
        (click)="goToNextWeek()"
        class="pi pi-chevron-right"
        style="margin: 0 10px 0 10px"
      ></i>
      <p-calendar
        styleClass="datepicker-as-icon"
        inputId="date"
        [showButtonBar]="true"
        [firstDayOfWeek]="1"
        [showWeek]="true"
        (onSelect)="goToSelectedWeek($event)"
        name="date"
        dateFormat="dd.mm.yy"
        [(ngModel)]="week.monday"
        [showIcon]="true"
      ></p-calendar>
    </span>

    <div class="content-header-buttons">
      <button
        pButton
        (click)="openConsultingDialog()"
        label="Neuer Eintrag"
        type="button"
        icon="pi pi-plus"
        iconPos="left"
        class="p-button-outlined"
      ></button>
    </div>
  </header>

  <div
    class="content-body content-body-full-height scrollable-y without-padding"
    [class.content-loading]="contentLoading"
  >
    <full-calendar #calendar [options]="calendarOptions"></full-calendar>
  </div>
</div>
