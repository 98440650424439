export class PermissionModel {
  id: number | null = null
  name = ''
  guard_name = ''
  comment = ''
  namespace = ''
  created_at: Date | null = null
  updated_at: Date | null = null
}

export class PermissionsNamespaced {
  Verwaltung: PermissionModel[] = []
}
