import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { DialogService } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { TitleService } from 'src/app/services/title.service'
import { AuthService } from '../../../services/auth.service'
import { UserCaregiverService } from '../../../services/user-caregiver.service'
import * as dayjs from 'dayjs'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import * as utc from 'dayjs/plugin/utc'
import { HelperService } from '../../../services/helper.service'
import { debounce, has } from 'lodash-es'
import * as currency from 'currency.js'
import { HttpErrorResponse } from '@angular/common/http'
import { ToastService } from '../../../services/toast.service'
import { AppointmentFeedbackDialogComponent } from '../../../components/dialogs/appointment-feedback-dialog/appointment-feedback-dialog.component'
import { Subscription } from 'rxjs'
import { AppointmentTripsDialogComponent } from '../../../components/dialogs/appointment-trips-dialog/appointment-trips-dialog.component'
import { CaregiverApproveOverviewDialogComponent } from '../../../components/dialogs/caregiver-approve-overview/caregiver-approve-overview-dialog.component'
import { AppointmentChangeFeedbackDialogComponent } from '../../../components/dialogs/appointment-change-feedback-dialog/appointment-change-feedback-dialog.component'
import { AnonymousAppointmentsDialogComponent } from '../../../components/dialogs/anonymous-appointments-dialog/anonymous-appointments-dialog.component'

@Component({
  selector: 'app-user-caregiver-overview-list',
  templateUrl: './user-caregiver-overview-list.component.html',
  styleUrls: ['./user-caregiver-overview-list.component.scss'],
})
export class UserCaregiverOverviewListComponent implements OnInit, OnDestroy {
  @ViewChild('dt') table: ElementRef | any = null
  @ViewChild('op') overlayPanel: ElementRef | any = null

  public hoursTotal = 0
  public hoursGoogle = 0
  public driveMeters = 0
  public totalPlannings = 0
  public year = ''
  public month = ''
  private eventBusSubscription: Subscription = new Subscription()

  public currentOverlayValue = ''

  private selectedYear = 0
  private selectedMonth = 0

  // TODO: Models erstellen
  public listData: any
  public isReleased = false
  public currentDay = dayjs().format('DD.MM.YYYY')

  public globalFilterValue = ''
  public loading = true
  public contentLoading = true
  public releaseSubmitted: any = {}

  public releaseClicked = false
  public isMobileView = false
  public showReleasedAppointments = false
  public currentHover = ''

  public monthOptions: any = []
  public yearOptions = [
    { label: '2021', value: '2021' },
    { label: '2022', value: '2022' },
    { label: '2023', value: '2023' },
    { label: '2024', value: '2024' },
  ]

  constructor(
    public userCaregiverService: UserCaregiverService,
    private authService: AuthService,
    private dialogService: DialogService,
    private eventbus: EventBusService,
    private helperService: HelperService,
    private toastService: ToastService,
    private titleService: TitleService
  ) {
    dayjs.locale('de')
    dayjs.extend(customParseFormat)
    dayjs.extend(utc)

    this.isMobileView = window.innerWidth <= 960

    this.appointmentChange = debounce(this.appointmentChange, 800)
  }

  ngOnInit(): void {
    this.titleService.setTitle(`Dienstplan`)

    document.body.classList.remove('mobile-nav-visible')

    const date = new Date()
    this.selectedYear = date.getFullYear()
    this.selectedMonth = date.getMonth()

    this.helperService.dependencies$.subscribe((data: any) => {
      this.monthOptions = data.months
      this.month = this.monthOptions[this.selectedMonth].label
      this.year = this.selectedYear.toString()
    })

    this.loadData()
    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
  }

  public setHover(type: string): void {
    this.currentHover = type
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.CaregiverOverviewChanged:
            this.loadData(false)
        }
      }
    )
  }

  public loadData(withLoading = true, withContentLoading = true): void {
    if (withContentLoading) {
      this.contentLoading = true
    }

    const foundMonth = this.monthOptions.findIndex((month: any) => {
      return month.label === this.month
    })

    if (foundMonth) {
      this.selectedMonth = foundMonth
    }

    this.selectedYear = +this.year

    if (withLoading) {
      this.loading = true
    }

    this.userCaregiverService
      .loadOverviewList(this.month, this.year)
      .subscribe((list: any) => {
        if (list.list_data.length) {
          this.isReleased = list.is_released
          this.listData = list.list_data
          this.hoursTotal = list.hours_total
          this.totalPlannings = list.total_plannings
          this.driveMeters = list.drive_meters_full
          this.hoursGoogle = list.hours_google
        } else {
          this.isReleased = false
          this.listData = []
          this.hoursTotal = 0
          this.totalPlannings = 0
          this.hoursGoogle = 0
          this.driveMeters = 0
        }

        if (withContentLoading) {
          this.contentLoading = false
        }

        this.releaseSubmitted = {}

        if (withLoading) {
          this.loading = false
        }
      })
  }

  public openApproveOverviewDialog(): void {
    let hasIssue = false

    this.listData.forEach((data: any) => {
      if (data.data && !data.data.caregiver_appointment_release) {
        hasIssue = true
        return
      }
    })

    if (hasIssue) {
      alert('Bitte zuerst jeden Einsatz freigeben')
      return
    }

    this.dialogService.open(CaregiverApproveOverviewDialogComponent, {
      header: 'Dienstplan freigeben',
      width: '520px',
      dismissableMask: true,
      styleClass: 'dialog-container',
      data: {
        year: this.year,
        month: this.month,
      },
    })
  }

  public disapproveOverview(): void {
    if (!window.confirm('Freigabe wirklich zurückziehen?')) {
      return
    }

    this.userCaregiverService
      .disapproveOverview(this.month, this.year)
      .subscribe(
        () => {
          this.eventbus.emit(GlobalEvent.CaregiverOverviewChanged)
          this.toastService.success('Dienstplan-Freigabe zurückgezogen')
        },
        () => {
          this.toastService.error(
            'Etwas ist schiefgelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
      )
  }

  public releaseAppointment(data: any): void {
    this.releaseClicked = true
    this.releaseSubmitted[data.data.id] = true

    this.userCaregiverService.releaseAppointment(data.data.id).subscribe(
      () => {
        this.eventbus.emit(GlobalEvent.CaregiverOverviewChanged)
        this.toastService.success('Einsatz freigegeben')
        this.releaseClicked = false
      },
      () => {
        this.toastService.error(
          'Etwas ist schiefgelaufen...',
          'Bitte wenden Sie sich an den Support'
        )

        this.releaseClicked = false
        this.releaseSubmitted[data.data.id] = false
      }
    )
  }

  public unreleaseAppointment(data: any): void {
    if (!window.confirm('Freigabe wieder zurückziehen?')) {
      return
    }

    this.releaseSubmitted[data.data.id] = true

    this.userCaregiverService.unreleaseAppointment(data.data.id).subscribe(
      () => {
        this.eventbus.emit(GlobalEvent.CaregiverOverviewChanged)
        this.toastService.success('Freigabe zurückgezogen')
      },
      () => {
        this.toastService.error(
          'Etwas ist schiefgelaufen...',
          'Bitte wenden Sie sich an den Support'
        )

        this.releaseSubmitted[data.data.id] = false
      }
    )
  }

  public openFeedbackDialog(data: any): void {
    this.dialogService.open(AppointmentFeedbackDialogComponent, {
      header: 'Feedback',
      width: '520px',
      dismissableMask: true,
      styleClass: 'dialog-container',
      data: {
        ...data,
        readonly: this.isReleased,
      },
    })
  }

  public openChangeFeedbackDialog(
    data: any,
    type: 'time' | 'drive_time'
  ): void {
    this.dialogService.open(AppointmentChangeFeedbackDialogComponent, {
      header: 'Kommentar',
      width: '520px',
      dismissableMask: true,
      styleClass: 'dialog-container',
      data: {
        ...data,
        type,
        readonly: this.isReleased,
      },
    })
  }

  public openTripsDialog(data: any): void {
    this.dialogService.open(AppointmentTripsDialogComponent, {
      header: 'Zusätzliche Fahrten',
      width: '520px',
      dismissableMask: true,
      styleClass: 'dialog-container',
      data: {
        ...data,
        readonly: this.isReleased,
      },
    })
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  public openAnonymousAppointments(data: any): void {
    const { first_name, last_name } = data.data.patient

    this.dialogService.open(AnonymousAppointmentsDialogComponent, {
      header: `Einsätze für ${first_name} ${last_name}`,
      width: '320px',
      dismissableMask: true,
      styleClass: 'dialog-container',
      data: {
        persplanId: data.data.id,
      },
    })
  }

  public toggleOverlay(event: any, value: string): void {
    this.currentOverlayValue = value

    this.overlayPanel.toggle(event)
  }

  public incrementAppointmentChange(
    data: any,
    type: 'time' | 'drive_time'
  ): void {
    const value = type === 'time' ? 0.25 : 1

    data.data.caregiver_appointment_change[type] = currency(
      data.data.caregiver_appointment_change[type]
    )
      .add(value)
      .toString()

    // Bei 0 soll er im HTML nichts anzeigen.
    if (data.data.caregiver_appointment_change[type] === '0.00') {
      data.data.caregiver_appointment_change[type] = 0
    }

    // Bei Fahrzeit müssen wir die Kommastellen entfernen.
    if (type === 'drive_time') {
      data.data.caregiver_appointment_change[type] = Math.trunc(
        data.data.caregiver_appointment_change[type]
      )
    }
  }

  public decrementAppointmentChange(
    data: any,
    type: 'time' | 'drive_time'
  ): void {
    const value = type === 'time' ? 0.25 : 1

    data.data.caregiver_appointment_change[type] = currency(
      data.data.caregiver_appointment_change[type]
    )
      .subtract(value)
      .toString()

    // Bei 0 soll er im HTML nichts anzeigen.
    if (data.data.caregiver_appointment_change[type] === '0.00') {
      data.data.caregiver_appointment_change[type] = 0
    }

    // Bei Fahrzeit müssen wir die Kommastellen entfernen.
    if (type === 'drive_time') {
      data.data.caregiver_appointment_change[type] = Math.trunc(
        data.data.caregiver_appointment_change[type]
      )
    }
  }

  public appointmentChange(data: any): void {
    this.userCaregiverService
      .changeAppointment(data.data.id, data.data.caregiver_appointment_change)
      .subscribe(
        () => {},
        (error: HttpErrorResponse) => {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
      )
  }

  /**
   * Springt zum letzten Monat.
   */
  public goToPreviousMonth(): void {
    if (this.selectedMonth === 0) {
      const lastYear = this.selectedYear - 1

      const hasYearOption = this.yearOptions.find(
        (data: any) => data.value == lastYear
      )

      if (hasYearOption) {
        this.selectedYear = lastYear
        this.selectedMonth = 11
      }
    } else {
      this.selectedMonth--
    }

    this.year = this.selectedYear.toString()
    this.month = this.monthOptions[this.selectedMonth].label

    this.loadData(false)
  }

  /**
   * Springt zum nächsten Monat.
   */
  public goToNextMonth(): void {
    if (this.selectedMonth === 11) {
      const nextYear = this.selectedYear + 1

      const hasYearOption = this.yearOptions.find(
        (data: any) => data.value == nextYear
      )

      if (hasYearOption) {
        this.selectedYear = nextYear
        this.selectedMonth = 0
      }
    } else {
      this.selectedMonth++
    }

    this.year = this.selectedYear.toString()
    this.month = this.monthOptions[this.selectedMonth].label

    this.loadData(false)
  }
}
