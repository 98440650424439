<div class="content-actions">
  <h2>
    <i class="tio">euro_outlined</i>
    Abrechnung
  </h2>
</div>

<header class="content-header-tabs">
  <a routerLink="/accounting/returns" routerLinkActive="active"> Rückläufer </a>
</header>

<router-outlet></router-outlet>
