import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { TitleService } from 'src/app/services/title.service'
import { NoticeDialogComponent } from '../../../components/dialogs/notice-dialog/notice-dialog.component'
import { DialogService } from 'primeng/dynamicdialog'
import {
  EventBusService,
  GlobalEvent,
} from '../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { PatientService } from '../../../services/patient.service'
import { CustomerDetailModel } from '../../../models/customer-patient/customer-detail.model'
import { environment } from '../../../../environments/environment'
import { NoticeAccountingDialogComponent } from '../../../components/dialogs/notice-accounting-dialog/notice-accounting-dialog.component'

@Component({
  selector: 'app-patients-detail',
  templateUrl: './patients-detail.component.html',
  styleUrls: ['./patients-detail.component.scss'],
})
export class PatientsDetailComponent implements OnInit, OnDestroy {
  public showMoreNotice = false

  public customerDetail: CustomerDetailModel = new CustomerDetailModel()
  private eventBusSubscription: Subscription = new Subscription()

  constructor(
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private eventbus: EventBusService,
    private patientService: PatientService,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    this.loadPatientDetails()
    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
  }

  public loadPatientDetails(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.patientService
        .details(params.get('id'))
        .subscribe((data: CustomerDetailModel) => {
          this.customerDetail = data
          this.titleService.setTitle(
            `${data.first_patient.form_of_address} ${data.first_patient.first_name} ${data.first_patient.last_name}`
          )
        })
    })
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe((event) => {
      switch (event) {
        case GlobalEvent.NoticeChanged:
          this.loadPatientDetails()
          break
      }
    })
  }

  public openNoticeDialog(): void {
    this.dialogService.open(NoticeDialogComponent, {
      header: 'Notiz bearbeiten',
      width: '450px',
      styleClass: 'dialog-container',
      data: {
        type: 'customer',
        type_id: this.customerDetail.id,
        notice: this.customerDetail.notice,
      },
    })
  }

  public openNoticeAccountingDialog(): void {
    this.dialogService.open(NoticeAccountingDialogComponent, {
      header: 'Notiz bearbeiten',
      width: '450px',
      styleClass: 'dialog-container',
      data: {
        type: 'customer',
        type_id: this.customerDetail.id,
        notice_accounting: this.customerDetail.notice_accounting,
      },
    })
  }
}
