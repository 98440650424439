import { UserSystemModel } from './user-system.model'
import { RoleModel } from '../permission/role.model'
import { CaregiverDetailModel } from '../caregiver/caregiver-detail.model'

export class UserModel {
  id: null | number = null
  name = ''
  email = ''
  password = ''
  type = ''
  super_admin = false
  logged_in_as_user_id: null | number = null
  initial_password_changed = false
  deleted_at: Date | null = null

  roles: RoleModel[] = []
  user_system: UserSystemModel | null = null
  caregiver: CaregiverDetailModel | null = null
}
