<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <span>
      Allgemeine, dir zugewiesene, keinem zugewiesen oder von dir erstellt</span
    >

    <div class="content-header-buttons">
      <button
        (click)="openTodoFormDialog()"
        pButton
        label="Neues Todo"
        type="button"
        icon="pi pi-plus"
        iconPos="left"
        class="p-button-outlined"
      ></button>

      <div
        class="table-search-container"
        [class.searchfield-active]="globalFilterValueFinished != ''"
      >
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            (input)="filterTableGlobal($event)"
            placeholder="Suche..."
            [(ngModel)]="globalFilterValueFinished"
          />
        </div>
      </div>
    </div>
  </header>
</div>

<div class="content" *ngIf="!loading">
  <div class="content-body" *ngIf="todos.length == 0">
    <span class="content-no-message">Keine erledigten Todos vorhanden</span>
  </div>

  <div
    class="content-body content-body-full-height-with-tabs without-padding"
    *ngIf="todos.length > 0"
  >
    <p-table
      #dtFinished
      [value]="todos"
      [globalFilterFields]="[
        'category.category',
        'name',
        'title',
        'assigned_to_string',
        'comment',
      ]"
      [(first)]="firstPage"
      [rowHover]="true"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{totalRecords} Todos"
      [rows]="50"
      [paginator]="true"
      scrollHeight="100%"
      [scrollable]="true"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th style="max-width: 50px" pSortableColumn="calculated_priority">
            <i
              style="font-size: 15px"
              class="pi pi-flag"
              pTooltip="Nach Priorität"
            ></i>
            <p-sortIcon field="calculated_priority"></p-sortIcon>
          </th>
          <th style="max-width: 220px" pSortableColumn="name">
            Name <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th style="max-width: 50px" pTooltip="Kommentare">
            <i class="pi pi-comment"></i>
          </th>
          <th style="max-width: 1000px; min-width: 100px" pSortableColumn="title">
            Titel <p-sortIcon field="title"></p-sortIcon>
          </th>
          <th pSortableColumn="done_at" style="max-width: 200px">
            Erledigt am <p-sortIcon field="done_at"></p-sortIcon>
          </th>
          <th pSortableColumn="done_from" style="max-width: 120px">
            Erledigt von <p-sortIcon field="done_from"></p-sortIcon>
          </th>
          <th style="max-width: 50px">an</th>
<!--          <th style="max-width: 50px"></th>-->
<!--          <th style="min-width: 300px">Bemerkung</th>-->
          <th style="max-width: 200px" pSortableColumn="updated_at">
            Bearbeitet am <p-sortIcon field="updated_at"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-todo>
        <tr class="clickable">
          <td style="max-width: 50px" (click)="openShowTodoDialog(todo)">
            <i
              style="font-size: 15px"
              class="pi pi-flag color-alert"
              *ngIf="todo.calculated_priority == 1"
              pTooltip="Hohe Priorität"
            ></i>

            <i
              style="font-size: 15px"
              class="pi pi-arrow-down color-gray"
              *ngIf="todo.calculated_priority == 3"
              pTooltip="Niedrige Priorität"
            ></i>
          </td>

          <td
            style="max-width: 220px"
            *ngIf="todo.assignment_type === 'PATIENT'"
          >
            <a routerLink="/patients/{{ todo.patient.id }}">{{ todo.name }}</a>
          </td>
          <td style="max-width: 220px" *ngIf="todo.assignment_type === 'CAR'">
            <a routerLink="/cars/{{ todo.car.id }}">{{ todo.name }}</a>
          </td>
          <td
            style="max-width: 220px"
            *ngIf="todo.assignment_type === 'CAREGIVER'"
          >
            <a routerLink="/caregivers/{{ todo.caregiver.id }}">{{
              todo.name
            }}</a>
          </td>
          <td
            style="max-width: 220px"
            (click)="openShowTodoDialog(todo)"
            *ngIf="todo.assignment_type === 'GENERAL'"
          >
            {{ todo.name }}
          </td>

          <td style="max-width: 50px" (click)="openShowTodoDialog(todo)">
            {{ todo.comments_count }}
          </td>

          <td style="max-width: 1000px; min-width: 100px" (click)="openShowTodoDialog(todo)">
            <div style="display: flex; flex-direction: column; max-width: 100%">
              <strong>
                {{ todo.title }}
                <small class="color-gray" style="font-weight: normal" *ngIf="todo.category"><i style="font-size: 10px" class="pi pi-tag"></i> {{ todo.category.category }}</small>
              </strong>
              <span class="history-information">
                <p class="history-text text-nowrap" *ngIf="!todo.last_comment">
                  {{ todo.comment || "-" }}
                </p>
                <p class="history-text text-nowrap" *ngIf="todo.last_comment">
                  {{ todo.last_comment.created_from.user_system.short_name }}: {{ todo.last_comment.comment }}
                </p>
              </span>
            </div>
          </td>

          <td style="max-width: 200px" (click)="openShowTodoDialog(todo)">
            {{ todo.done_at | date: "dd.MM.y, H:mm" }}
          </td>
          <td class="color-gray" style="max-width: 120px" (click)="openShowTodoDialog(todo)">
            {{ todo.done_from_user?.user_system?.short_name || "??" }}
          </td>
          <td style="max-width: 50px" (click)="openShowTodoDialog(todo)">
            <i
              class="pi pi-info-circle color-gray"
              style="font-size: 14px"
              *ngIf="todo.assigned_to_string"
              [pTooltip]="todo.assigned_to_string"
            ></i>
          </td>
          <td class="color-gray" style="max-width: 200px" (click)="openShowTodoDialog(todo)">
            {{ todo.updated_at | date: "dd.MM.y, H:mm" }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
