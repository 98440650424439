<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-4">
        <label for="persplan_id">Benutzername *</label>
        <p-inputNumber
          inputId="persplan_id"
          name="persplan_id"
          [(ngModel)]="values.persplan_id"
          [useGrouping]="false"
          #persplan_id="ngModel"
          [required]="true"
          placeholder="XXXX"
          [min]="1000"
          [class.p-invalid]="
            persplan_id.invalid && (persplan_id.dirty || persplan_id.touched)
          "
        ></p-inputNumber>
        <small
          style="display: flex; align-items: center; gap: 5px"
          class="color-gray"
          ><i class="pi pi-info-circle" style="font-size: 12px"></i>Mindestens 4
          Zahlen</small
        >
      </div>

      <div class="p-field p-col-4">
        <label for="email">E-Mail *</label>
        <input
          [(ngModel)]="values.email"
          id="email"
          name="email"
          type="email"
          pInputText
          [required]="true"
          #email="ngModel"
          [class.p-invalid]="email.invalid && (email.dirty || email.touched)"
        />
      </div>

      <div class="p-field p-col-4">
        <label for="initial_password"
          >Initiales Passwort<i
            class="pi pi-info-circle"
            pTooltip="Mit diesem Passwort kann sich der Benutzer initial einloggen. Dieser wird nach dem ersten Login aufgefordert, ein neues Passwort zu setzen. Bitte leer lassen, wenn es nicht geändert werden soll."
          ></i
        ></label>
        <input
          [(ngModel)]="values.initial_password"
          id="initial_password"
          name="initial_password"
          type="password"
          pInputText
        />
      </div>
    </div>
  </div>
  <div class="dialog-form-footer">
    <div class="p-field-checkbox">
      <p-checkbox
        name="todo"
        [binary]="true"
        label="Account aktiv"
        [(ngModel)]="values.is_active"
      ></p-checkbox>
    </div>

    <button
      (click)="save()"
      pButton
      label="Speichern"
      type="button"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>
