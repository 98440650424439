<form #form="ngForm" class="dialog-form">
  <div class="dialog-sidebar secondary-dialog-sidebar">
    <span class="content-sub-headline"
      >Einsätze des Mitarbeiters an diesem Tag</span
    >

    <small
      class="color-gray"
      *ngIf="!values.home_visit_date_string || !values.home_visit_from"
      >Bitte Datum und Mitarbeiter auswählen</small
    >

    <small
      class="color-gray"
      *ngIf="
        values.home_visit_date_string &&
        values.home_visit_from &&
        appointmentsForUser.length === 0
      "
      >Keine Einsätze vorhanden</small
    >

    <div class="p-field p-col-12" style="margin: 0">
      <small
        style="display: flex; flex-direction: column; margin: 0"
        *ngFor="let appointment of appointmentsForUser; let i = index"
      >
        {{ appointment.date }}
        {{ appointment.name }} ({{ appointment.type }})</small
      >
    </div>
  </div>

  <div class="dialog-form-body">
    <div
      class="detail-information-banner"
      *ngIf="recommendationIssue.has_issue"
    >
      <span
        >Der Kunde wurde von {{ recommendationIssue.name }} empfohlen. Es kann
        kein Beratungseinsatz erstellt werden.</span
      >
    </div>

    <div class="p-fluid p-formgrid p-grid without-border" style="padding: 0">
      <div class="p-field p-col-12" *ngIf="withoutPatient">
        <label for="patient">Patient * </label>
        <p-autoComplete
          field="full_name"
          inputId="patient"
          name="patient"
          scrollHeight="350px"
          appendTo="body"
          [forceSelection]="true"
          [(ngModel)]="patient"
          [suggestions]="patients"
          (completeMethod)="searchPatients($event)"
          (onSelect)="patientSelected($event)"
          [required]="true"
          [autofocus]="true"
          #patientRef="ngModel"
          [class.p-invalid]="
            patientRef.invalid && (patientRef.dirty || patientRef.touched)
          "
        >
          <ng-template let-patient pTemplate="item">
            <div class="search-result-data">
              <span class="search-result-name">
                <b>{{ patient.full_name }}</b>
                <!--                <span class="search-result-id">#{{ patient.id }}</span>-->
                <i
                  *ngIf="patient.status"
                  class="p-tag p-tag-rounded p-tag-{{ patient.status }}"
                  >{{ patient.status_string }}</i
                >
              </span>
            </div>
          </ng-template>
        </p-autoComplete>
      </div>

      <div class="p-field p-col-8">
        <label for="qm-home-visit-date">Datum *</label>
        <p-calendar
          [firstDayOfWeek]="1"
          [showWeek]="true"
          (onSelect)="loadUserAppointments()"
          (onBlur)="loadUserAppointments()"
          inputId="qm-home-visit-date"
          name="qm-home-visit-date"
          [showButtonBar]="true"
          dateFormat="dd.mm.yy"
          appendTo="body"
          [(ngModel)]="values.home_visit_date_string"
          dataType="string"
          #date="ngModel"
          [required]="true"
          [class.p-invalid]="date.invalid && (date.dirty || date.touched)"
        ></p-calendar>
      </div>

      <div class="p-field p-col-4">
        <label for="from_time">Uhrzeit *</label>
        <p-calendar
          (onBlur)="checkForTimes($event)"
          inputId="from_time"
          name="from_time"
          [timeOnly]="true"
          appendTo="body"
          [(ngModel)]="values.home_visit_time"
          dataType="string"
          [required]="true"
          [showButtonBar]="false"
          #from_time="ngModel"
          [class.p-invalid]="
            from_time.invalid && (from_time.dirty || from_time.touched)
          "
        ></p-calendar>
      </div>
    </div>

    <div class="p-field">
      <label for="home_visit_from">Mitarbeiter *</label>
      <p-dropdown
        name="home_visit_from"
        inputId="home_visit_from"
        placeholder="Bitte auswählen"
        optionLabel="full_name"
        optionValue="full_name"
        (onChange)="loadUserAppointments()"
        [(ngModel)]="values.home_visit_from"
        [options]="homeVisitFrom"
        [required]="true"
        #home_visit_from="ngModel"
        [class.p-invalid]="
          home_visit_from.invalid &&
          (home_visit_from.dirty || home_visit_from.touched)
        "
      ></p-dropdown>
    </div>

    <div class="p-field">
      <label for="comment">Kommentar</label>
      <textarea
        id="comment"
        name="comment"
        [rows]="2"
        [(ngModel)]="values.comment"
        pInputTextarea
      ></textarea>
    </div>

    <div class="p-field" *ngIf="!isPreview">
      <label>Übergabe per *</label>
      <div class="p-formgroup-inline">
        <div class="p-field-radiobutton">
          <p-radioButton
            label="E-Mail"
            name="sent_type"
            (onClick)="buildReceiverOptions()"
            value="MAIL"
            [(ngModel)]="values.sent_type"
            [required]="true"
            #sent_type="ngModel"
          ></p-radioButton>
        </div>
        <div class="p-field-radiobutton">
          <p-radioButton
            label="Post"
            (onClick)="buildReceiverOptions()"
            name="sent_type"
            value="POST"
            [(ngModel)]="values.sent_type"
            [required]="true"
            #sent_type="ngModel"
          ></p-radioButton>
        </div>
        <div class="p-field-radiobutton">
          <p-radioButton
            label="Telefonisch"
            name="sent_type"
            (onClick)="buildReceiverOptions()"
            value="PHONE"
            [(ngModel)]="values.sent_type"
            [required]="true"
            #sent_type="ngModel"
          ></p-radioButton>
        </div>
        <div class="p-field-radiobutton">
          <p-radioButton
            label="Persönlich"
            (onClick)="buildReceiverOptions()"
            name="sent_type"
            value="PERSONAL"
            [(ngModel)]="values.sent_type"
            [required]="true"
            #sent_type="ngModel"
          ></p-radioButton>
        </div>
      </div>
    </div>

    <div class="p-field" *ngIf="values.sent_type === 'MAIL'">
      <div class="p-formgroup-inline">
        <div class="p-field-checkbox">
          <p-checkbox
            label="E-Mail senden"
            name="send_email"
            [binary]="true"
            [(ngModel)]="values.send_email"
          ></p-checkbox>
        </div>
      </div>
    </div>

    <!-- Empfänger -->
    <div
      class="p-fluid p-formgrid p-grid without-border"
      style="padding: 0"
      *ngIf="values.sent_type !== ''"
    >
      <div class="p-field p-col-6">
        <label for="receiver">Empfänger *</label>
        <p-dropdown
          inputId="receiver"
          name="receiver"
          [options]="receiverOptions"
          [(ngModel)]="values.receiver"
          appendTo="body"
          scrollHeight="400px"
          [required]="true"
          #receiver="ngModel"
          [class.p-invalid]="
            receiver.invalid && (receiver.dirty || receiver.touched)
          "
        >
          <ng-template let-receiver pTemplate="item">
            <div>{{ receiver.label }}</div>
            <span *ngIf="values.sent_type === 'MAIL'">{{
              receiver.email
            }}</span>
          </ng-template>
        </p-dropdown>
      </div>

      <!-- CC an -->
      <div
        class="p-field p-col-6"
        *ngIf="values.send_email && values.sent_type === 'MAIL'"
      >
        <label for="cc">CC an</label>
        <p-multiSelect
          inputId="cc"
          placeholder="Optional"
          [options]="ccOptions"
          [filter]="false"
          [showHeader]="false"
          name="cc"
          appendTo="body"
          scrollHeight="400px"
          [(ngModel)]="values.cc"
        ></p-multiSelect>
      </div>
    </div>
  </div>
  <div class="dialog-form-footer">
    <i
      *ngIf="!config.data.isNew && !hasMedia"
      class="pi pi-trash"
      [class.pi-trash]="!submittedDelete"
      [class.pi-spin]="submittedDelete"
      [class.pi-spinner]="submittedDelete"
      tooltipPosition="left"
      tooltipZIndex="20000"
      [pTooltip]="submittedDelete ? 'Wird storniert' : 'BE stornieren'"
      (click)="remove($event)"
    ></i>

    <i *ngIf="!config.data.isNew && hasMedia"></i>

    <i
      *ngIf="
        config.data.isNew && !values.send_email && values.sent_type !== 'POST'
      "
    ></i>

    <a
      *ngIf="values.send_email && values.sent_type === 'MAIL'"
      target="_blank"
      (click)="clickedOnPreview = true"
      [href]="consultingService.getMailPreviewLink(values)"
    >
      <button
        pButton
        label="Mail Vorschau"
        type="button"
        icon="pi pi-eye"
        iconPos="left"
        class="p-button p-button-info"
      ></button>
    </a>

    <a
      *ngIf="values.sent_type === 'POST'"
      target="_blank"
      (click)="clickedOnPreview = true"
      [href]="consultingService.getPdfPreviewLink(values)"
    >
      <button
        pButton
        label="PDF Vorschau"
        type="button"
        icon="pi pi-eye"
        iconPos="left"
        class="p-button p-button-info"
      ></button>
    </a>

    <button
      *ngIf="isPreview"
      pButton
      label="Daten speichern"
      type="submit"
      (click)="savePreview()"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>

    <button
      *ngIf="!isPreview"
      pButton
      [label]="values.send_email ? 'Speichern und versenden' : 'Speichern'"
      type="submit"
      (click)="save()"
      [disabled]="submitted || (!clickedOnPreview && values.send_email)"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>
<p-confirmPopup></p-confirmPopup>
