import { CaregiverEmployeedModel } from './caregiver-employeed.model'
import { CaregiverStatusModel } from './caregiver-status.model'
import { CaregiverAllergyModel } from './caregiver-allergy.model'
import { CaregiverPhobiaModel } from './caregiver-phobia.model'
import {
  ActivityModel,
  CountyModel,
  ExperienceModel,
} from './caregiver-dependencies.model'
import { TreeNode } from 'primeng/api'

export class CaregiverFormModel {
  id?: number | null = null
  user_id = null
  persplan_id: number | null = null
  planner_id: number | null = null
  form_of_address = ''
  consider_capacity = false
  is_mentor = false
  first_name = ''
  last_name = ''
  birth_name = ''
  shortcut = ''
  birthplace = ''
  tax_id = ''
  pension_insurance_number = ''
  birthday_date: Date | null | string = null
  birthday_date_string: string | null = null
  driving_license_since: Date | null | string = null
  driving_license_since_string: string | null = null
  old_employment_until: Date | null | string = null
  old_employment_until_string: string | null = null
  employment_possible_at: Date | null | string = null
  employment_possible_at_string: string | null = null
  old_employment_unlimited = false
  current_job_situation = ''
  current_job_situation_string = ''
  current_family_situation = ''
  current_job_situation_notice = ''
  instruction_infection_act = 'Nein'
  instruction_infection_act_date: Date | null | string = null
  instruction_infection_act_date_string = ''
  extended_certificate_of_good_conduct_date: Date | null | string = null
  extended_certificate_of_good_conduct_date_string = ''
  application_documents_complete = ''
  missed_application_documents = ''
  application_contact_via: string[] = []
  application_ne = false
  application_ne_ab = false
  application_information = ''
  extended_certificate_of_good_conduct = 'Nein'
  street_house_number = ''
  jobcenter_number = ''
  zipcode = ''
  city = ''
  city_part = ''
  state = ''
  county = ''
  phone_1 = ''
  phone_2 = ''
  phone_3 = ''
  phone_1_info = ''
  phone_2_info = ''
  phone_3_info = ''
  notice = ''
  email = ''
  email_info = ''
  smoking = 'Nein'
  driving = 'Nein'
  allergies = 'Nein'
  phobias = 'Nein'
  vaccinated_covid_19 = 'Nein'
  vaccinated_covid_19_info = ''
  about = ''
  employed_since_date: Date | null | string = null
  employed_until_date: Date | null | string = null
  employed_since_date_string: null | string = null
  employed_until_date_string: null | string = null
  employed_temporary = true
  job_scope: string | null = null
  job_position = ''
  job_day = 6

  persplan_hours_real_for_current_month = 0
  persplan_hours_real_for_last_month = 0
  persplan_hours_real_for_next_month = 0

  salary = null
  vacation_days = null
  employed_as = 'Reine HW'
  experience_info = ''
  education_as = ''
  education_dementia = false
  education_assistant = false
  initial_training = 'Nein'
  last_training_date: Date | null | string = null
  last_training_date_string: null | string = null
  first_aid = 'Nein'
  first_aid_date: Date | null | string = null
  first_aid_date_string: null | string = null
  training_administration = 'Nein'
  training_administration_from = ''
  training_administration_date: Date | null | string = null
  training_administration_date_string: null | string = null
  hospitation = 'Nein'
  hospitation_dates = ''
  hospitation_from = ''
  hospitation_feedback = ''
  created_at?: Date | null = null
  updated_at?: Date | null = null
  deleted_at?: Date | null = null
  counties: CountyModel[] = []
  activities: ActivityModel[] = []
  county_ids: number[] = []
  city_ids: TreeNode[] = []
  experiences: ExperienceModel[] = []
  experience_ids: number[] = []
  activity_ids: number[] = []
  from_campaign = false

  allergy: CaregiverAllergyModel = new CaregiverAllergyModel()
  phobia: CaregiverPhobiaModel = new CaregiverPhobiaModel()
  current_employment: CaregiverEmployeedModel = new CaregiverEmployeedModel()
  current_status: CaregiverStatusModel = new CaregiverStatusModel()
}
