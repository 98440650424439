<app-loader [loading]="loading"></app-loader>

<div class="content new-content-container" *ngIf="!loading">
  <header class="content-header" *ngIf="data.length > 0">
    <span> </span>

    <div class="content-header-buttons">
      <div class="table-search-container">
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            (input)="filterTableGlobal($event)"
            placeholder="Suche..."
            [value]="globalFilterValue"
          />
        </div>
      </div>
    </div>
  </header>
  <div class="content-body auto-height" *ngIf="data.length === 0">
    <span class="content-no-message">Keine Rückläufer vorhanden</span>
  </div>
  <div class="content-body without-padding auto-height" *ngIf="data.length > 0">
    <p-table
      #dt
      [value]="data"
      [globalFilterFields]="['case_number', 'invoice_number']"
      [rowHover]="true"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th style="width: 160px" pSortableColumn="status">
            Status <p-sortIcon field="status"></p-sortIcon>
          </th>
          <th pSortableColumn="case_number">
            Fallnummer <p-sortIcon field="case_number"></p-sortIcon>
          </th>
          <th style="width: 90px" pSortableColumn="correction_reason">
            Grund <p-sortIcon field="correction_reason"></p-sortIcon>
          </th>
          <th pSortableColumn="performance_date_sortable">
            Leistungsmonat
            <p-sortIcon field="performance_date_sortable"></p-sortIcon>
          </th>
          <th pSortableColumn="invoice_number">
            Re-Nummer <p-sortIcon field="invoice_number"></p-sortIcon>
          </th>
          <th pSortableColumn="invoice_date">
            Re-Datum <p-sortIcon field="invoice_date"></p-sortIcon>
          </th>
          <th pSortableColumn="total_value">
            Gesamtwert <p-sortIcon field="total_value"></p-sortIcon>
          </th>
          <th style="width: 180px" pSortableColumn="created_at">
            Erstellt am <p-sortIcon field="created_at"></p-sortIcon>
          </th>
          <th style="width: 100px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr class="clickable">
          <td (click)="openAddAccountingReturnDialog(item)">
            <span class="p-tag p-tag-rounded p-tag-{{ item.status }}">{{
              item.status_text
            }}</span>
          </td>
          <td (click)="openAddAccountingReturnDialog(item)">
            {{ item.case_number }}
          </td>
          <td
            tooltipStyleClass="tooltip-wider"
            [escape]="false"
            [pTooltip]="correctionReasons[item.correction_reason]"
            (click)="openAddAccountingReturnDialog(item)"
          >
            {{ item.correction_reason }}
          </td>
          <td (click)="openAddAccountingReturnDialog(item)">
            {{ item.performance_month }} {{ item.performance_year }}
          </td>
          <td (click)="openAddAccountingReturnDialog(item)">
            {{ item.invoice_number }}
          </td>
          <td (click)="openAddAccountingReturnDialog(item)">
            {{ item.invoice_date_string }}
          </td>
          <td (click)="openAddAccountingReturnDialog(item)">
            {{ item.total_value_formatted }}
          </td>
          <td (click)="openAddAccountingReturnDialog(item)">
            {{ item.created_at | date: "dd.MM.y, H:mm" }} Uhr
          </td>
          <td class="table-action">
            <i
              (click)="openAddAccountingReturnDialog(item)"
              class="pi pi-pencil with-margin-right"
              pTooltip="Bearbeiten"
            ></i>
            <a
              *ngIf="item.media[0]"
              [href]="
                documentService.getDocumentDownloadLink(item.media[0].uuid)
              "
              target="_blank"
            >
              <i
                pTooltip="Herunterladen"
                tooltipPosition="left"
                class="pi pi-download"
              ></i>
            </a>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
