import { CarsDetailModel } from '../car/cars-detail.model'
import { DocumentModel } from '../document/document.model'

export class CaregiverDetailDocumentsModel {
  initial_documents_generated = true
  car: CarsDetailModel | null = null
  types: CaregiverDocumentTypesModel = new CaregiverDocumentTypesModel()
}

export class CaregiverDocumentTypesModel {
  misc = new DocumentModel()
  arbeitsvertrag = new DocumentModel()
  dienstfahrzeugueberlassungsvertrag = new DocumentModel()
  fahrzeug_rueckgabe = new DocumentModel()
  fahrzeug_uebergabe = new DocumentModel()
  hygienefolgebelehrung = new DocumentModel()
  personalfragebogen = new DocumentModel()
  tankkarten_arbeitsanweisungen = new DocumentModel()
  fahrtenbuch = new DocumentModel()
}
