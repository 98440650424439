import { Component, OnInit } from '@angular/core'
import { MenuItem } from 'primeng/api'
import { AuthService } from '../../services/auth.service'
import { AuthModel } from '../../models/user/auth.model'
import { TodoHeaderModel } from '../../models/todo/todo-header.model'
import { DashboardService } from '../../services/dashboard.service'

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  public user: AuthModel | null = new AuthModel()

  public amounts = {
    plannings: 0,
    todos: 0,
  }

  public navHidden = false
  public navHiddenMobile = false

  public userMenuItems: MenuItem[] = [
    { label: 'Einstellungen', disabled: true },
    { label: 'Ausloggen', command: () => this.logout() },
  ]

  constructor(
    public authService: AuthService,
    private dashboardService: DashboardService
  ) {}

  ngOnInit(): void {
    this.user = this.authService.getUser()

    const isNavHidden = localStorage.getItem('nav-hidden')

    if (isNavHidden === null) {
      localStorage.setItem('nav-hidden', 'false')
    } else {
      this.navHidden = isNavHidden === 'true'

      this.toggleClass()
    }

    // Dadurch aktualisieren wir die Anzeige in der Navigation.
    // Für sowas benötigen wir keine Websocket Events.
    if (this.user?.is_user_system) {
      this.loadNavAmounts()

      setInterval(() => {
        this.loadNavAmounts()
      }, 30000)
    }
  }

  private loadNavAmounts(): void {
    this.dashboardService.loadNavAmounts().subscribe((result: any) => {
      this.amounts.plannings = result.plannings
      this.amounts.todos = result.todos
    })
  }

  public toggleMobileNav(): void {
    if (document.body.classList.contains('mobile-nav-visible')) {
      document.body.classList.remove('mobile-nav-visible')
    } else {
      document.body.classList.add('mobile-nav-visible')
    }
  }

  public toggleNav(): void {
    this.navHidden = !this.navHidden

    localStorage.setItem('nav-hidden', this.navHidden.toString())

    this.toggleClass()
  }

  private toggleClass(): void {
    if (this.navHidden) {
      document.body.classList.add('nav-hidden')
    } else {
      document.body.classList.remove('nav-hidden')
    }
  }

  private logout(): void {
    this.authService.logout().subscribe(() => {
      window.location.href = '/login'
    })
  }
}
